import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UtilService } from 'src/app/shared/services/util.service';

@Component({
  selector: 'app-speaker-card',
  templateUrl: './speaker-card.component.html',
  styleUrls: ['./speaker-card.component.scss']
})
export class SpeakerCardComponent implements OnInit {

  @Output() addToFavorites = new EventEmitter<{ id: number, confirm: boolean }>();

  @Input() Id: number;
  @Input() ViewsCount: number;
  @Input() Name: string;
  @Input() Picture: string;
  @Input() City: string;
  @Input() CountryState: string;
  @Input() IsFavorite: boolean;
  @Input() RouteLink: string;
  @Input() Category: string;
  @Input() Speciality: string;
  @Input() Facebook: string;
  @Input() Instagram: string;
  @Input() LinkedIn: string;
  @Input() ShowSpeakerDetails: boolean;
  @Input() ShowFavorite: boolean;
  @Input() ShowFistAndLastName: boolean;
  @Input() BackgroundImage:string  = 'assets/images/user-card/background.jpg';

  constructor(public util: UtilService) { }

  ngOnInit(): void {
    if (this.ShowFistAndLastName) {

      this.Name = this.Name.split(/(\s).+\s/).join("");
      let namesplit = this.Name.split(' ');

      this.Name = namesplit.length >= 2 ? namesplit[0] + '<br>' + namesplit[1] : this.Name;

    }
  }

  addFavorites(topicId: number, confirm: boolean) {
    this.addToFavorites.emit({ id: topicId, confirm: confirm });
  }
}
