<div class="card custom-card b-r-10 p-20 p-b-30">
    <div *ngIf="IsFavorite && ShowFavorite" style="cursor: pointer;" placement="left" ngbTooltip="Remover dos Favoritos"
        tooltipClass="custom-tooltip" class="ribbon ribbon-bookmark ribbon-vertical-right ribbon-primary m-r-5"
        (click)="addFavorites(Id, false)">
        <i class="icofont icofont-ui-love"></i>
    </div>
    <div *ngIf="!IsFavorite && ShowFavorite" style="cursor: pointer;" placement="left" ngbTooltip="Favoritar"
        tooltipClass="custom-tooltip" class="ribbon ribbon-bookmark ribbon-vertical-right ribbon-light m-r-5"
        (click)="addFavorites(Id, true)">
        <i class="icofont icofont-ui-love"></i>
    </div>
    <div class="card-header card-no-border"><img class="img-fluid" [src]="BackgroundImage" alt="">
    </div>
    <div class="card-profile">
        <img class="rounded-circle" [src]="Picture ? Picture : 'assets/images/user/user.png'" alt="">
    </div>
    <div *ngIf="!ShowSpeakerDetails" class="text-center profile-details">
        <h5 class="m-t-20 m-b-5 f-w-700 s-name" style="text-transform: capitalize !important;">
            <highlight-words [innerHtml]="Name?.toLowerCase()"></highlight-words>
        </h5>
        <h5 class="m-t-5 f-16 f-w-300 s-city">
            <highlight-words [Text]="City"></highlight-words>, <highlight-words [Text]="CountryState"></highlight-words>
        </h5>
        <a class="btn btn-primary f-w-600 f-14 m-b-10" [routerLink]="[RouteLink, Id]">Detalhes</a>
    </div>
    <div *ngIf="ShowSpeakerDetails" class="text-center profile-details">
        <h5 class="m-t-20 m-b-5 f-w-700 s-name">
            <highlight-words [Text]="util.capitalizeTerm(Name ? Name : '')"></highlight-words>
        </h5>
        <h5 class="m-t-5 m-b-10 m-t-15 f-16 f-500">{{ Category ? Category : 'Médico(a)' }}</h5>
        <h6 class="m-t-5 m-b-20 f-16 f-w-300">{{ Speciality ? Speciality : '' }}</h6>
        <hr>
        <div class="s-data">
            <ul class="social-data">
                <li class="d-flex justify-content-start  align-items-center m-t-10"><span
                        class="f-w-700 m-r-30">Cidade</span>
                    <span class="name d-inline s-info f-w-500">
                        <highlight-words [Text]="City"></highlight-words>, <highlight-words [Text]="CountryState">
                        </highlight-words>
                    </span>
                </li>
                <li class="d-flex justify-content-start align-items-center m-t-10"><span
                        *ngIf="Facebook || Instagram || LinkedIn" class="f-w-700 m-r-30">Siga-me</span>
                    <div class="name d-inline social-media s-info">
                        <a href="{{ Facebook }}" target="blank" *ngIf="Facebook" class="s-media m-r-10">
                            <i class="fa fa-facebook" placement="top" ngbTooltip="Facebook"
                                tooltipClass="custom-tooltip"></i></a>
                        <a href="{{ Instagram }}" target="blank" *ngIf="Instagram" class="s-media m-r-10">
                            <i class="fa fa-instagram" placement="top" ngbTooltip="Instagram"
                                tooltipClass="custom-tooltip"></i></a>
                        <a href="{{ LinkedIn }}" target="blank" *ngIf="LinkedIn" class="s-media m-r-10">
                            <i class="fa fa-linkedin" placement="top" ngbTooltip="LinkedIn"
                                tooltipClass="custom-tooltip"></i></a>
                    </div>
                </li>
                <!--Removido a pedido da SOGESP
                    Número de visitas do perfil-->
                <!-- <li class="d-flex justify-content-start align-items-center m-t-10"><span
                        class="f-w-700 m-r-30">Visitas</span><span
                        class="badge badge badge-light s-info">{{ViewsCount}}</span></li> -->
            </ul>
        </div>
    </div>
</div>
