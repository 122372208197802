import { Injectable } from '@angular/core';
declare var require;
const Swal = require('sweetalert2');

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor() { }

  basic(title: string, msg: string) {
    Swal.fire({
      title: title,
      html: msg
    })
  }

  error(title: string, textMessage: string, footerText?: string, buttonText?: string) {
    Swal.fire({
      type: 'error',
      title: title,
      html: textMessage,
      footer: footerText,
      confirmButtonText: buttonText ? buttonText : 'OK'
    })
  }

  warning(title: string, textMessage: string, footerText?: string, buttonText?: string) {
    Swal.fire({
      type: 'warning',
      title: title,
      html: textMessage,
      showConfirmButton: true,
      footer: footerText,
      confirmButtonText: buttonText ? buttonText : 'OK'
    });
  }

  success(title: string, textMessage: string, footerText?: string, buttonText?: string) {
    Swal.fire({
      type: 'success',
      title: title,
      html: textMessage,
      showConfirmButton: true,
      footer: footerText,
      confirmButtonText: buttonText ? buttonText : 'OK'
    });
  }

  confirmation(title: string, text: string, type: string, confirmButtonText: string, cancelButtonText: string, yesfunc?: () => any, nofunc?: () => any) {
    Swal.fire({
      title: title,
      html: text,
      type: type,
      allowOutsideClick: false,
      showCancelButton: true,
      cancelButtonText: cancelButtonText ?? 'Não',
      confirmButtonText: confirmButtonText ?? 'Sim'
    }).then((result: { value: any; }) => {
      if (result.value && yesfunc) {
        yesfunc();
      }
      else {
        if (nofunc)
          nofunc();
      }
    });

  }

}
