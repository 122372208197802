import { CoverageArea } from './coverage-area.model';
import { ThematicArea } from './thematic-area.model';
import { ActivityType } from './activity-type.model';
import { IEvent } from './event.model';
import { StorageRefresh } from '../shared/storage-refresh';

export class EventSchedule extends StorageRefresh implements IEvent {

    constructor() {
        super();
    }

    id: number;
    description: string;
    shortDescription: string;
    logo: string;
    about: string;
    authorizationTerm: string;
    eventId: number | null;
    days: ScheduleDay[];
    updated: boolean;

    checkTimeToRefresh(): boolean {
        return super.checkTimeToRefresh();
    }

    public setLastRefreshDate() {
        return super.setLastRefreshDate();
   }
}

export class ScheduleDay extends StorageRefresh {

    constructor() {
        super();
    }

    id: number;
    day: string;
    dayOfWeek: string;
    color: string;
    beginHour: string;
    endHour: string;
    hasActivities: boolean;
    eventScheduleId: number;
    activities: Activity[];

}

export class Activity extends StorageRefresh {

    constructor() {
        super();
    }

    id: number;
    description: string;
    order: number | null;
    duration: string;
    startTime: string;
    endTime: string;
    abbreviation: string;
    coverageAreaId: number;
    thematicAreaId: number;
    typeId: number;
    type: string;
    coverageAreaDescription: string;
    thematicAreaDescription: string;
    roomAbbreviation: string;
    roomDescription: string;
    roomLinkVideo: string;
    roomLinkChat: string;
    dayDate: string;
    dayColor: string;
    eventScheduleId: number;
    registrationEventId?: number;
    registrationEventName: string;
    activityColor: string;
    hasVideos: number;
    newVideos: number
    activitiesCount: number;
    expanded: boolean;
    itsTime: boolean;
    isSponsored: number;
    day: ScheduleDay;
    progress: number;
    topics: Topic[];
}

export class Guest extends StorageRefresh {

    constructor() {
        super();
    }

    id: number;
    userSgunId: number;
    name: string;
    profilePicturePath: string;
    email: string;
    institution: string;
    city: string;
    countryState: string;
    miniResume: string;
    speciality: string;
    category: string;
    currentCategory: string;
    socialMedias: string;
    facebook: string;
    instagram: string;
    linkedIn: string;
    numberOfVisits: number;
    coverageAreaId: number;
    coverageAreaDescription: string;
    eventScheduleId: number;
}

export class GuestActivity extends StorageRefresh {

    constructor() {
        super();
    }

    description: string;
    topicId: number;
    guestId: number;
    guestSgunId: number;
    abbreviation: string;
    roomAbbreviation: string;
    order: number;
    dayDate: string;
    duration: string;
    startTime: string;
    endTime: string;
    thumbImage: string;
    urlVideo: string;
    eventId: number;
}

export class Topic extends StorageRefresh {

    constructor() {
        super();
    }

    id: number;
    duration: string;
    startTime: string;
    endTime: string;
    description: string;
    order: number;
    note: string;
    activityId: number;
    activity: Activity;
    type: number; //0 = Tema 1 = Subtema
    canHaveVideo: number;
    videoUrl: string;
    thumbUrl: string;
    itsLive: number;
    materialUrl: string;
    rate: number;
    resume: string;
    participations: Participation[];
}

export class Participation extends StorageRefresh {

    constructor() {
        super();
    }

    id: number;
    roleDescription: string;
    topicId: number;
    guestId: number;
    guestName: string;
    guestPhoto: string;
    guest: Guest;
}

export class GetCompleteEventScheduleInput {
    eventId: number;
    code: string;
    filter: string;
    conerageAreaId: number | null;
    thematicAreaId: number | null;
    activityTypeId: number | null;
}

export class GetEventForViewInput {
  eventId: number;
  code: string;
}

export class DateFilter {
    date: string;
    count: number;
    isChecked: boolean;
}


export class RoomFilter {
  room: string;
  count: number;
  isChecked: boolean;
}
