<div class="card">
    <div *ngIf="IsFavorite" style="cursor: pointer;" placement="left" ngbTooltip="Remover dos Favoritos"
        tooltipClass="custom-tooltip" class="ribbon ribbon-bookmark ribbon-vertical-right ribbon-secondary m-r-20"
        (click)="addFavorites(Topic.id, false)">
        <i class="icofont icofont-ui-love"></i>
    </div>
    <div *ngIf="!IsFavorite" style="cursor: pointer;" placement="left" ngbTooltip="Favoritar"
        tooltipClass="custom-tooltip" class="ribbon ribbon-bookmark ribbon-vertical-right ribbon-light m-r-20"
        (click)="addFavorites(Topic.id, true)">
        <i class="icofont icofont-ui-love"></i>
    </div>
    <div class="job-search">
        <div class="card-body" style="padding-right: 20px !important;">
            <div class="row">
                <div class="col-sm-5 col-md-4 col-lg-4 xl-45 col-xl-3">
                    <!-- <img class="img-fluid sm-100-w b-light border-5"
                        [src]='Topic.ThumbUrl ? Topic.ThumbUrl : "https://cientifico.sogesp.org.br/shared/apc/img/thumbs/thumb_video_geral.jpg"'
                        alt=""> -->
                    <div class="product-box">
                        <div class="faq-image product-img" (mouseover)="showEffect(true)"
                            (mouseout)="showEffect(false)"><img class="img-fluid activity-thumb"
                                [src]="Topic.thumbUrl ? Topic.thumbUrl : 'assets/images/thumb_video_geral.png'"
                                alt="">
                            <div class="product-hover" [class.product-hovered]="effect" (click)="openVideo(Topic.id)">
                                <ul>
                                    <li><i class="ion ion-play"></i></li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-7 col-md-8 col-lg-8 xl-55 col-xl-9">
                    <h6 class="f-14 f-w-300 m-b-0 topic-time" *ngIf="Topic.activity">
                        <i class="icon-calendar"></i><span class="m-l-5">{{Topic.activity.dayDate}} às
                            {{Topic.startTime}}
                        </span><i class="icon-clock m-l-5"></i><span class="m-l-5 m-r-10">{{
                            Duration }}</span>
                        <i class="icon-check font-primary" *ngIf="HasView"></i><span class="m-l-5 m-r-10 font-primary" *ngIf="HasView">aula assistida</span>
                        <span class="m-l-0 m-r-20 d-inline-block" *ngIf="false"><!--*ngIf="showRate"-->
                            <ngb-rating [(rate)]="rateLesson" class="rating-size font-warning" [max]="5"
                                [readonly]="true">
                                <ng-template let-fill="fill" let-index="index">
                                    <span class="star" [class.full]="fill === 100">
                                        <span class="half" [style.width.%]="fill"><span
                                                class="fa fa-star f-18 m-l-5 font-warning"></span></span><span
                                            class="fa fa-star-o f-18 m-l-5 font-warning"></span>
                                    </span>
                                </ng-template>
                            </ngb-rating>
                        </span>
                    </h6>
                    <h6 class="p-t-15" *ngIf="Topic.activity">
                        <span class="badge badge-dark m-b-5">{{
                            Topic.activity.coverageAreaDescription }}</span>
                        <span class="badge badge-primary m-r-5 m-b-5">{{
                            Topic.activity.abbreviation }} {{ Topic.activity.order }} </span>
                        <span class="badge badge-secondary m-l-0 m-b-5">{{
                            Topic.activity.thematicAreaDescription }}</span>
                    </h6>
                    <p class="f-w-600 f-16 m-t-5" [ngClass]="Topic.participations.length > 1 ? 'm-b-0' : 'm-b-15'"
                        style="cursor: pointer !important;" (mouseenter)="showEffect(true)"
                        (mouseout)="showEffect(false)" (click)="openVideo(Topic.id)">{{
                        getDescription(Topic) }}</p>

                    <div class="customers m-t-15">
                        <ul class="m-0">
                            <li class="d-inline-block m-t-10" *ngFor="let p of Topic.participations.slice(0, 3)">
                                <a [routerLink]="['/evento/palestrantes/perfil/', p.guestId]"
                                    *ngIf="p.guestName && !p.guestName.includes('A Definir')">
                                    <img class="img-50 rounded-circle" placement="top"
                                        [ngbTooltip]="util.getFirstAndLastName(p.guestName)"
                                        tooltipClass="custom-tooltip"
                                        [src]="p.guestPhoto ? p.guestPhoto : 'assets/images/user/user.png'" alt=""
                                        data-original-title="" title="">
                                </a>
                                <img class="img-50 rounded-circle" placement="top"
                                    *ngIf="!p.guestName || (p.guestName && p.guestName.includes('A Definir'))"
                                    [ngbTooltip]="util.getFirstAndLastName(p.guestName ? p.guestName : 'A Definir')" tooltipClass="custom-tooltip"
                                    [src]="p.guestPhoto ? p.guestPhoto : 'assets/images/user/user.png'" alt=""
                                    data-original-title="" title="">
                            </li>
                            <li class="d-inline-block ml-2 m-r-10 m-t-10">
                                <p *ngIf="Topic.participations.length && ((Topic.participations.length - 3) >= 1)"
                                    class="f-15 f-w-500 m-0">
                                    +{{(Topic.participations.length - 3)}} palestrante(s)</p>
                            </li>
                            <li class="d-inline-block nav-item ml-2 m-r-5 m-t-10" *ngIf="Topic.videoUrl">
                                <p class="f-15 f-w-500 m-0"><a href="javascript:void(0)" class="btn-light"
                                        (mouseenter)="showEffect(true)" (mouseout)="showEffect(false)"
                                        (click)="openVideo(Topic.id)"><i
                                            class="icon-control-play m-r-5 f-15 f-w-500"></i> <span
                                            class="d-inline">assistir
                                            aula</span></a>
                                </p>
                            </li>
                            <li *ngIf="Topic.materialUrl" class="d-inline-block ml-2 nav-item m-r-10 m-t-10">
                                <p class="f-15 f-w-500 m-0"><a class="btn-light" href="javascript:void(0)"
                                        (click)="openMaterialLink(Topic.materialUrl)"><i
                                            class="fa fa-file-zip-o m-r-5 f-15 f-w-500"></i> material disponível</a>
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
