import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';

import { AlertService } from 'src/app/shared/services/alert.service';
import { UpdateProfilePictureInput } from '../../models/user/user.model';
import { SavePermissionModel } from '../../models/user/save-permission.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private http: HttpClient;
  private baseUrl: string = environment.serverUrl;

  constructor(@Inject(HttpClient) http: HttpClient, private alert: AlertService) {
    this.http = http;
  }

  getSgunUserById(id: number, token: string) {
    let url = this.baseUrl + "User/GetSgunUser?id=" + id;

    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Athorization": "Bearer " + token
      })
    };

    return this.http.request("get", url, options);
  }

  updateProfilePic(model: UpdateProfilePictureInput){
    let url = this.baseUrl + "User/UpdateProfilePictureForEvent";
    let body = JSON.stringify(model);

    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      })
    };

    return this.http.post(url, body, options);
  }

  savePermissions(userId: number, emailAuthorized: number, personalAuthorized: number): Promise<any> {

    let url = this.baseUrl + "User/SavePermissions";
    let auth = new SavePermissionModel(userId, emailAuthorized, personalAuthorized);
    let body = JSON.stringify(auth);

    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };

    return this.http.post(url, body, options).toPromise();
  }


}
