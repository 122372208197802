<div class="media profile-media" (click)="toggleProfileMobile()">
  <img class="b-r-50 pic" *ngIf="_user"
    [src]="_user && _user.profilePicture ? _user.profilePicture : 'assets/images/dashboard/profile.jpg'" alt="">
  <div *ngIf="!_user" class="media-body enter">
    <a (click)="openLogin()">
      <app-feather-icons [icon]="'log-in'" class="icon-log-in"></app-feather-icons><span>Entrar</span>
    </a>
  </div>
  <div *ngIf="_user" class="media-body m-t-10 name-user-nav"><span>{{_user.name.split(' ')[0]}}</span>&nbsp;<i style="font-size: 16px !important;" class="middle fa fa-angle-down"></i>
    <!-- <p class="mb-0 font-roboto">Congressista&nbsp;
      <i class="middle fa fa-angle-down"></i></p> -->
  </div>
</div>
<!-- <div class="media profile-media">

</div> -->
<ul class="profile-dropdown onhover-show-div" [class.active]="openProfile">
  <li *ngIf="_user"><a [routerLink]="['/usuario/perfil']">
      <app-feather-icons [icon]="'user'"></app-feather-icons><span>Perfil</span>
    </a></li>
  <li *ngIf="_user" (click)="logout()"><a>
      <app-feather-icons [icon]="'log-out'"></app-feather-icons><span>Sair</span>
    </a></li>
</ul>
