import { Topic } from './../models/event/eventSchedule.model';
import { EventEmitter, Injectable, Output } from '@angular/core';
// import { BroadcastEventListener, IConnectionOptions, SignalR, SignalRConnection } from 'ng2-signalr';
import { environment } from '../../../environments/environment';
import { HubConnection, HubConnectionBuilder, LogLevel, HttpTransportType} from '@microsoft/signalr';
//Models
import { NotificationModel } from 'src/app/shared/models/event/notification.model';

@Injectable({
  providedIn: 'root'
})
export class EventHubService {

  @Output() notificationReceived = new EventEmitter();
  @Output() connectionEstablished = new EventEmitter();
  @Output() updateTopics = new EventEmitter();

  private _hubConnection: HubConnection;
  private baseUrl = environment.serverRootUrl + 'signalr-notification';
  private count: number = 0;

  constructor() {
    this.createConnection();
    this.registerOnServerEvents();
    this.startConnection();
  }

  private createConnection() {
    this._hubConnection = new HubConnectionBuilder()
      .withUrl(this.baseUrl, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets
      })
      .build();
  }

  private registerOnServerEvents() {
    this._hubConnection
      .on('getEventNotification', (data) => {
        console.log(data);
        this.notificationReceived.emit(data);
      });
  }

  private startConnection(): any {
    this._hubConnection
      .start()
      .then(() => {
        console.log('Hub de conexão iniciado');
        this.connectionEstablished.emit(true);
      }).catch(err => {
        console.log('Erro ao realizar conexão do signalR, tentando novamente');
        if (this.count <= 5)
          setTimeout(this.startConnection(), 5000);

        this.count++;
      });
  }

}


// @Injectable({
//   providedIn: 'root'
// })
// export class EventHubService {

//   @Output() notificationReceived = new EventEmitter();
//   @Output() updateTopics = new EventEmitter();
//   @Output() connectionEstablished = new EventEmitter();

//   public connection: SignalRConnection;

//   constructor(private signal: SignalR) {

//     this.configConnection();
//     this.startConnection();

//   }

//   configConnection(hubName?: string): SignalRConnection {
//     let name = hubName ? hubName : 'eventNotification';
//     let url = environment.cientificoUrl + '/signalr';
//     let options: IConnectionOptions = { hubName: name, url: url, jsonp: true };
//     if (this.connection == undefined)
//       this.connection = this.signal.createConnection(options);
//     return this.connection;
//   }

//   startConnection(): any {
//     var _start = this.connection.start().then(() => {
//       this.connectionEstablished.emit(true);
//       this.registerOnServerEvents();
//     });

//     this.connection.errors.subscribe((error: any) => {
//       console.log('SignalR error: ' + error);
//     });

//     return _start;
//   }

//   private registerOnServerEvents() {

//     //Receive Message
//     let onMessageSent$ = new BroadcastEventListener<NotificationModel>('sendMessage');

//     this.connection.listen(onMessageSent$);

//     onMessageSent$.subscribe((notification: NotificationModel) => {
//       this.notificationReceived.emit(notification);
//     });


//     //Receive Current Topics
//     let onTopicsSent$ = new BroadcastEventListener<Topic[]>('updateTopics');

//     this.connection.listen(onTopicsSent$);

//     onTopicsSent$.subscribe((topics: Topic[]) => {
//       this.updateTopics.emit(topics);
//     });

//   }

// }
