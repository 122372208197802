<form class="form-inline search-full open">
  <div class="form-group w-100" [class.view-result]="searchPageResult">
    <input class="form-control-plaintext" [(ngModel)]="text" [ngModelOptions]="{standalone: true}"
      (keyup)="searchTerm()" type="text" autocomplete="off" [placeholder]="getPlaceholder()"
      [style.text-transform]="text ? 'lowercase': 'initial'" joyrideStep="5" title="Busca" (done)="onDone()"
      [stepContent]="buscar">
    <ng-template #buscar>
      <div style="max-width: 400px; width: 310px;">Utilize este campo para buscar por conteúdos, aulas, palestrantes,
        etc.</div>
    </ng-template>
    <app-feather-icons [icon]="'arrow-left'" *ngIf="searchPageResult" class="close-search back-search"
      (click)="backToSearchGeneral()" placement="top" ngbTooltip="Voltar" tooltipClass="custom-tooltip">
    </app-feather-icons>
    <app-feather-icons [icon]="'x'" class="close-search" (click)="searchToggle()" *ngIf="text" placement="top"
      ngbTooltip="Limpar" tooltipClass="custom-tooltip">
    </app-feather-icons>
  </div>
</form>