import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Work } from 'src/app/shared/models/event/e-poster-model';
import { UtilService } from 'src/app/shared/services/util.service';

@Component({
  selector: 'app-e-poster-card',
  templateUrl: './e-poster-card.component.html',
  styleUrls: ['./e-poster-card.component.scss']
})
export class EPosterCardComponent {

  @Input() Work: Work;
  @Input() Index: string;
  @Input() IsEposterUser: boolean;
  @Output() goTo = new EventEmitter<Work>();

  expanded: boolean = false;

  constructor(
    private router: Router,
    public utilService: UtilService)
  { }

  viewMaterial(workId: number) {
    this.router.navigate(['evento/e-poster/visualizar/' + workId]);
  }

  getName(name: string) {

    name = this.utilService.capitalizeTerm(name);

    if (this.Index && name?.length && name[0].toLocaleLowerCase().includes(this.Index.toLocaleLowerCase()) ) {

      let splitStr = name.split(' ');
      splitStr[0] = '<span class="highlight-search">' + splitStr[0] + '</span>'

      return splitStr.join(' ');
    }

    return name;
  }


  viewPoster(expanded: boolean, el: HTMLElement): void {

    if (!expanded) {
      setTimeout(() => {
        el.scrollIntoView();
      }, 0);
    }
    this.expanded = !expanded;
  }
}
