<div class="card custom-card b-r-10 m-t-15 m-b-15 p-b-50">
    <div class="card-header">
        <img class="img-fluid" [src]="BackgroundImage">
    </div>
    <div class="card-profile">
        <div class="content-img-exhibitor">
            <img class="rounded-circle img-exhibitor" [src]='Exhibitor.logoListPath' placement="top" [ngbTooltip]="Exhibitor.name" tooltipClass="custom-tooltip tooltip-normal-text">
        </div>
    </div>
    <ul class="card-social">
        <li *ngFor="let socialNetwork of Exhibitor.socialNetworks">
            <a [href]="socialNetwork.link" target="_blank" *ngIf="socialNetwork.name == socialNetworkName.Facebook">
                <i class="fa fa-facebook" placement="top" ngbTooltip="Facebook" tooltipClass="custom-tooltip"></i>
            </a>
            <a [href]="socialNetwork.link" target="_blank" *ngIf="socialNetwork.name == socialNetworkName.Twitter">
                <i class="fa fa-twitter" placement="top" ngbTooltip="Twitter" tooltipClass="custom-tooltip"></i>
            </a>
            <a [href]="socialNetwork.link" target="_blank" *ngIf="socialNetwork.name == socialNetworkName.YouTube">
                <i class="fa fa-youtube" placement="top" ngbTooltip="YouTube" tooltipClass="custom-tooltip"></i>
            </a>
            <a [href]="socialNetwork.link" target="_blank" *ngIf="socialNetwork.name == socialNetworkName.Instagram">
                <i class="fa fa-instagram" placement="top" ngbTooltip="Instagram" tooltipClass="custom-tooltip"></i>
            </a>
            <a [href]="socialNetwork.link" target="_blank" *ngIf="socialNetwork.name == socialNetworkName.Linkedin">
                <i class="fa fa-linkedin" placement="top" ngbTooltip="LinkedIn" tooltipClass="custom-tooltip"></i>
            </a>
        </li>
        <li>
            <a [href]="Exhibitor?.websiteLink" target="_blank" *ngIf="Exhibitor?.websiteLink">
                <i class="fa fa-globe" placement="top" ngbTooltip="Site" tooltipClass="custom-tooltip"></i>
            </a>
        </li>
    </ul>
    <div class="text-center profile-details">
        <a class="btn btn-primary f-w-600 f-15 m-b-20"
            (click)="goTo(Exhibitor)">Acesse Aqui
        </a>
    </div>
</div>
