import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnInit, PLATFORM_ID, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ExhibitorFile } from 'src/app/shared/models/exhibitor/exhibitor.model';
import { EventService } from 'src/app/shared/services/event/event.service';
import { GoogleAnalyticsEventsService } from 'src/app/shared/services/google-analytics/google-analytics-events.service';

@Component({
  selector: 'video-modal',
  templateUrl: './video-modal.component.html',
  styleUrls: ['./video-modal.component.scss']
})
export class VideoModalComponent {

  @ViewChild("modalVideo", { static: false }) modalVideo: TemplateRef<any>;

  public show: boolean = false;
  public modalOpen: boolean = false;
  public eventColor: string;
  public title: string;
  public description: string;
  public videoUrl: string;
  public googleAnalyticsEvent: string;


  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal,
    private eventService: EventService,
    private googleAnalyticsEventsService: GoogleAnalyticsEventsService)
    {  }

  openModal(title: string, description: string, videoUrl: string, googleAnalyticsEvent: string = undefined) {

    this.title = title;
    this.description = description,
    this.videoUrl = videoUrl;
    this.googleAnalyticsEvent = googleAnalyticsEvent;
    this.eventColor = this.eventService?.currentEvent?.primaryColor;

    if (isPlatformBrowser(this.platformId)) { // For SSR
      this.modalService.open(this.modalVideo, {
        size: 'md',
        ariaLabelledBy: 'modal-bookmark',
        centered: true,
        windowClass: 'modal-bookmark',
      }).result.then((result) => {
        if (result)
          this.modalOpen = true;
      });
    }
  }

  showPassword() {
    this.show = !this.show;
  }

  ngOnDestroy() {
    if (this.modalService.hasOpenModals()) {
      this.modalService.dismissAll();
    }
  }

  startedVideo(): void {
    if (this.googleAnalyticsEvent)
      this.googleAnalyticsEventsService.emitEvent(this.googleAnalyticsEvent, this.description, "Vídeo");
  }

}
