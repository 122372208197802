import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgSelectConfig } from '@ng-select/ng-select';
import { SelectItem } from 'src/app/shared/models/shared/selectItem.model';

@Component({
  selector: 'app-institution-filter',
  templateUrl: './institution-filter.component.html',
  styleUrls: ['./institution-filter.component.scss']
})
export class InstitutionFilterComponent implements OnInit {

  @Input() InstitutionName: string;
  @Input() Institutions: SelectItem[] = [];
  @Output() searchByInstitution = new EventEmitter<string>();

  public isInstitution: boolean = false;

  constructor(private config: NgSelectConfig) {
    this.config.notFoundText = 'Nenhum item encontrado';
   }

  ngOnInit(): void {
  }

  onSelectedChange(value: string) {
    this.InstitutionName = value;
    this.filterByInstitution(this.InstitutionName)
  }

  filterByInstitution(name: string) {
    this.searchByInstitution.emit(name);
  }
}
