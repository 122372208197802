<div class="card custom-card b-r-10 p-20 p-b-50 m-t-15 m-b-15">
    <div *ngIf="IsFavorite && ShowFavorite" style="cursor: pointer;" placement="left" ngbTooltip="Remover dos Favoritos" tooltipClass="custom-tooltip"
        class="ribbon ribbon-bookmark ribbon-vertical-right ribbon-primary m-r-5" (click)="addFavorites(Id, false)"
        joyrideStep="05" stepPosition="top" title="Favorito" (done)="onDone()" [stepContent]="favorito">
        <i class="icofont icofont-ui-love"></i>
    </div>
    <div *ngIf="!IsFavorite && ShowFavorite" style="cursor: pointer;" placement="left" ngbTooltip="Favoritar" tooltipClass="custom-tooltip"
        class="ribbon ribbon-bookmark ribbon-vertical-right ribbon-light m-r-5" (click)="addFavorites(Id, true)"
        joyrideStep="05" stepPosition="top" title="Favorito" (done)="onDone()" [stepContent]="favorito">
        <i class="icofont icofont-ui-love"></i>
    </div>
    <ng-template #favorito>
        <div style="max-width: 400px; width: 310px;"><strong>Favorite</strong> ou <strong>remova dos favoritos</strong> o amigo.</div>
    </ng-template> 
    <div class="card-header"><img class="img-fluid" src="assets/images/user-card/background.jpg" alt="">
    </div>
    <div class="card-profile">
        <img class="rounded-circle" [src]="Picture ? Picture : 'assets/images/user/user.png'" alt="">
    </div>
    <div class="text-center profile-details">
        <h5 class="m-t-20 m-b-10 f-w-700 m-b-0 s-name">
            <highlight-words [innerHTML]="Name?.toLowerCase()"></highlight-words>
        </h5>
        <div class="name d-inline contact-info social-media s-info">
            <ul class="card-social" joyrideStep="03" stepPosition="top" title="Contatos" (done)="onDone()" [stepContent]="contatos">
                <li>
                    <a href="{{'mailto:' + Email}}" class="s-media">
                        <i class="fa fa-envelope-o" placement="top" ngbTooltip="E-Mail" tooltipClass="custom-tooltip"></i>
                    </a>
                </li>
                <li>
                    <a href="{{'https://api.whatsapp.com/send?1=pt_BR&phone=' + WhatsApp }}" target="blank" *ngIf="WhatsApp" class="s-media">
                        <i class="fa fa-whatsapp" placement="top" ngbTooltip="WhatsApp" tooltipClass="custom-tooltip"></i>
                    </a>
                </li>
                <li>
                    <a href="{{ Facebook }}" target="blank" *ngIf="Facebook" class="s-media">
                        <i class="fa fa-facebook" placement="top" ngbTooltip="Facebook" tooltipClass="custom-tooltip"></i>
                    </a>
                </li>
                <li>
                    <a href="{{ Instagram }}" target="blank" *ngIf="Instagram" class="s-media">
                        <i class="fa fa-instagram" placement="top" ngbTooltip="Instagram" tooltipClass="custom-tooltip"></i>
                    </a>
                </li>
                <li>
                    <a href="{{ LinkedIn }}" target="blank" *ngIf="LinkedIn" class="s-media">
                        <i class="fa fa-linkedin" placement="top" ngbTooltip="LinkedIn" tooltipClass="custom-tooltip"></i>
                    </a>
                </li>
                <li>
                    <a href="{{ Lattes }}" target="blank" *ngIf="Lattes" class="s-media">
                        <i class="fa fa-file-o" placement="top" ngbTooltip="Lattes" tooltipClass="custom-tooltip"></i>
                    </a>
                </li>
            </ul>
            <ng-template #contatos>
                <div style="max-width: 400px; width: 310px;">Navegue entre as opções para <strong>contatar</strong> o amigo.
                </div>
            </ng-template>
        </div>
        <h5 class="m-t-10 m-b-10 f-16 f-w-300 localization">{{ City }}, {{ CountryState }}</h5>
        <a href="javascript:void(0)" (click)="expanded = !expanded" class="universities"
            [attr.aria-expanded]="!expanded" aria-controls="collapse" *ngIf="Institutions?.length"
            placement="top" [ngbTooltip]="expanded ? 'Ocultar Formações' : 'Exibir Formações'" tooltipClass="custom-tooltip"
            joyrideStep="04" stepPosition="top" title="Formações" (done)="onDone()" [stepContent]="formacoes">
            Formações&nbsp;
            <i *ngIf="!expanded" class="icon-plus expand-icon"></i>
            <i *ngIf="expanded" class="icon-minus expand-icon"></i>
        </a>
        <ng-template #formacoes>
            <div style="max-width: 400px; width: 310px;">Visualize as formações do amigo.</div>
        </ng-template>
        <div class="collapse b-b-r-15" id="collapseicon" [ngbCollapse]="!expanded">
            <br>
            <h6 class="m-t-10 m-b-0 name" *ngFor="let institution of Institutions">{{ institution }}</h6>
        </div>
    </div>
</div>
