<div class="container-fluid">
  <div class="page-title">
    <div class="row">
      <div>
        <h3>{{title}}</h3>
      </div>
      <div class="breadcrumb-content">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a [routerLink]="'/'">
              <app-feather-icons [icon]="'home'" *ngIf="!isCongressUser"></app-feather-icons>
            </a>
          </li>
          <li class="breadcrumb-item" *ngFor="let item of items">{{item}}</li>
          <li class="breadcrumb-item" *ngFor="let item of route_items"><a [routerLink]="item.route">
              {{item.name}}
            </a></li>
          <li class="breadcrumb-item active">{{active_item}}
          </li>
        </ol>
      </div>
    </div>
  </div>
</div>