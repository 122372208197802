<div class="card">
    <div class="card-body ad-owl-carousel">
        <owl-carousel-o [options]="owlcarousel2Options" (dragging)="isDragging = $event.dragging">
            <ng-template carouselSlide class="item" [id]="BannerId?.toString()" *ngFor="let ban of Banners; let i=index">
                <div class="slider">
                    <img [src]="[ban.imageUrl]" alt="" [title]="ban.title" class="carousel-image" *ngIf="!ban.redirectPageName && !ban.link">

                    <a *ngIf="ban.redirectPageName"
                        [owlRouterLink]="[ban.redirectPageName + '/' + ban.redirectParamValue]" [stopLink]="isDragging">
                        <img [src]="[ban.imageUrl]" alt="" [title]="ban.title" class="carousel-image">
                        <span>{{ban.text}}</span>
                    </a>
                    <a *ngIf="ban.link" class="outer-link" [href]="ban.link" target="_blank">
                        <img [src]="[ban.imageUrl]" alt="" [title]="ban.title" class="carousel-image">
                    </a>
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>
</div>
