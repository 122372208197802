export class AuthenticateResultModel {
    accessToken: string;
    userId: number;
    sgunAuthResult: AuthenticateSgunResultModel;
}

export class AuthenticateSgunResultModel {
    guid: string;
    us_cookie: string;
}


