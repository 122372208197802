import { Injectable, OnDestroy } from '@angular/core';
import { Subject, BehaviorSubject, fromEvent } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router';

// Menu
export interface Menu {
  headTitle1?: string;
  headTitle2?: string;
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  bookmark?: boolean;
  children?: Menu[];
}

@Injectable({
  providedIn: 'root',
})
export class NavService implements OnDestroy {
  private unsubscriber: Subject<any> = new Subject();
  public screenWidth: BehaviorSubject<number> = new BehaviorSubject(
    window.innerWidth
  );

  // Search Box
  public search: boolean = false;

  // Language
  public language: boolean = false;

  // Mega Menu
  public megaMenu: boolean = false;
  public levelMenu: boolean = false;
  public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

  // Collapse Sidebar
  public collapseSidebar: boolean = true; //window.innerWidth < 991 ? true : false;
  public isFixedSidebar: boolean = false;

  // Full screen
  public fullScreen: boolean = false;

  constructor(private router: Router) {
    this.setScreenWidth(window.innerWidth);
    fromEvent(window, 'resize')
      .pipe(debounceTime(1000), takeUntil(this.unsubscriber))
      .subscribe((evt: any) => {
        this.setScreenWidth(evt.target.innerWidth);
        if (evt.target.innerWidth < 991) {
          this.collapseSidebar = true;
          this.megaMenu = false;
          this.levelMenu = false;
        }
        if (evt.target.innerWidth < 1199) {
          this.megaMenuColapse = true;
        }
      });
    if (window.innerWidth < 991) {
      // Detect Route change sidebar close
      this.router.events.subscribe((event) => {
        this.collapseSidebar = true;
        this.megaMenu = false;
        this.levelMenu = false;
      });
    }
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  private setScreenWidth(width: number): void {
    this.screenWidth.next(width);
  }

  MENUITEMS: Menu[] = [
    /*Begin Event*/
    {
      headTitle1: 'O Evento',
      headTitle2: 'Acesso rápido para áreas do evento.',
    },
    {
      path: '/evento/inicio',
      title: 'Início',
      icon: 'home',
      type: 'link',
    },
    {
      path: '/evento/sobre',
      title: 'Sobre',
      icon: 'airplay',
      type: 'link',
    },
    {
      path: '/evento/programacao',
      title: 'Programação',
      icon: 'calendar',
      type: 'link',
    },
    {
      path: '/evento/aulas',
      title: 'Aulas',
      icon: 'video',
      type: 'link',
    },
    {
      path: '/evento/palestrantes',
      title: 'Palestrantes',
      icon: 'users',
      type: 'link',
    },
    {
      path: 'evento/e-poster',
      title: 'E-Pôster',
      icon: 'file',
      type: 'link',
    },
    // {
    // 	path: '/evento/expositores', title: 'Expositores', icon: 'map', type: 'link',
    // },
    //Desativado a pedido da SOGESP
    // {
    // 	path: '/evento/networking', title: 'Networking', icon: 'share-2', type: 'link',
    // },

    /*Begin My Event*/

    {
      headTitle1: 'Meu Evento',
      headTitle2: 'Acesso fácil aos seus itens favoritos.',
    },
    {
      path: '/meu-evento/minha-agenda/',
      title: 'Minha Agenda',
      icon: 'clock',
      type: 'link',
    },
    {
      path: '/meu-evento/minhas-aulas',
      title: 'Aulas Favoritas',
      icon: 'bookmark',
      type: 'link',
    },
    {
      path: '/meu-evento/meus-palestrantes',
      title: 'Palestrantes Favoritos',
      icon: 'star',
      type: 'link',
    },
    {
      ///Desativado a pedido da SOGESP
      //path: '/meu-evento/meus-amigos', title: 'Meus Amigos', icon: 'users', type: 'link',
    },
    {
      path: '/meu-evento/certificado',
      title: 'Certificado',
      icon: 'download-cloud',
      type: 'link',
    },

    /*Begin Organization*/

    {
      headTitle1: 'Organização',
      headTitle2: 'Fale com a organização do evento.',
    },
    {
      path: '/organizacao/perguntas-e-respostas',
      title: 'Perguntas e Respostas',
      icon: 'list',
      type: 'link',
    },
    {
      path: '/organizacao/duvidas-e-sugestoes',
      title: 'Dúvidas e Sugestões',
      icon: 'help-circle',
      type: 'link',
    },
  ];

  MEGAMENUITEMS: Menu[] = [];

  LEVELMENUITEMS: Menu[] = [];

  // Array
  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
  megaItems = new BehaviorSubject<Menu[]>(this.MEGAMENUITEMS);
  levelmenuitems = new BehaviorSubject<Menu[]>(this.LEVELMENUITEMS);
}
