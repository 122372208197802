import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { Component, OnInit } from '@angular/core';
import { NgxIndexedDBService } from 'ngx-indexed-db';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  
  public today: number = Date.now();
  public isCongressUser: boolean = false;

  constructor(
    private dbService: NgxIndexedDBService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.checkUserLogged();
  }

  checkUserLogged(): void {
    this.authService.getUserEmitter().subscribe((user) => {
      this.isCongressUser = this.authService.isCongressUser();
    });
    this.dbService.getAll('User').subscribe((users) => {
      if (users && users.length) {
        this.isCongressUser = this.authService.isCongressUser();
      }
      else {
        this.isCongressUser = false;
      }
    });


  }
}
