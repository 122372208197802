import { DbService } from './../db.service';
import { Injectable } from '@angular/core';
import { NgxIndexedDBService } from 'ngx-indexed-db';
// Models
import { Filters, LessonFilter, ScheduleFilter } from 'src/app/shared/models/filter/filters.model';
// Services
import { EventService } from './../event/event.service';

@Injectable({
  providedIn: 'root'
})
export class FiltersService {

  private currentEventId: number = 0;
  public filters: Filters;

  constructor(
    private eventService: EventService,
    private dbService: NgxIndexedDBService,
    private appDbService: DbService)
  {
    this.getFilters();
  }

  saveLessonSearch(term: string, dates: string[], rooms: string[], thematicArea: string, coverageAreas: number[], types: number[], listMode: boolean, favorites: boolean, page: number): void {

    if (!this.filters)
      this.filters = new Filters(this.currentEventId);

    if (!this.filters.lesson)
      this.filters.lesson = new LessonFilter();

    if (this.filters.schedule)
      this.filters.schedule.isActive = false;

    this.filters.lesson.term = term;
    this.filters.lesson.dates = dates;
    this.filters.lesson.rooms = rooms;
    this.filters.lesson.thematicArea = thematicArea;
    this.filters.lesson.coverageAreas = coverageAreas;
    this.filters.lesson.types = types;
    this.filters.lesson.listMode = listMode;
    this.filters.lesson.favorites = favorites;
    this.filters.lesson.page = page;
    this.filters.lesson.isActive = true;

    this.saveFilters();
  }

  saveScheduleSearch(term: string, day: string, rooms: string[], thematicArea: string, coverageAreas: number[], types: number[], page: number, activityId?: number): void {

    if (!this.filters)
      this.filters = new Filters(this.currentEventId);

    if (!this.filters.schedule)
      this.filters.schedule = new ScheduleFilter();

    if (this.filters.lesson)
      this.filters.lesson.isActive = false;

    this.filters.schedule.term = term;
    this.filters.schedule.day = day;
    this.filters.schedule.rooms = rooms
    this.filters.schedule.thematicArea = thematicArea;
    this.filters.schedule.coverageAreas = coverageAreas;
    this.filters.schedule.types = types;
    this.filters.schedule.page = page;
    this.filters.schedule.isActive = true;

    if (activityId)
      this.filters.schedule.activityId = activityId;

    this.saveFilters();
  }

  getFilters(): Promise<Filters> {

    if (!this.currentEventId)
      return this.loadCurrentEventIdFilters();
    else
      return this.loadFilters();
  }

  private loadCurrentEventIdFilters() {
    return this.eventService.getCurrentEvent().then((ev: any) => {
      this.currentEventId = ev ? ev.id : 0;
      return this.loadFilters();
    })
  }

  private loadFilters() {

    return this.dbService.getAllByIndex('Filter', 'eventScheduleId', IDBKeyRange.only(this.currentEventId)).toPromise().then((filters) => {
      if (filters && filters.length)
        this.filters = filters[0] as Filters;

      return this.filters;
    });
  }

  private saveFilters() {

    try {
      this.appDbService.clear('Filter');
    }
    catch(e) { }

    this.appDbService.create(this.filters, 'Filter');
  }
}
