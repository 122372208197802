<div class="news notification h-100">
    <div class="card m-b-0 h-100">
        <div class="card-header card-no-border p-b-20">
            <div class="header-top">
                <h5 class="m-0">{{Title}}</h5>
            </div>
        </div>
        <div class="card-body pt-0 h-100 p-b-5">
            <div class="media features-faq product-box row" *ngFor="let topic of Topics">
                <div class="col-sm-4" *ngIf="ShowThumb">
                    <div class="faq-image product-img"><img class="img-fluid activity-thumb"
                            [src]="ThumbImage ? ThumbImage : 'assets/images/thumb_video_geral.png'"
                            alt="">
                        <div class="product-hover" (click)="openVideo(topic.id)">
                            <ul>
                                <li><i class="ion ion-play"></i></li>
                            </ul>

                        </div>
                    </div>
                </div>
                <div class="media-body col-12 col-sm-12" [ngClass]="ShowThumb ? 'col-xl-8' : 'col-xl-12'"
                    (click)="openVideo(topic.id)">
                    <div class="f-14">
                        <i class="icon-calendar m-r-5 f-w-300"></i><label
                            class="f-w-500 m-r-5">Disponível</label><label class="m-r-10 f-w-300">{{
                            topic.activity.dayDate }} às {{ topic.startTime ? topic.startTime : topic.activity.startTime }}
                        </label><br><i class="icon-clock m-r-5"></i><label
                            class="m-r-5 f-w-500">Duração</label><label class="m-r-10 f-w-300">{{
                            topic.duration ? topic.duration : topic.activity.duration }}</label>
                    </div>
                    <p>
                        <span class="badge badge-dark m-l-0 m-r-5">{{topic.activity.roomAbbreviation}}</span>
                        <span class="badge badge-primary m-l-0 m-r-5">{{topic.activity.type}} {{ topic.activity.order
                            }}</span>
                        <span
                            class="badge badge-secondary m-l-0 m-r-5">{{topic.activity.thematicAreaDescription}}</span>
                    </p>
                    <h6 class="f-w-600 f-16">
                        <div class="row m-0 p-0">
                            <div class="col-10 m-0 p-0">
                                <h6 class="f-w-600 f-16 m-0 ellipsis-2">{{ topic.description }}</h6>
                            </div>
                            <div class="col-2 m-0 p-0">
                                <app-feather-icons class="middle pull-right icon-right" [icon]="'youtube'"
                                    placement="top" [ngbTooltip]="'Assistir'" tooltipClass="custom-tooltip">
                                </app-feather-icons>
                            </div>
                        </div>
                    </h6>

                </div>
            </div>
            <div class="media features-faq product-box row" *ngFor="let activity of Activities">
                <div class="col-sm-4" *ngIf="ShowThumb">
                    <div class="faq-image product-img"><img class="img-fluid activity-thumb"
                            [src]="ThumbImage ? ThumbImage : 'assets/images/thumb_video_geral.png'"
                            alt="">
                        <div class="product-hover" (click)="openVideo(activity.topics[0].id)">
                            <ul>
                                <li><i class="ion ion-play"></i></li>
                            </ul>

                        </div>
                    </div>
                </div>
                <div class="media-body" [ngClass]="ShowThumb ? 'ol-xl-8' : 'col-xl-12'"
                    (click)="openVideo(activity.topics[0].id)">
                    <div class="f-14">
                        <i class="icon-calendar m-r-5 f-w-300"></i><label
                            class="f-w-500 m-r-5">Disponível</label><label class="m-r-10 f-w-300">{{
                            activity.dayDate }} às {{ activity.startTime }}
                        </label><i class="icon-clock m-r-5"></i><label
                            class="m-r-5 f-w-500">Duração</label><label class="m-r-5 f-w-300">{{
                            activity.duration }}</label>
                    </div>
                    <p>
                        <span class="badge badge-dark m-l-0 m-r-5">{{activity.roomAbbreviation}}</span>
                        <span class="badge badge-primary m-l-0 m-r-5">{{activity.type}} {{ activity.order }}</span>
                        <span class="badge badge-secondary m-l-0 m-r-5">{{activity.thematicAreaDescription}}</span>

                    </p>
                    <h6 class="f-w-600 f-16">
                        <div class="row m-0 p-0">
                            <div class="col-11 m-0 p-0">
                                <h6 class="f-w-600 f-16 m-0 ellipsis-2">{{ activity.description }}</h6>
                            </div>
                            <div class="col-1 m-0 p-0">
                                <app-feather-icons class="middle pull-right icon-right" [icon]="'youtube'"
                                    placement="top" [ngbTooltip]="'Assistir'" tooltipClass="custom-tooltip">
                                </app-feather-icons>
                            </div>
                        </div>
                    </h6>

                </div>
            </div>
        </div>
        <div class="card-footer card-no-border" *ngIf="ShowAllLink">
            <div class="bottom-btn text-left"><a [routerLink]="['/evento/programacao']">Todas as Aulas ...</a></div>
        </div>
    </div>
</div>
