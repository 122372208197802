import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
//Services
import { AuthService } from '../../shared/services/auth/auth.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { EventService } from 'src/app/shared/services/event/event.service';
import { UserService } from './../../shared/services/user/user.service';
//Models
import { User } from 'src/app/shared/models/user/user.model';
import { Event } from 'src/app/shared/models/event/event.model';
import { AuthenticateResultModel, AuthenticateSgunResultModel } from 'src/app/shared/models/user/auth.result.model';

@Component({
  selector: 'login',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss']
})
export class LoginModalComponent implements OnInit {

  @ViewChild("login", { static: false }) login: TemplateRef<any>;
  @ViewChild("modalPermission", { static: false }) permission: TemplateRef<any>;

  public modalOpen: boolean = false;
  public loginForm: FormGroup;
  public show: boolean = false;
  public errorMessage: any;
  public allowEmailMarketing: boolean = false;
  public allowSharePersonalInfo: boolean = false;
  public showLoader: boolean = false;
  private redirectToRoute: string;
  private redirectIfRegistered: boolean;
  private redirectIfPrescriber: boolean;

  public _user: User;
  public cpf: string;
  public password: string;
  public logo: string = 'assets/images/logo/logo_sogesp.png';
  public termText = '';

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private router: Router,
    private modalService: NgbModal,
    public authService: AuthService,
    public userService: UserService,
    private eventService: EventService,
    private alert: AlertService,
    private fb: FormBuilder) {

    this.loginForm = this.fb.group({
      cpf: ['', [Validators.required, Validators.maxLength]],
      password: ['', Validators.required]
    });
  }

  ngOnInit(): void {

    this.eventService.getCurrentEvent().then((ev: Event) => {
      this.termText = ev.authorizationTerm;
    });

    this.authService.getUserEmitter().subscribe((user: User) => {
      this._user = user;
      if (this._user) {
        this.ngOnDestroy();
      }
    });
  }

  auth(l: any) {

    this.authService.signIn(this.loginForm.value['cpf'], this.loginForm.value['password']).then((data: User) => {
      if (data) {
        if (data.allowEmailMarketing == null || data.allowSharePersonalInfo == null) {
          this._user = data;
          this.openModalPermission();
        }
        else {
          this.redirect();
        }
      }
    });
  }

  redirect() {

    if (this.redirectToRoute && this._user) {
      if (!this.redirectIfPrescriber && !this.redirectIfRegistered)
        this.router.navigate([this.redirectToRoute]);
      else if (this.redirectIfPrescriber && this.redirectIfRegistered) {
        if (this._user.paidRegistration && this._user.prescriber)
          this.router.navigate([this.redirectToRoute]);
      }
      else if (this.redirectIfPrescriber && !this.redirectIfRegistered) {
        if (this._user.prescriber)
          this.router.navigate([this.redirectToRoute]);
      }
      else if (!this.redirectIfPrescriber && this.redirectIfRegistered) {
        if (this._user.paidRegistration)
          this.router.navigate([this.redirectToRoute]);
      }
    }
  }

  savePermissions() {

    let textError = "Ocorreu um erro ao atualizar permissão.";

    let perm = this.allowEmailMarketing ? 1 : 0;
    let perm2 = this.allowSharePersonalInfo ? 1 : 0;
    this.showLoader = true
    this.userService.savePermissions(this._user.id, perm, perm2).then((data: any) => {
      this.showLoader = false;
      if (data) {
        this.alert.success("Sucesso", "Permissão atualizada com sucesso. Você pode alterar esta permissão a qualquer momento acessando seu perfil.");

        this.getUserInfo();

        this.redirect();

        this.ngOnDestroy();
      }
      else
        this.alert.error("Erro", textError);
    });
  }

  openModal(route?: string, redirectIfRegistered?: boolean, redirectIfPrescriber?: boolean) {

    this.logo = this.eventService?.currentEvent?.logo;
    this.redirectToRoute = route;
    this.redirectIfRegistered = redirectIfRegistered;
    this.redirectIfPrescriber = redirectIfPrescriber;
    if (isPlatformBrowser(this.platformId)) { // For SSR
      this.modalService.open(this.login, {
        size: 'md',
        ariaLabelledBy: 'modal-bookmark',
        centered: true,
        windowClass: 'modal-bookmark',
      }).result.then((result) => {
        if (result)
          this.modalOpen = true;

      });
    }
  }


  openModalPermission() {
    if (isPlatformBrowser(this.platformId)) { // For SSR
      this.modalService.open(this.permission, {
        size: 'md',
        ariaLabelledBy: 'modal-bookmark',
        centered: true,
        windowClass: 'modal-bookmark',
        backdrop: 'static',
        keyboard: false
      }).result.then((result) => {
        if (result)
          this.modalOpen = true;
      });
    }
  }

  updateEmailPermission() {
    this.allowEmailMarketing = !this.allowEmailMarketing;
  }

  updateInfoPermission() {
    this.allowSharePersonalInfo = !this.allowSharePersonalInfo;
  }

  getUserInfo() {
    let model = new AuthenticateResultModel();

    model.sgunAuthResult = new AuthenticateSgunResultModel();
    model.accessToken = this._user.accessToken;
    model.userId = this._user.id;
    model.sgunAuthResult.guid = this._user.guid;
    model.sgunAuthResult.us_cookie = this._user.usCookie
    this.authService.getCurrentUserInfo(model);
  }


  showPassword() {
    this.show = !this.show;
  }

  ngOnDestroy() {
    if (this.modalService.hasOpenModals()) {
      this.modalService.dismissAll();
    }
  }
}
