import { UtilService } from './../../../shared/services/util.service';
import { Topic } from './../../../shared/models/event/eventSchedule.model';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { ViewActivity } from 'src/app/shared/models/user/user.model';
import { NgxIndexedDBService } from 'ngx-indexed-db';

@Component({
  selector: 'app-lesson-card',
  templateUrl: './lesson-card.component.html',
  styleUrls: ['./lesson-card.component.scss']
})
export class LessonCardComponent implements OnInit {

  @Output() addToFavorites = new EventEmitter<{ id: number, confirm: boolean }>();
  @Output() openMaterial = new EventEmitter<string>();
  @Output() openVideoPage = new EventEmitter<number>();

  @Input() Topic: Topic;
  @Input() IsFavorite: boolean;
  @Input() Duration: string;
  @Input() HasView: boolean;

  public showRate: boolean = false;
  public effect: boolean = false;
  public rateLesson: number = 0;

  constructor(
    public util: UtilService,
    public authService: AuthService,
    private dbService: NgxIndexedDBService
  ) { }

  ngOnInit(): void {
    this.getRate(this.Topic.id);
    this.initShowRate();
  }

  initShowRate() {
    this.authService.getUserEmitter().subscribe((user) => {
      this.showRate = !!user;
    });

    this.dbService.getAll('User').subscribe((users) => {
      if (users && users.length) {
        this.showRate = true;
      }
    });
  }

  addFavorites(activityId: number, confirm: boolean) {
    this.addToFavorites.emit({ id: activityId, confirm: confirm });
  }

  openMaterialLink(material: string) {
    this.openMaterial.emit(material);
  }

  openVideo(id: number) {
    this.openVideoPage.emit(id);
  }

  showEffect(show: boolean) {
    this.effect = show;
  }

  getDescription(topic: Topic) {
    if (topic.activity && topic.description == 'Convidado') {
      let prevTopic = topic.activity.topics.filter(x => x.description != "Convidado" && x.order == topic.order - 1).sort((a, b) => a > b ? -1 : 1)[0];
      return prevTopic ? prevTopic.description : topic.activity.description;
    }
    else {
      return topic.description ? topic.description :  topic.activity.description;
    }
  }

  getRate(id: number) {

    if (this.authService._userLogged) {
      let views = JSON.parse(this.authService._userLogged.viewsActivities);
      let v = views.find((x: any) => x.ActivityId == id) as ViewActivity;
      if (v)
        this.rateLesson = v.Rate;
    }
  }

}
