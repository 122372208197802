import { AuthModule } from './../auth/auth.module';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DragulaModule } from 'ng2-dragula';
import { TranslateModule } from '@ngx-translate/core';
import { PlyrModule } from 'ngx-plyr';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { RoundProgressModule } from 'angular-svg-round-progressbar';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxHighlightWordsModule } from 'ngx-highlight-words';
import { JoyrideModule } from 'ngx-joyride';
// Components
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { FeatherIconsComponent } from './components/feather-icons/feather-icons.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { ContentComponent } from './components/layout/content/content.component';
import { FullComponent } from './components/layout/full/full.component';
import { LoaderComponent } from './components/loader/loader.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { TapToTopComponent } from './components/tap-to-top/tap-to-top.component';
import { ContactCardComponent } from './../components/event/contact-card/contact-card.component';
import { TermFilterComponent } from './../components/shared/term-filter/term-filter.component';
import { ImageMapperComponent } from './../components/shared/image-mapper/image-mapper.component';
import { NextLessonsCardComponent } from './../components/event/next-lessons-card/next-lessons-card.component';
import { LessonViewCardComponent } from './../components/event/lesson-view-card/lesson-view-card.component';
import { LessonCardComponent } from './../components/event/lesson-card/lesson-card.component';
import { InstitutionFilterComponent } from '../components/shared/institution-filter/institution-filter.component';
import { CountryStateCityFilterComponent } from './../components/shared/country-state-city-filter/country-state-city-filter.component';
import { CityFilterComponent } from '../components/shared/city-filter/city-filter.component';
import { JitsiMeetingComponent } from './../components/shared/jitsi-meeting/jitsi-meeting.component';
import { LoginCardComponent } from './../components/shared/login-card/login-card.component';

// Header Elements Components
import { SearchComponent } from './components/header/elements/search/search.component';
import { MegaMenuComponent } from './components/header/elements/mega-menu/mega-menu.component';
import { LanguagesComponent } from './components/header/elements/languages/languages.component';
import { NotificationComponent } from './components/header/elements/notification/notification.component';
import { BookmarkComponent } from './components/header/elements/bookmark/bookmark.component';
import { CartComponent } from './components/header/elements/cart/cart.component';
import { MessageBoxComponent } from './components/header/elements/message-box/message-box.component';
import { MyAccountComponent } from './components/header/elements/my-account/my-account.component';
import { AdBannerComponent } from '../components/shared/ad-banner/ad-banner.component';
import { VideoModalComponent } from './components/video/video-modal/video-modal.component';
import { VideoPlayerComponent } from '../components/shared/video-player/video-player.component';
import { ExhibitorsPanelComponent } from '../components/exhibitor/exhibitors-panel/exhibitors-panel.component';
import { SpeakerCardComponent } from '../components/event/speaker-card/speaker-card.component';
import { ExhibitorCardComponent } from '../components/exhibitor/exhibitor-card/exhibitor-card.component';
import { EPosterCardComponent } from '../components/event/e-poster-card/e-poster-card.component';
import { NotificationModalComponent } from './components/notification/notification-modal/notification-modal.component';
// Directives
import { DisableKeyPressDirective } from './directives/disable-key-press.directive';
import { OnlyAlphabetsDirective } from './directives/only-alphabets.directive';
import { OnlyNumbersDirective } from './directives/only-numbers.directive';
import { ShowOptionsDirective } from './directives/show-options.directive';
// Services
import { LayoutService } from './services/layout.service';
import { NavService } from './services/nav.service';
import { HighlightComponent } from './components/highlight/highlight.component';
import { FiltersService } from './services/filter/filters.service';


@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    ContentComponent,
    BreadcrumbComponent,
    FeatherIconsComponent,
    FullComponent,
    ShowOptionsDirective,
    DisableKeyPressDirective,
    OnlyAlphabetsDirective,
    OnlyNumbersDirective,
    LoaderComponent,
    TapToTopComponent,
    SearchComponent,
    MegaMenuComponent,
    LanguagesComponent,
    NotificationComponent,
    BookmarkComponent,
    CartComponent,
    MessageBoxComponent,
    MyAccountComponent,
    SpeakerCardComponent,
    LessonCardComponent,
    LessonViewCardComponent,
    NextLessonsCardComponent,
    ImageMapperComponent,
    ContactCardComponent,
    TermFilterComponent,
    InstitutionFilterComponent,
    CountryStateCityFilterComponent,
    CityFilterComponent,
    ImageMapperComponent,
    AdBannerComponent,
    VideoPlayerComponent,
    VideoModalComponent,
    JitsiMeetingComponent,
    ExhibitorsPanelComponent,
    ExhibitorCardComponent,
    EPosterCardComponent,
    HighlightComponent,
    LoginCardComponent,
    NotificationModalComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    DragulaModule.forRoot(),
    TranslateModule,
    AuthModule,
    PlyrModule,
    RoundProgressModule,
    PdfViewerModule,
    NgSelectModule,
    CarouselModule,
    NgxHighlightWordsModule,
    JoyrideModule.forChild(),
  ],
  providers: [
    NavService,
    LayoutService,
    NotificationComponent,
    FiltersService
  ],
  exports: [
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    LoaderComponent,
    PlyrModule,
    RoundProgressModule,
    PdfViewerModule,
    CarouselModule,
    BreadcrumbComponent,
    FeatherIconsComponent,
    TapToTopComponent,
    DisableKeyPressDirective,
    OnlyAlphabetsDirective,
    OnlyNumbersDirective,
    SpeakerCardComponent,
    LessonCardComponent,
    LessonViewCardComponent,
    NextLessonsCardComponent,
    ImageMapperComponent,
    ContactCardComponent,
    TermFilterComponent,
    InstitutionFilterComponent,
    CountryStateCityFilterComponent,
    CityFilterComponent,
    ImageMapperComponent,
    AdBannerComponent,
    VideoPlayerComponent,
    VideoModalComponent,
    JitsiMeetingComponent,
    ExhibitorsPanelComponent,
    ExhibitorCardComponent,
    EPosterCardComponent,
    HighlightComponent,
    LoginCardComponent,
    NotificationModalComponent
  ],
})
export class SharedModule { }
