import { ExhibitorGroupModel } from './../../models/exhibitor/exhibitor.model';
import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import * as _ from 'lodash';
//Services
import { UtilService } from 'src/app/shared/services/util.service';
import { DbService } from 'src/app/shared/services/db.service';

//Models
import { AddViewExhibitorInput } from './../../models/exhibitor/add-view-exhibitor-input.model';
import { SendExhibitorContatcMessageInput } from '../../models/exhibitor/send-exhibitor-contatc-message.model.ts';
import { Exhibitor } from '../../models/exhibitor/exhibitor.model';
import { NetworkConnectionService } from '../network-connection.service';

@Injectable({
  providedIn: 'root',
})
export class ExhibitorService {
  private http: HttpClient;
  private baseUrl: string;

  constructor(
    @Inject(HttpClient) http: HttpClient,
    private dbService: NgxIndexedDBService,
    private appDbService: DbService,
    private connectionService: NetworkConnectionService,
    private utilService: UtilService
  ) {
    this.http = http;
    this.baseUrl = environment.serverUrl;
  }

  getExhibitorsForSelect(id: number) {
    let url = this.baseUrl + 'Exhibitors/GetForSelect?eventId=' + id;

    let options: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.request('get', url, options);
  }

  getExhibitorsForSelectData(eventId: number): Promise<Exhibitor[]> {
    return this.dbService
      .getAllByIndex('Exhibitor', 'eventScheduleId', IDBKeyRange.only(eventId))
      .toPromise()
      .then((exhibitors: Exhibitor[]) => {
        if (
          exhibitors &&
          exhibitors.length &&
          !this.utilService.checkTimeToRefresh(exhibitors[0].lastRefreshDate) &&
          this.connectionService.checkConnection(true)
        )
          return exhibitors as Exhibitor[];
        else {
          return this.getExhibitorsForSelect(eventId)
            .toPromise()
            .then((data: any) => {
              let exhibitors = data.result as Exhibitor[];

              if (exhibitors && exhibitors.length) {
                exhibitors.forEach((e) => {
                  e.lastRefreshDate = new Date().toISOString();
                  this.appDbService.update(e, 'Exhibitor');
                });
              } else {
                exhibitors.forEach((e) => {
                  e.lastRefreshDate = new Date().toISOString();
                  this.appDbService.create(e, 'Exhibitor');
                });
              }

              return data.result;
            });
        }
      });
  }

  getAll(id: number) {
    let url = this.baseUrl + 'Exhibitors/GetAll?congressoId=' + id;

    let options: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.request('get', url, options);
  }

  GetAllGroupedByType(id: number) {
    let url = this.baseUrl + 'Exhibitors/GetAllGroupedByType?eventId=' + id;

    let options: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.request('get', url, options);
  }

  getExhibitorsGroupedByTypeData(
    eventId: number
  ): Promise<ExhibitorGroupModel[]> {

    return this.dbService
      .getAllByIndex('Exhibitor', 'eventScheduleId', IDBKeyRange.only(eventId))
      .toPromise()
      .then((exhibitors: Exhibitor[]) => {
        if (
          exhibitors &&
          exhibitors.length &&
          !this.utilService.checkTimeToRefresh(exhibitors[0].lastRefreshDate) &&
          this.connectionService.checkConnection(true)
        ) {
          exhibitors as Exhibitor[];

          var result = _.chain(
            exhibitors.sort((a, b) =>
              a.categoryOrder < b.categoryOrder ? -1 : 1
            )
          )
            .groupBy('category')
            .map((value, key) => ({ category: key, exhibitors: value }))
            .value() as ExhibitorGroupModel[];

          return result;
        } else {
          return this.GetAllGroupedByType(eventId)
            .toPromise()
            .then((data: any) => {
              if (exhibitors) {
                let groups = data.result as ExhibitorGroupModel[];
                groups.forEach((g) => {
                  let exhibitors = g.exhibitors as Exhibitor[];
                  exhibitors.forEach((e) => {
                    e.lastRefreshDate = new Date().toISOString();
                    this.appDbService.update(e, 'Exhibitor');
                  });
                });
                return groups;
              } else if (data.result) {
                let groups = data.result as ExhibitorGroupModel[];
                groups.forEach((g) => {
                  let exhibitors = g.exhibitors as Exhibitor[];
                  exhibitors.forEach((e) => {
                    e.lastRefreshDate = new Date().toISOString();
                    this.appDbService.create(e, 'Exhibitor');
                  });
                });
                return groups;
              }
            });
        }
      });
  }

  getTotalAndSaveView(userGuid: string, exhibitorId: number) {
    let url = this.baseUrl + 'Exhibitors/GetTotalAndSaveView';
    let view = new AddViewExhibitorInput();
    view.userGuid = userGuid;
    view.exhibitorId = exhibitorId;
    let body = JSON.stringify(view);

    let options: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post(url, body, options);
  }

  sendExhibitorContatcMessage(message: SendExhibitorContatcMessageInput) {
    let url = this.baseUrl + 'Exhibitors/SendExhibitorContatcMessage';

    let body = JSON.stringify(message);

    let options: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post(url, body, options);
  }
}
