<img class="img-map" [src]="src" usemap="#image-map">

<!--Usar https://www.image-map.net/ para mapear a imagem-->
<map name="image-map">
    <map name="image-map">
        <div class="area" *ngFor="let coordinate of coordinates">
            <area *ngIf="coordinate.externalLink; else internalLink" (click)="onAreaClick(coordinate)"
                (contextmenu)="onAreaEdit(coordinate)" target="{{coordinate.target}}" alt="{{coordinate.alt}}"
                title="{{coordinate.title}}" href="{{coordinate.externalLink}}" [coords]="coordinate.coordinates"
                shape="{{ coordinate.shape }}">

            <ng-template #internalLink>
                <a *ngIf="coordinate.link" [routerLink]="[coordinate.link]">
                    <area (click)="onAreaClick(coordinate)"
                        (contextmenu)="onAreaEdit(coordinate)" target="{{coordinate.target}}" alt="{{coordinate.alt}}"
                        title="{{coordinate.title}}" [coords]="coordinate.coordinates" shape="{{ coordinate.shape }}">
                </a>
            </ng-template>
        </div>
    </map>
</map>