<!-- Page Header Start-->
<div class="page-header" [class.close_icon]="navServices.collapseSidebar">
  <div class="header-wrapper row m-0">
    <div class="header-logo-wrapper">
      <div class="logo-wrapper">
        <a routerLink='/'>
          <img class="img-fluid for-light" [src]="logo" alt="">
          <img class="img-fluid for-dark" [src]="logoDark" alt="">
        </a>
      </div>
      <div class="toggle-sidebar" (click)="sidebarToggle()">
        <app-feather-icons [icon]="'grid'" class="status_toggle middle"></app-feather-icons>
      </div>
    </div>
    <div class="nav-right col pull-right right-header p-0">
      <ul class="nav-menus">
        <!--Idioma não será utilizado no projeto SOGESP-->
        <!-- <li class="language-nav" (click)="languageToggle()">
          <app-languages></app-languages>
        </li> -->
        <li class="w-search" [style.width.px]="getSearchWidth()">
          <app-search></app-search>
        </li>
        <li class="onhover-dropdown" *ngIf="userLogged">
          <app-notification></app-notification>
        </li>
        <li class="nav-mode" joyrideStep="4" title="Aparência" (done)="onDone()" [stepContent]="visualizacao">
          <ng-template #visualizacao>
            <div style="max-width: 400px; width: 310px;">Alterne o visual do site entre <strong>Tema Noturno</strong> (Tons Escuros)
              ou <strong>Tema Dia</strong> (Tons Claros).</div>
          </ng-template>
          <div class="mode" (click)="layoutToggle()">
            <i class="fa fa-moon-o" *ngIf="!dark" placement="top" ngbTooltip="Modo Noite"
              tooltipClass="custom-tooltip"></i>
            <i class="fa fa-lightbulb-o" *ngIf="dark" placement="top" ngbTooltip="Modo Dia"
              tooltipClass="custom-tooltip"></i>
          </div>
        </li>
        <li class="maximize" joyrideStep="3" title="Tamanho da Tela" (done)="onDone()" [stepContent]="tamanho">
          <ng-template #tamanho>
            <div style="max-width: 400px; width: 310px;">Alterne o tamanho da tela entre <strong>Tela Cheia</strong> ou <strong>Tela
                Normal</strong>.</div>
          </ng-template>
          <a class="text-dark" href="javascript:void(0)" (click)="toggleFullScreen()" placement="top"
            [ngbTooltip]="navServices.fullScreen ? 'Minimizar' : 'Maximizar'" tooltipClass="custom-tooltip">
            <app-feather-icons [icon]="'maximize'" *ngIf="!navServices.fullScreen"></app-feather-icons>
            <app-feather-icons *ngIf="navServices.fullScreen" [icon]="'minimize'"></app-feather-icons>
          </a>
        </li>
        <li *ngIf="showLayout" joyrideStep="2" title="Visualização" text="" (done)="onDone()"
          [stepContent]="visualizacao">
          <ng-template #visualizacao>
            <div style="max-width: 400px; width: 310px;">Alterne o modo de visualização da pagina inicial do site entre
              <strong>Desenho 3D</strong> ou <strong>Desktop</strong>.</div>
          </ng-template>
          <a class="text-dark" href="javascript:void(0)" placement="top"
            [ngbTooltip]="mobileLayout ? 'Modo 3d' : 'Modo Desktop'" tooltipClass="custom-tooltip"
            (click)="changeLayout()">
            <app-feather-icons *ngIf="!mobileLayout" [icon]="'layout'"></app-feather-icons>
            <app-feather-icons *ngIf="mobileLayout" [icon]="'codepen'"></app-feather-icons>
          </a>
        </li>
        <li class="profile-nav onhover-dropdown p-0" joyrideStep="1" title="Entrar"
          text="" (done)="onDone()" [stepContent]="entrar">
          <ng-template #entrar>
            <div style="max-width: 400px; width: 310px;">Clique aqui para acessar sua conta com login e senha no site.</div>
          </ng-template>
          <app-my-account></app-my-account>
        </li>
      </ul>
    </div>
  </div>
</div>
<!-- Page Header Ends -->