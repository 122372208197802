// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  title: 'Prod',
  serverRootUrl: 'https://api-sistema-cientifico.sogesp.org.br/',
  serverUrl: 'https://api-sistema-cientifico.sogesp.org.br/api/services/app/',
  cientificoUrl: 'https://sistema-cientifico.sogesp.org.br/',
  trabalhosUrl: 'http://trabalhos.sogesp.org.br/',
  sgunServiceUrl: 'https://sgunw.sogesp.org.br/SgunService.svc/rest',
  sgunReferer: 'https://api-eventos.sogesp.org.br',
  sgunAuthorization:
    'Basic M0MzMEYwRUEtQUNDRi00NTI0LUEzQ0ItMEIxMzU4RUE3RkYyOjc3OTQyMzQyLTQxQzUtNDYyMy04ODdCLTU3MEI0QjM1NjZFQw==',
  jaasDomain: '8x8.vc',
  jaasAppId: 'vpaas-magic-cookie-c69a9b92f2fb459fa44b0ac276e610f7',
  refreshMinutes: 120,
  googleAnalyticsId: 'G-M735SCJHTX',
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
