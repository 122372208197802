<ng-template #login let-modal>
    <div class="modal-header bg-light">
        <button class="close" type="button" data-dismiss="modal" aria-label="Close" data-original-title="" title=""
            (click)="modal.dismiss('Cross click')"><span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body p-0">
        <div class="container-fluid p-0">
            <div class="row">
                <div class="col-12">
                    <div class="login-card p-b-25 p-t-0 bg-light">
                        <div>
                            <div>
                                <a class="logo" routerLink='/'>
                                    <img class="img-fluid for-light" src="assets/images/logo/logo_sogesp.png" alt="loginpage">
                                    <img class="img-fluid for-dark" src="assets/images/logo/logo_sogesp.png" alt="loginpage">
                                </a>
                            </div>
                            <div class="login-main">
                                <form class="theme-form" [formGroup]="loginForm" (keyup.enter)="auth(loginForm.value)">
                                    <h5 class="form-title">Entrar na Minha Conta</h5>
                                    <p>Digite seu CPF e senha para acessar</p>
                                    <div class="form-group">
                                        <label class="col-form-label">CPF</label>
                                        <input class="form-control" type="text" required="true" maxlength="15"
                                            placeholder="" formControlName="cpf">
                                        <div *ngIf="loginForm.controls.cpf.touched && loginForm.controls.cpf.errors?.required"
                                            class="text text-danger mt-1">
                                            CPF é obrigatório
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label class="col-form-label">Senha</label>
                                        <input class="form-control" [type]="show ? 'text' : 'password'"
                                            formControlName="password" required="" placeholder="">
                                        <div class="show-hide" (click)="showPassword()" *ngIf="!show"><span
                                                class="show"></span></div>
                                        <div class="show-hide" (click)="showPassword()" *ngIf="show"><span
                                                class="Hide"></span></div>
                                        <div *ngIf="loginForm.controls.password.touched && loginForm.controls.password.errors?.required"
                                            class="text text-danger mt-1">
                                            A Senha é obrigatória
                                        </div>
                                    </div>
                                    <div class="form-group mb-0">
                                        <div class="checkbox p-0">
                                            <input id="checkbox1" type="checkbox">
                                            <label class="text-muted" for="checkbox1">Permanecer Logado</label>
                                        </div>
                                        <a href="https://sgun.sogesp.org.br/account/login/?forget=true" target="blank"
                                            class="link">Esqueceu sua
                                            senha?</a>
                                        <div class="text-end mt-3">
                                            <button class="btn btn-primary btn-block"
                                                [class.loader--text]="authService.showLoader"
                                                [disabled]="!loginForm.valid || authService.showLoader"
                                                (click)="auth(loginForm.value)" type="button"><span>{{
                                                    authService.showLoader ? '' : 'Acessar' }}</span>
                                            </button>
                                        </div>
                                    </div>
                                    <p class="mt-4 mb-0">Ainda não fez sua inscrição?
                                        <a href="https://www.sogesp.com.br/congressos-e-eventos/inscreva-se-nos-eventos-disponiveis/"
                                            target="blank" class="ml-2">Clique Aqui</a>
                                    </p>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<!--Modal Permissões-->
<ng-template #modalPermission let-modal>
    <div class="modal-header">
        <h4 id="modal-basic-title" class="modal-title">Permissões</h4>
    </div>
    <div class="modal-body">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div [innerHtml]="termText"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <label class="form-label">Autoriza recebimento de materiais publicitários dos patrocinadores
                            deste evento?</label>
                        <br>
                        <div class="media-body">
                            <label class="switch custom-switch m-b-0 m-r-20">
                                <input type="checkbox"
                                    (click)="updateEmailPermission()"><span class="switch-state"
                                    [ngClass]="allowEmailMarketing ? 'bg-success after' : 'before'"></span>
                                <span *ngIf="allowEmailMarketing" class="absolute-yes">SIM</span>
                                <span *ngIf="!allowEmailMarketing" class="absolute-no">NÃO</span>

                            </label>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="form-label">Autoriza o compartilhamento de seus dados nas salas de contato e
                            networking?</label>
                        <br>
                        <div class="media-body">
                            <label class="switch custom-switch m-b-0 m-r-20">
                                <input type="checkbox"
                                    (click)="updateInfoPermission()"><span class="switch-state"
                                    [ngClass]="allowSharePersonalInfo ? 'bg-success after' : 'before'"></span>
                                <span *ngIf="allowSharePersonalInfo" class="absolute-yes">SIM</span>
                                <span *ngIf="!allowSharePersonalInfo" class="absolute-no">NÃO</span>

                            </label>
                        </div>
                    </div>
                    <button class="btn btn-primary btn-block" [class.loader--text]="showLoader" [disabled]="showLoader"
                        (click)="savePermissions()" type="button"><span>{{
                            showLoader ? '' : 'Salvar' }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-template>
