import { EventSchedule } from 'src/app/shared/models/event/eventSchedule.model';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Component, PLATFORM_ID, Inject, ViewChild, Output, EventEmitter } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { map, delay, withLatestFrom, filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { JoyrideService } from 'ngx-joyride';
//Components
import { LoginModalComponent } from './auth/login/login-modal.component';
//Services
import { LoadingBarService } from '@ngx-loading-bar/core';
import { LayoutService } from './shared/services/layout.service';
import { TranslateService } from '@ngx-translate/core';
import { EventService } from './shared/services/event/event.service'
import { UtilService } from './shared/services/util.service';
//Models
import { Event } from './shared/models/event/event.model';
import { AuthService } from './shared/services/auth/auth.service';

declare var gtag;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  @ViewChild("login") login: LoginModalComponent;

  @Output() updateSchedule: EventEmitter<boolean> = new EventEmitter<boolean>();

  private googleAnalyticsId = environment.googleAnalyticsId;
  public loaderColor: string = 'DE3051';
  public eventId: number;
  public inTour: boolean = false;

  // For Progressbar
  loaders = this.loader.progress$.pipe(
    delay(1000),
    withLatestFrom(this.loader.progress$),
    map(v => v[1]),
  );

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private router: Router,
    private loader: LoadingBarService,
    translate: TranslateService,
    private layoutService: LayoutService,
    private joyrideService: JoyrideService,
    public util: UtilService,
    private eventService: EventService,
    private route: ActivatedRoute,
    private authService: AuthService) {
    if (isPlatformBrowser(this.platformId)) {
      translate.setDefaultLang('pt');
      translate.addLangs(['en', 'de', 'es', 'fr', 'pt', 'cn', 'ae']);
    }


    // Google Analytics
    const navEndEvents$ = router.events
      .pipe(
        filter(event => event instanceof NavigationEnd)
      );

    navEndEvents$.subscribe((event: NavigationEnd) => {
      if (event instanceof NavigationEnd) {
        gtag('config', this.googleAnalyticsId, {
          'page_path': event.urlAfterRedirects
        });
      }
    });
  }



  openLogin(route?: string, redirectIfRegistered?: boolean, redirectIfPrescriber?: boolean) {
    this.login.openModal(route, redirectIfRegistered, redirectIfPrescriber);
  }

  ngOnInit() {

    this.route.queryParams.subscribe((params) => {
      let code = params.evento;
      if (code && code != localStorage.getItem('eventCode')){
          localStorage.setItem('eventCode', code);
          this.authService.signOut();

      }

        this.eventService.getCurrentEvent().then((event: Event) => {
          this.eventId = event.id
          this.loadEvent();
        });
    });



  }

  /*
    Insere as cores do evento no tema do app
  */
  loadEvent() {

    this.eventService.getEventData(this.eventId).then((event: Event) => {
      this.eventId = event.id;
      this.layoutService.setThemeColors(event.primaryColor, event.secondaryColor);
      this.loaderColor = event.primaryColor;
      this.eventService.getCurrentEvent();

      this.eventService.getCompleteEventScheduleData(this.eventId).then((data: EventSchedule) => {
        if (data.updated)
          this.updateSchedule.emit(true);
      });
    });
  }

  getScheduleEmitter() {
    return this.updateSchedule;
  }

  //TODO: D.R.Y
  onDone() {
    localStorage.setItem('viewedTour', 'true');
  }
}
