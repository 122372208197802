import { EventEmitter, Injectable, Output } from '@angular/core';
// Models
import { SearchModel } from 'src/app/shared/models/event/search.model.ts';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  @Output() searchKeyup: EventEmitter<any> = new EventEmitter<any>();
  @Output() searchToggle: EventEmitter<any> = new EventEmitter<any>();
  @Output() searchPageResult: EventEmitter<any> = new EventEmitter<any>();

  public search: SearchModel = new SearchModel()

  constructor() { }

  searchTerm(term): void {
    this.searchKeyup.emit(term);
  }

  getSearchEmitter() {
    return this.searchKeyup;
  }

  closeSerch(): void {
    this.searchToggle.emit(false);
  }

  getSearchToggleEmitter() {
    return this.searchToggle;
  }

  goToPageSearchResult() {
    this.searchPageResult.emit();
  }

  getSearchPageResultEmitter() {
    return this.searchPageResult;
  }

  saveSearch(term: string, page: number): void {
    this.search.term = term;
    this.search.page = page;
  }

  getSearchModel(): SearchModel {
    return this.search;
  }
}
