import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
import { ConnectionService } from 'ng-connection-service';

@Injectable({
  providedIn: 'root'
})
export class NetworkConnectionService {
  connected = true;
  connectionIsActive: boolean = navigator.onLine;

  constructor(private connectionService: ConnectionService, private toaster: ToastrService) {

    this.connectionService.monitor().subscribe(isConnected => {
      this.connected = isConnected;
      if (this.connected) {
        this.connectionIsActive = true;
        toaster.success('Conexão com a internet ativa');
      }
      else {
        this.connectionIsActive = false;
        toaster.error('Sem conexão com a internet');
      }
    })
  }

  getStatus() {
    let status: any;
    if (this.connectionIsActive)
      status = ConnectionStatusEnum.Online;
    else if (!this.connectionIsActive)
      status = ConnectionStatusEnum.Offline
  }

  checkConnection(showMessage?: boolean, notConnectedMessage?: string): boolean {

    if (showMessage && !this.connectionIsActive)
      this.toaster.error(notConnectedMessage ? notConnectedMessage : 'Sem conexão com a internet')

    return this.connectionIsActive;
  }

}

export enum ConnectionStatusEnum {
  Online,
  Offline
}
