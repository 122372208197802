import { Topic } from './../../../shared/models/event/eventSchedule.model';
import { UtilService } from 'src/app/shared/services/util.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { Activity } from 'src/app/shared/models/event/eventSchedule.model';
import { DbService } from 'src/app/shared/services/db.service';

@Component({
  selector: 'app-next-lessons-card',
  templateUrl: './next-lessons-card.component.html',
  styleUrls: ['./next-lessons-card.component.scss']
})
export class NextLessonsCardComponent implements OnInit {

  @Output() openVideoPage = new EventEmitter<number>();

  @Input() Title: string;
  @Input() TopCount: number = 3;
  @Input() ShowAllLink: boolean;
  @Input() ShowThumb: boolean;
  @Input() Topics: Topic[] = [];
  @Input() Activities: Activity[] = [];
  @Input() ThumbImage: string;


  constructor(
    private dbService: NgxIndexedDBService,
    private utilService: UtilService) { }

  ngOnInit(): void {

    this.Topics.forEach(x => {
      x.description = this.getDescription(x);
    });
  }

  openVideo(id: number) {
    this.openVideoPage.emit(id);
  }

  getDescription(topic: Topic) {
    if (topic.activity && topic.description == 'Convidado') {

      let prevTopic = topic.activity.topics.filter(x => x.description != "Convidado" && x.order == topic.order - 1).sort((a, b) => a > b ? -1 : 1)[0];
      return prevTopic ? prevTopic.description : topic.activity.description;
    }
    else {
      return topic.description ? topic.description : topic.activity.description;
    }
  }

}
