import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AuthRoutingModule } from './auth-routing.module';

import { LoginModalComponent } from './login/login-modal.component';

@NgModule({
  declarations: [ ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    FormsModule,  
    ReactiveFormsModule    
  ],
  exports: [
  ]
})
export class AuthModule { }
