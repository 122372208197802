import { NetworkMeetingModel } from './../event/network-meeting.model';
import { StorageRefresh } from "../shared/storage-refresh"

export class Exhibitor extends StorageRefresh{

    constructor() {
        super();
    }

    id: number
    cnpj: string
    name: string
    description: string
    about: string
    logoPath: string
    logoListPath: string
    emblemPath: string
    type: number
    category: string
    categoryId: string
    categoryOrder: number
    isMaster: number
    prescriber: number
    order: number
    eventScheduleId: number
    websiteLink: string
    surveyLink: string
    surveyPrescriber: number
    imageSurvey: string
    drawLink: string
    imageDraw: string
    meeting: NetworkMeetingModel
    files: ExhibitorFile[]
    videos: ExhibitorFile[]
    banners: ExhibitorBannerModel[]
    socialNetworks: ExhibitorSocialNetwork[]
    drawCategories: DrawCategoryModal[]
    lastRefreshDate: string
}

export class ExhibitorFile {
    id: number
    description: string
    videoUrl: string
    size:any;
    file: string
    thumbImage: string
    prescriber: boolean
    exhibitorId: number
}

export class ExhibitorBannerModel {
    id: number
    description: string
    title: string
    text: string
    externalLink: string
    internalLink: string
    file: string
    order: number
    exhibitorId: number
}

export class ExhibitorSocialNetwork {
    id: number
    name: SocialNetworkName
    link: string
    exhibitorId: number
}

export enum SocialNetworkName {
    Facebook,
    Twitter,
    YouTube,
    Instagram,
    Linkedin
}

export class DrawCategoryModal {
    id: number;
    categoryId: number;
    description: string;
    associationTypeId: number;
}

export class ExhibitorGroupModel {

    public category: string;
    public order: number;
    public exhibitors: Exhibitor[];
}
