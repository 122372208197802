import { DbService } from 'src/app/shared/services/db.service';
import { EventHubService } from '../../../../services/event-hub.service';
import { UtilService } from 'src/app/shared/services/util.service';
import { EventService } from 'src/app/shared/services/event/event.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NotificationModel } from 'src/app/shared/models/event/notification.model';
import { Event } from 'src/app/shared/models/event/event.model';
import { NotificationModalComponent } from '../../../notification/notification-modal/notification-modal.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

  public openNotification: boolean = false;
  public notifications: NotificationModel[] = [];
  public unreadNotifications: NotificationModel[] = [];
  public currentEventId: number;

  @ViewChild("notificationModal") notificationModal: NotificationModalComponent;


  constructor(private eventService: EventService,
    private router: Router,
    public util: UtilService,
    private dbService: DbService,
    private hub: EventHubService) { }

  ngOnInit() {

    this.eventService.getCurrentEvent().then((ev: Event) => {
      this.currentEventId = ev ? ev.id : 0;
      this.loadNotifications(this.currentEventId);
    });

    this.eventService.getUpdateNotificationsEmitter().subscribe((id: number) => {
      this.loadNotifications(id);
    });


    this.hub.notificationReceived.subscribe((data: any) => {

      let notificationResult = JSON.parse(data);
      if (notificationResult.resposta.codigo == 0) {
        let notification = notificationResult.resposta.retorno as NotificationModel;
        notification.message = decodeURI(notification.message);


        this.dbService.get(notification.id, "Notification").subscribe(data => {
          if (!data) {
            this.dbService.create(notification, 'Notification');

            this.eventService.getCurrentEvent().then((ev: Event) => {
              let currentEventId = ev ? ev.id : 0;

              this.loadNotifications(currentEventId);
            });
          }
        });
      }
    });
  }

  public loadNotifications(id: number) {
    if (id > 0) {

      this.eventService.getNotificationsData(id).then((data: NotificationModel[]) => {
        if (data) {
          this.notifications = data.sort((a, b) => (a.sendDateTime > b.sendDateTime ? -1 : 1));
          this.unreadNotifications = this.notifications.filter(x => x.isRead === 0);

          this.notifications = this.notifications.slice(0, 5);
        }
      });
    }
  }

  openNotificationModal(notification: NotificationModel) {
    this.notificationModal.openModal(notification.id, notification.title, notification.message);
  }

  markNotificationAsRead(notification: any, setIsRead?: number) {
    this.dbService.get(notification.id, "Notification").subscribe(n => {
      n.isRead = setIsRead || setIsRead == 0 ? setIsRead : notification.isRead;
      this.dbService.update(n, "Notification");

      this.eventService.updateNotifications.emit(this.currentEventId)

      this.loadNotifications(this.currentEventId);
    });
  }

  toggleNotificationMobile() {
    this.openNotification = !this.openNotification;
  }

  openNotifications(){
    this.router.navigate(['evento/notificacoes']);
    this.openNotification = false;
  }

}
