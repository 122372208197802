<div class="default-according style-1 faq-accordion job-accordion" id="accordioncity">
    <div class="row">
        <div class="col-xl-12">
            <div class="card m-b-0">
                <div class="card-header">
                    <h5 class="mb-0">
                        <button class="btn btn-link pl-0" (click)="isInstitution = !isInstitution"
                            [attr.aria-expanded]="!isInstitution" aria-controls="collapse">Faculdade</button>
                    </h5>
                </div>
                <div class="collapse" id="collapseicon2" [ngbCollapse]="isInstitution">
                    <div class="card-body animate-chk b-b-r-15">
                        <div class="faq-form">
                            <ng-select [items]="Institutions" bindLabel="description" bindValue="description" placeholder="Selecione" [(ngModel)]="InstitutionName" (ngModelChange)="onSelectedChange($event)" [tabIndex]="10"></ng-select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
