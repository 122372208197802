import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgSelectConfig } from '@ng-select/ng-select';
import { SelectItem } from 'src/app/shared/models/shared/selectItem.model';

@Component({
  selector: 'app-city-filter',
  templateUrl: './city-filter.component.html',
  styleUrls: ['./city-filter.component.scss']
})
export class CityFilterComponent implements OnInit {

  @Input() CityName: string;
  @Input() Cities: SelectItem[] = [];

  @Output() searchByCity = new EventEmitter<string>();

  public isCity: boolean = false;

  constructor(private config: NgSelectConfig) { 
    this.config.notFoundText = 'Nenhum item encontrado';
  }

  ngOnInit(): void {
  }

  onSelectedChange(value: string) {
    this.CityName = value;
    this.filterByCity(this.CityName)
  }

  filterByCity(name: string) {
    this.searchByCity.emit(name);
  }

}
