import { Injectable } from '@angular/core';

import { NgxIndexedDBService } from 'ngx-indexed-db';

@Injectable({
  providedIn: 'root'
})
export class DbService {

  constructor(private dbService: NgxIndexedDBService) { }

  getAll(currentstore: string) {
    return this.dbService.getAll(currentstore);
  }

  get(id: number, currentstore: string) {
    return this.dbService.getByKey(currentstore, id);
  }

  /**
   *Create a object
   */
  create(obj: any, currentstore: string) {

    this.dbService.add(currentstore, obj).subscribe((key) => {
      // console.log('data saved successfully ', key);
    }, error => { console.log(error); }
    );
  }

  /**
  *Update a object
  */
  update(obj: any, currentstore: string) {
    this.dbService
      .update(currentstore, obj)
      .subscribe((storeData) => {
        // console.log('storeData: ', storeData);
      }, error => { console.log(error) });
  }

  /**
  *Delete a object
  */
  delete(id: number, currentstore: string) {
    this.dbService.delete(currentstore, id).subscribe((key) => {
      // console.log('data deleted successfully ', key);
    }, error => { console.log(error); });
  }

  clear(currentstore: string) {
    this.dbService.clear(currentstore).subscribe((successDeleted) => {
      // console.log('success? ', successDeleted);
    }, error => { console.log(error); });
  }

  /**
  *Clear all tables
  */
  clearDb() {

    this.dbService.clear('User');
    this.dbService.clear('Event');
    this.dbService.clear('CoverageArea');
    this.dbService.clear('ActivityType');
    this.dbService.clear('ThematicArea');
    this.dbService.clear('EventSchedule');
    this.dbService.clear('Day');
    this.dbService.clear('Activity');
    this.dbService.clear('Guest');
    this.dbService.clear('GuestActivity');
    this.dbService.clear('Participation');
    this.dbService.clear('Exhibitor');
    this.dbService.clear('CoverageAreaEPoster');
    this.dbService.clear('WorkEPoster');
    this.dbService.clear('Question');
    this.dbService.clear('Notification');

  }

  /* Implementar outros métodos e funções de acordo com a necessidade. */
}
