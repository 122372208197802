import { Router } from '@angular/router';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { Component, Input, OnInit } from '@angular/core';
// Components
import { AppComponent } from 'src/app/app.component';
// Models
import { User } from 'src/app/shared/models/user/user.model';
import { Exhibitor, SocialNetworkName } from 'src/app/shared/models/exhibitor/exhibitor.model';
// Services
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { AlertService } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-exhibitor-card',
  templateUrl: './exhibitor-card.component.html',
  styleUrls: ['./exhibitor-card.component.scss']
})
export class ExhibitorCardComponent implements OnInit {

  public user: User = undefined;

  @Input() Exhibitor: Exhibitor;
  @Input() BackgroundImage: string = 'assets/images/user-card/background.jpg';

  public socialNetworkName = SocialNetworkName;

  constructor(
    private router: Router,
    private alert: AlertService,
    private authService: AuthService,
    private appComponent: AppComponent,
    private dbService: NgxIndexedDBService,
  ) { }

  ngOnInit(): void {

    this.authService.getUserEmitter().subscribe((user) => {
      this.user = user as User;
    });

    this.dbService.getAll('User').subscribe((users) => {
      if (users && users.length) {
        this.user = users[0] as User;
      }
    });
  }

  goTo(exhibitor: Exhibitor): void {
    var route = '/evento/expositores/visualizar/' + exhibitor.id;
    if (!this.user) {
      this.appComponent.openLogin(route, true, (exhibitor.prescriber == 1));
    }
    else if (!this.user.paidRegistration && exhibitor.cnpj != this.user.userName)
      this.alert.error('Área Restrita!', 'Necessário estar inscrito no evento para visualizar o expositor.')
    else if (exhibitor.prescriber && !this.user.prescriber && exhibitor.cnpj != this.user.userName)
      this.alert.error('Área Restrita!', 'Este expositor está disponível apenas para prescritores.');
    else
      this.router.navigate([route]);
  }

}
