import { Component, OnInit, Input } from '@angular/core';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { AuthService } from '../../services/auth/auth.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  @Input() title: string;
  @Input() items: any[];
  @Input() active_item: string;
  @Input() route_items: BreadCrumbRouteItem[];

  isCongressUser: boolean = false;

  constructor(private dbService: NgxIndexedDBService,
    private authService: AuthService) {
  }

  ngOnInit() {
    this.checkUserLogged()
  }

  checkUserLogged(): void {
    this.authService.getUserEmitter().subscribe((user) => {
      this.isCongressUser = this.authService.isCongressUser();
    });

    this.dbService.getAll('User').subscribe((users) => {
      if (users && users.length) {
        this.isCongressUser = this.authService.isCongressUser();
      }
      else {
        this.isCongressUser = false;
      }
    });
  }

}

export class BreadCrumbRouteItem{
   name: string;
   route: string;
}
