export const environment = {
    production: true,
    title: 'Test',
    serverRootUrl:'https://api-teste-cientifico.sogesp.org.br/',
    serverUrl: 'https://api-teste-cientifico.sogesp.org.br/api/services/app/',
    cientificoUrl: 'https://novocientifico-teste.sogesp.org.br/',
    trabalhosUrl: 'https://trabalhos-teste.sogesp.org.br/',
    sgunServiceUrl: 'https://sgunw-teste.sogesp.org.br/SgunService.svc/rest',
    sgunReferer: 'https://api-teste-cientifico.sogesp.org.br',
    sgunAuthorization: 'Basic M0MzMEYwRUEtQUNDRi00NTI0LUEzQ0ItMEIxMzU4RUE3RkYyOjc3OTQyMzQyLTQxQzUtNDYyMy04ODdCLTU3MEI0QjM1NjZFQw==',
    jaasDomain: '8x8.vc',
    jaasAppId: 'vpaas-magic-cookie-c69a9b92f2fb459fa44b0ac276e610f7',
    refreshMinutes: 30,
    googleAnalyticsId: 'G-F6NN3YFQY4'
  };
