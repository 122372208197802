<!-- footer start-->
<div class="container-fluid">
  <div class="row">
    <div class="col-md-8 footer-copyright text-left">
      <p class="mb-0">Copyright {{ today | date:'y'}} © Congresso 2021 Todos os Direitos Reservados </p>
    </div>
    <div class="col-md-4 footer-powered">
      <p class="mb-0">Powered by 
        <a href="https://www.rmcbrothers.com.br" target="_blank" *ngIf="!isCongressUser"><span id="powered">RMC Brothers</span></a>
        <a href="javascript:void(0)" *ngIf="isCongressUser"><span id="powered">RMC Brothers</span></a>
      </p>
    </div>
  </div>
</div>