export class SavePermissionModel {
    userId: number;
    authorized: number;
    personalInfoAuthorized: number;

    constructor(userId: number, authorized: number, authorized2: number) {
        this.userId = userId;
        this.authorized = authorized;
        this.personalInfoAuthorized = authorized2;
    }
}
