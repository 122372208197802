export class User {
    id: number;
    name: string;
    userName: string;
    gender: string;
    emailAddress: string;
    miniResume: string;
    phone: string;
    profilePicture: string;
    associationTypeId: number;
    associationType: string;
    paidRegistration: boolean;
    ticketValid: boolean;
    posEventAccessValid: boolean;
    eventFinished: boolean;
    categoryId: number;
    category: string;
    isCompliant: boolean;
    prescriber: boolean;
    yearsOfParticipation: string;
    eventsOfRegistration: string;
    yearCongressReleased: string;
    favoriteActivities: string;
    viewsActivities: string;
    favoriteGuests: string;
    favoriteContacts: string;
    securityStamp: string;
    guid: string;
    usCookie: string;
    progress: number;
    allowEmailMarketing?: boolean;
    allowSharePersonalInfo?: boolean;
    accessToken: string;
}

export class UserCookie {
    guid: string;
    us_cookie: string;
    validateApcAccess: boolean;
    eventId: number;
    user: User;
}

export class ViewActivity {
    Id: number;
    ActivityId: number;
    Confirm: boolean;
    SgunId: number;
    Rate: number
    ViewPercentage: number;
}

export class UpdateProfilePictureInput {

    UserId: number;
    UserName: string;
    Buffer: any;
    Extension: string;
    Base64: string;
    Y: number;
    X: number;
    W: number;
    H: number;
}

export class IngressoModel{

}
