<div class="default-according style-1 faq-accordion job-accordion" id="accordioncity">
    <div class="row">
        <div class="col-xl-12">
            <div class="card">
                <div class="card-header">
                    <h5 class="mb-0">
                        <button class="btn btn-link pl-0" (click)="isCity = !isCity" [attr.aria-expanded]="!isCity"
                            aria-controls="collapse">Cidade</button>
                    </h5>
                </div>
                <div class="collapse" id="collapseicon2" [ngbCollapse]="isCity">
                    <div class="card-body animate-chk b-b-r-15">
                        <div class="faq-form">
                            <ng-select [items]="Cities" bindLabel="description" bindValue="description" placeholder="Selecione" [(ngModel)]="CityName" (ngModelChange)="onSelectedChange($event)"></ng-select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
