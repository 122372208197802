<div class="container-fluid p-0 m-0" >
    <div class="row p-0 m-0">
        <div class="col-sm-12 p-0 m-0">
            <div class="card text-center" *ngIf="groups && groups.length">
                <div class="card-body p-0">
                    <div *ngFor="let group of groups;let i = index;"
                        [ngClass]="i == groups.length - 1 ? 'card-footer' : 'card-header'">
                        <h5 class="p-b-15"
                            [ngClass]="group.category.split(' ').length > 1 ? 'break-line' : 'no-break-line'">
                            {{group.category}}</h5>
                        <div class="m-t-30 d-inline" *ngFor="let e of group.exhibitors">
                            <a [routerLink]="['/evento/expositores/visualizar', e.id]">
                                <img class="img-fluid img-exhibitor m-l-10 m-r-10"
                                    [class]="e.isMaster ? 'col-10 p-0 img-master' : 'img-sponsor'" [src]='e.emblemPath'
                                    alt="">
                                <!--2 = Organização; 3 = Master-->
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
