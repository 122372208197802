import { ExhibitorGroupModel } from './../../../shared/models/exhibitor/exhibitor.model';
import { EventService } from 'src/app/shared/services/event/event.service';
import { Component, Input, OnInit } from '@angular/core';

import { ExhibitorService } from 'src/app/shared/services/exhibitor/exhibitor.service';
import { Exhibitor } from 'src/app/shared/models/exhibitor/exhibitor.model';

@Component({
  selector: 'app-exhibitors-panel',
  templateUrl: './exhibitors-panel.component.html',
  styleUrls: ['./exhibitors-panel.component.scss']
})
export class ExhibitorsPanelComponent implements OnInit {

  @Input() showInHome: boolean;

  public exhibitors: Exhibitor[] = [];
  public groups: ExhibitorGroupModel[] = [];
  public currentEventId: number = 0;

  constructor(
    public eventService: EventService,
    public exhibitorService: ExhibitorService) {

  }

  ngOnInit(): void {
    this.eventService.getCurrentEvent().then((ev: any) => {
      this.currentEventId = ev ? ev.id : 0;
      this.getExhibitors();
    });
  }

  getExhibitors() {
    this.exhibitorService.getExhibitorsGroupedByTypeData(this.currentEventId).then((data: any) => {

      if (data) {

        this.groups = data as ExhibitorGroupModel[];
        this.groups = this.groups.sort((a, b) => (a.order < b.order ? -1 : 1));
        this.groups.forEach(g => {
          g.exhibitors = g.exhibitors.sort((a, b) => (a.order < b.order ? -1 : 1));
        });

      }
    });
  }
}


