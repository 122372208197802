import { UtilService } from 'src/app/shared/services/util.service';
import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { LayoutService } from '../../../services/layout.service';
import { NavService } from '../../../services/nav.service';
import { fadeInAnimation } from '../../../data/router-animation/router-animation';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import * as feather from 'feather-icons';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
  animations: [fadeInAnimation]
})
export class ContentComponent implements OnInit, AfterViewInit {

  public isEposterUser: boolean = false;
  public isScheduleUser: boolean = false;
  public isCongressUser: boolean = false;
  
  constructor(public navServices: NavService, 
    public utilService: UtilService,    
    public layout: LayoutService,
    private dbService: NgxIndexedDBService,    
    private authService: AuthService,
    private router: Router,
    @Inject(DOCUMENT) document: any) {
  }
    
  ngAfterViewInit() {
    setTimeout(() => {
      feather.replace();
    });
  }

  public getRouterOutletState(outlet) {
    return outlet.isActivated ? outlet.activatedRoute : '';
  }
  
  ngOnInit() {
    this.checkUserLogged()
  }

  checkUserLogged(): void {
    this.authService.getUserEmitter().subscribe((user) => {
      this.isEposterUser = this.authService.isEposterUser();
      this.isScheduleUser = this.authService.isScheduleUser();
      this.isCongressUser = this.authService.isCongressUser();
      this.adjustCongressLayout();
    });

    this.dbService.getAll('User').subscribe((users) => {
      this.isEposterUser = this.authService.isEposterUser();
      this.isScheduleUser = this.authService.isScheduleUser();
      this.isCongressUser = this.authService.isCongressUser();
      this.adjustCongressLayout();
    });
  }

  adjustCongressLayout() {
    
    if (this.isCongressUser) {      
      document.getElementById('md-app-widget').style.display = 'none';
    }
    else {
      document.getElementById('md-app-widget').style.display = 'block';        
    }

    if (this.isEposterUser) {
      this.router.navigate(['evento/e-poster']);
    }
    else if (this.isScheduleUser) {
      this.router.navigate(['evento/programacao']);
    }
  }
}