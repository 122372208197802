import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AddParticipantInput } from '../../models/event/network-meeting.model';

@Injectable({
  providedIn: 'root'
})
export class NetworkingService {

  private http: HttpClient;
  private baseUrl: string;

  constructor(@Inject(HttpClient) http: HttpClient) {
    this.http = http;
    this.baseUrl = environment.serverUrl;
  }

  getToken(id: number) {
    let url = this.baseUrl + "Networking/GetToken?id=" + id;

    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };

    return this.http.request("get", url, options);
  }

  getAllMeetings(id: number) {
    let url = this.baseUrl + "Networking/GetAllMeetings?eventId=" + id;

    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      })
    };

    return this.http.request("get", url, options);
  }

  getMeetingById(id: number) {
    let url = this.baseUrl + "Networking/GetMeetingById?id=" + id;

    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      })
    };

    return this.http.request("get", url, options);
  }

  addParticipant(input: AddParticipantInput): any{
    let url = this.baseUrl + "Networking/AddParticipant";

    let body = JSON.stringify(input);

    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      })
    };

    return this.http.post(url, body, options);
  }

  deleteParticipant(id: string) : any{
    let url = this.baseUrl + "Networking/DeleteParticipant?participationId=" + id;

    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      })
    };

    return this.http.request("get", url, options);
  }

  closeMeeting(meetingId: number){
    let url = this.baseUrl + "Networking/CloseMeeting?meetingId=" + meetingId;

    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      })
    };

    return this.http.request("get", url, options);
  }

}
