import { SignalR, SignalRConfiguration, SignalRModule } from 'ng2-signalr';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { AuthModule } from './auth/auth.module';
import { AppRoutingModule } from './app-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { NgxIndexedDBModule, DBConfig } from 'ngx-indexed-db';
import { JoyrideModule } from 'ngx-joyride';
import { config } from 'rxjs';
// for HttpClient import:
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
// for Router import:
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
// for Core import:
import { LoadingBarModule } from '@ngx-loading-bar/core';
//App services
import { DbService } from './shared/services/db.service';
import { EventService } from './shared/services/event/event.service';
import { UtilService } from './shared/services/util.service';
import { ExhibitorService } from './shared/services/exhibitor/exhibitor.service';
import { UserService } from './shared/services/user/user.service';
import { AuthService } from './shared/services/auth/auth.service';
import { AlertService } from './shared/services/alert.service';
import { ToastService } from './shared/services/toast.service';
import { NetworkConnectionService } from './shared/services/network-connection.service';
import { NetworkingService } from './shared/services/networking/networking.service';
import { EventHubService } from './shared/services/event-hub.service';
import { SearchService } from './shared/services/search/search.service';
//App components
import { AppComponent } from './app.component';
import { LoginModalComponent } from './auth/login/login-modal.component';
import { environment } from 'src/environments/environment';
import { ServiceWorkerModule } from '@angular/service-worker';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

/***
 *   Migration Factory:
     Fábrica para alterações da base de dados
     Inserir aqui alterações para o EventosDb
     Atualizar a versão da base de dados para que as alterações sejam aplicadas sem a necessidade de limpar o IndexedDb no navegador.
*/
export function migrationFactory() {
  return {
    2: (db: any, transaction: any) => {
      const store = transaction.objectStore('Event');
      store.createIndex('Progress', 'Progress', { unique: false });
    },
    3: (db: any, transaction: any) => {
      const store = transaction.objectStore('Notification');
      store.createIndex('SendDateTimeFormatted', 'SendDateTimeFormatted', {
        unique: false,
      });
    },
    4: (db: any, transaction: any) => {
      const store = transaction.objectStore('Activity');
      store.createIndex('RoomLinkChat', 'RoomLinkChat', { unique: false });
    },
  };
}

const dbConfig: DBConfig = {
  name: 'EventosDb',
  version: 9, //Versão do banco
  objectStoresMeta: [
    {
      store: 'User',
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        { name: 'name', keypath: 'name', options: { unique: false } },
        { name: 'userName', keypath: 'userName', options: { unique: false } },
        { name: 'gender', keypath: 'gender', options: { unique: false } },
        {
          name: 'emailAddress',
          keypath: 'emailAddress',
          options: { unique: false },
        },
        { name: 'phone', keypath: 'phone', options: { unique: false } },
        {
          name: 'profilePicture',
          keypath: 'profilePicture',
          options: { unique: false },
        },
        {
          name: 'associationTypeId',
          keypath: 'associationTypeId',
          options: { unique: false },
        },
        {
          name: 'associationType',
          keypath: 'associationType',
          options: { unique: false },
        },
        {
          name: 'paidRegistration',
          keypath: 'paidRegistration',
          options: { unique: false },
        },
        {
          name: 'categoryId',
          keypath: 'categoryId',
          options: { unique: false },
        },
        { name: 'category', keypath: 'category', options: { unique: false } },
        {
          name: 'isCompliant',
          keypath: 'isCompliant',
          options: { unique: false },
        },
        {
          name: 'prescriber',
          keypath: 'prescriber',
          options: { unique: false },
        },
        {
          name: 'yearsOfParticipation',
          keypath: 'yearsOfParticipation',
          options: { unique: false },
        },
        {
          name: 'eventsOfRegistration',
          keypath: 'eventsOfRegistration',
          options: { unique: false },
        },
        {
          name: 'yearCongressReleased',
          keypath: 'yearCongressReleased',
          options: { unique: false },
        },
        {
          name: 'securityStamp',
          keypath: 'securityStamp',
          options: { unique: false },
        },
        {
          name: 'favoriteActivities',
          keypath: 'favoriteActivities',
          options: { unique: false },
        },
        {
          name: 'favoriteGuests',
          keypath: 'favoriteGuests',
          options: { unique: false },
        },
        {
          name: 'favoriteContacts',
          keypath: 'favoriteContacts',
          options: { unique: false },
        },
        {
          name: 'viewsActivities',
          keypath: 'viewsActivities',
          options: { unique: false },
        },
        { name: 'guid', keypath: 'guid', options: { unique: false } },
        { name: 'usCookie', keypath: 'usCookie', options: { unique: false } },
        { name: 'progress', keypath: 'progress', options: { unique: false } },
        {
          name: 'miniResume',
          keypath: 'miniResume',
          options: { unique: false },
        },
        {
          name: 'accessToken',
          keypath: 'accessToken',
          options: { unique: false },
        },
      ],
    },
    {
      store: 'Event',
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        {
          name: 'description',
          keypath: 'description',
          options: { unique: false },
        },
        {
          name: 'shortDescription',
          keypath: 'shortDescription',
          options: { unique: false },
        },
        { name: 'logo', keypath: 'logo', options: { unique: false } },
        { name: 'logoBlack', keypath: 'logoBlack', options: { unique: false } },
        { name: 'logoIcon', keypath: 'logoIcon', options: { unique: false } },
        {
          name: 'defaultThumb',
          keypath: 'defaultThumb',
          options: { unique: false },
        },
        {
          name: 'backgroundCard',
          keypath: 'backgroundCard',
          options: { unique: false },
        },
        { name: 'about', keypath: 'about', options: { unique: false } },
        {
          name: 'primaryColor',
          keypath: 'primaryColor',
          options: { unique: false },
        },
        {
          name: 'secondaryColor',
          keypath: 'secondaryColor',
          options: { unique: false },
        },
        {
          name: 'hasCertificate',
          keypath: 'hasCertificate',
          options: { unique: false },
        },
        {
          name: 'numberOfRegistrations',
          keypath: 'numberOfRegistrations',
          options: { unique: false },
        },
        {
          name: 'numberOfViews',
          keypath: 'numberOfViews',
          options: { unique: false },
        },
        {
          name: 'numberOfActivities',
          keypath: 'numberOfActivities',
          options: { unique: false },
        },
        {
          name: 'numberOfGuests',
          keypath: 'numberOfGuests',
          options: { unique: false },
        },
        {
          name: 'eventSgunId',
          keypath: 'eventSgunId',
          options: { unique: false },
        },
        {
          name: 'lastRefreshDate',
          keypath: 'lastRefreshDate',
          options: { unique: false },
        },
        {
          name: 'authorizationTerm',
          keypath: 'authorizationTerm',
          options: { unique: false },
        },
      ],
    },
    {
      store: 'CoverageArea',
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        {
          name: 'description',
          keypath: 'description',
          options: { unique: false },
        },
        {
          name: 'activitiesCount',
          keypath: 'activitiesCount',
          options: { unique: false },
        },
        {
          name: 'lastRefreshDate',
          keypath: 'lastRefreshDate',
          options: { unique: false },
        },
      ],
    },
    {
      store: 'ActivityType',
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        {
          name: 'description',
          keypath: 'description',
          options: { unique: false },
        },
        {
          name: 'shortDescription',
          keypath: 'shortDescription',
          options: { unique: false },
        },
        { name: 'color', keypath: 'Color', options: { unique: false } },
        {
          name: 'activitiesCount',
          keypath: 'activitiesCount',
          options: { unique: false },
        },
        {
          name: 'lastRefreshDate',
          keypath: 'lastRefreshDate',
          options: { unique: false },
        },
      ],
    },
    {
      store: 'ThematicArea',
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        {
          name: 'description',
          keypath: 'description',
          options: { unique: false },
        },
        { name: 'eventId', keypath: 'eventId', options: { unique: false } },
        {
          name: 'lastRefreshDate',
          keypath: 'lastRefreshDate',
          options: { unique: false },
        },
      ],
    },
    {
      store: 'EventSchedule',
      storeConfig: {
        keyPath: 'id',
        autoIncrement: false,
        options: { unique: true },
      },
      storeSchema: [
        {
          name: 'description',
          keypath: 'description',
          options: { unique: false },
        },
        {
          name: 'shortDescription',
          keypath: 'shortDescription',
          options: { unique: false },
        },
        { name: 'about', keypath: 'About', options: { unique: false } },
        { name: 'eventId', keypath: 'EventId', options: { unique: false } },
        {
          name: 'lastRefreshDate',
          keypath: 'lastRefreshDate',
          options: { unique: false },
        },
        {
          name: 'authorizationTerm',
          keypath: 'authorizationTerm',
          options: { unique: false },
        },
      ],
    },
    {
      store: 'Day',
      storeConfig: {
        keyPath: 'id',
        autoIncrement: false,
        options: { unique: true },
      },
      storeSchema: [
        { name: 'day', keypath: 'day', options: { unique: false } },
        { name: 'dayOfWeek', keypath: 'dayOfWeek', options: { unique: false } },
        { name: 'color', keypath: 'color', options: { unique: false } },
        { name: 'beginHour', keypath: 'beginHour', options: { unique: false } },
        { name: 'endHour', keypath: 'endHour', options: { unique: false } },
        {
          name: 'hasActivities',
          keypath: 'hasActivities',
          options: { unique: false },
        },
        {
          name: 'eventScheduleId',
          keypath: 'eventScheduleId',
          options: { unique: false },
        },
        {
          name: 'lastRefreshDate',
          keypath: 'lastRefreshDate',
          options: { unique: false },
        },
      ],
    },
    {
      store: 'Activity',
      storeConfig: {
        keyPath: 'id',
        autoIncrement: false,
        options: { unique: true },
      },
      storeSchema: [
        {
          name: 'description',
          keypath: 'description',
          options: { unique: false },
        },
        { name: 'order', keypath: 'order', options: { unique: false } },
        { name: 'duration', keypath: 'duration', options: { unique: false } },
        { name: 'startTime', keypath: 'startTime', options: { unique: false } },
        { name: 'endTime', keypath: 'endTime', options: { unique: false } },
        { name: 'typeId', keypath: 'typeId', options: { unique: false } },
        { name: 'type', keypath: 'type', options: { unique: false } },
        {
          name: 'abbreviation',
          keypath: 'abbreviation',
          options: { unique: false },
        },
        {
          name: 'coverageAreaId',
          keypath: 'coverageAreaId',
          options: { unique: false },
        },
        {
          name: 'coverageAreaDescription',
          keypath: 'coverageAreaDescription',
          options: { unique: false },
        },
        {
          name: 'thematicAreaDescription',
          keypath: 'thematicAreaDescription',
          options: { unique: false },
        },
        {
          name: 'roomAbbreviation',
          keypath: 'roomAbbreviation',
          options: { unique: false },
        },
        {
          name: 'roomDescription',
          keypath: 'roomDescription',
          options: { unique: false },
        },
        {
          name: 'roomLinkVideo',
          keypath: 'roomLinkVideo',
          options: { unique: false },
        },
        { name: 'dayId', keypath: 'dayId', options: { unique: false } },
        { name: 'dayDate', keypath: 'dayDate', options: { unique: false } },
        { name: 'dayColor', keypath: 'dayColor', options: { unique: false } },
        {
          name: 'activityColor',
          keypath: 'activityColor',
          options: { unique: false },
        },
        { name: 'hasVideos', keypath: 'hasVideos', options: { unique: false } },
        { name: 'newVideos', keypath: 'newVideos', options: { unique: false } },
        {
          name: 'activitiesCount',
          keypath: 'activitiesCount',
          options: { unique: false },
        },
        {
          name: 'isSponsored',
          keypath: 'isSponsored',
          options: { unique: false },
        },
        {
          name: 'eventScheduleId',
          keypath: 'eventScheduleId',
          options: { unique: false },
        },
        {
          name: 'registrationEventId',
          keypath: 'registrationEventId',
          options: { unique: false },
        },
        {
          name: 'registrationEventName',
          keypath: 'registrationEventName',
          options: { unique: false },
        },
        {
          name: 'lastRefreshDate',
          keypath: 'lastRefreshDate',
          options: { unique: false },
        },
      ],
    },
    {
      store: 'Guest',
      storeConfig: {
        keyPath: 'id',
        autoIncrement: false,
        options: { unique: true },
      },
      storeSchema: [
        { name: 'sgunId', keypath: 'sgunId', options: { unique: false } },
        {
          name: 'userSgunId',
          keypath: 'userSgunId',
          options: { unique: false },
        },
        { name: 'name', keypath: 'name', options: { unique: false } },
        {
          name: 'profilePicturePath',
          keypath: 'profilePicturePath',
          options: { unique: false },
        },
        { name: 'email', keypath: 'email', options: { unique: false } },
        {
          name: 'institution',
          keypath: 'institution',
          options: { unique: false },
        },
        { name: 'city', keypath: 'city', options: { unique: false } },
        {
          name: 'countryState',
          keypath: 'countryState',
          options: { unique: false },
        },
        { name: 'category', keypath: 'category', options: { unique: false } },
        {
          name: 'currentCategory',
          keypath: 'currentCategory',
          options: { unique: false },
        },
        {
          name: 'speciality',
          keypath: 'speciality',
          options: { unique: false },
        },
        {
          name: 'miniResume',
          keypath: 'miniResume',
          options: { unique: false },
        },
        {
          name: 'socialMedias',
          keypath: 'socialMedias',
          options: { unique: false },
        },
        {
          name: 'numberOfVisits',
          keypath: 'numberOfVisits',
          options: { unique: false },
        },
        {
          name: 'coverageAreaId',
          keypath: 'coverageAreaId',
          options: { unique: false },
        },
        {
          name: 'coverageAreaDescription',
          keypath: 'coverageAreaDescription',
          options: { unique: false },
        },
        {
          name: 'eventScheduleId',
          keypath: 'eventScheduleId',
          options: { unique: false },
        },
        {
          name: 'lastRefreshDate',
          keypath: 'lastRefreshDate',
          options: { unique: false },
        },
      ],
    },
    {
      store: 'GuestActivity',
      storeConfig: {
        keyPath: 'id',
        autoIncrement: false,
        options: { unique: false },
      },
      storeSchema: [
        {
          name: 'description',
          keypath: 'description',
          options: { unique: false },
        },
        { name: 'topicId', keypath: 'topicId', options: { unique: false } },
        { name: 'guestId', keypath: 'guestId', options: { unique: false } },
        {
          name: 'guestSgunId',
          keypath: 'guestSgunId',
          options: { unique: false },
        },
        {
          name: 'abbreviation',
          keypath: 'abbreviation',
          options: { unique: false },
        },
        {
          name: 'roomAbbreviation',
          keypath: 'roomAbbreviation',
          options: { unique: false },
        },
        { name: 'dayDate', keypath: 'dayDate', options: { unique: false } },
        { name: 'order', keypath: 'order', options: { unique: false } },
        { name: 'duration', keypath: 'duration', options: { unique: false } },
        { name: 'startTime', keypath: 'startTime', options: { unique: false } },
        { name: 'endTime', keypath: 'endTime', options: { unique: false } },
        {
          name: 'thumbImage',
          keypath: 'thumbImage',
          options: { unique: false },
        },
        { name: 'urlVideo', keypath: 'urlVideo', options: { unique: false } },
        { name: 'eventId', keypath: 'eventId', options: { unique: false } },
        {
          name: 'lastRefreshDate',
          keypath: 'lastRefreshDate',
          options: { unique: false },
        },
      ],
    },
    {
      store: 'Topic',
      storeConfig: {
        keyPath: 'id',
        autoIncrement: false,
        options: { unique: true },
      },
      storeSchema: [
        { name: 'duration', keypath: 'duration', options: { unique: false } },
        { name: 'startTime', keypath: 'startTime', options: { unique: false } },
        { name: 'endTime', keypath: 'endTime', options: { unique: false } },
        {
          name: 'description',
          keypath: 'description',
          options: { unique: false },
        },
        { name: 'order', keypath: 'order', options: { unique: false } },
        { name: 'note', keypath: 'note', options: { unique: false } },
        {
          name: 'activityId',
          keypath: 'activityId',
          options: { unique: false },
        },
        { name: 'type', keypath: 'type', options: { unique: false } },
        {
          name: 'canHaveVideo',
          keypath: 'canHaveVideo',
          options: { unique: false },
        },
        { name: 'videoUrl', keypath: 'videoUrl', options: { unique: false } },
        { name: 'thumbUrl', keypath: 'thumbUrl', options: { unique: false } },
        { name: 'itsLive', keypath: 'itsLive', options: { unique: false } },
        {
          name: 'materialUrl',
          keypath: 'materialUrl',
          options: { unique: false },
        },
        { name: 'rate', keypath: 'rate', options: { unique: false } },
        { name: 'resume', keypath: 'resume', options: { unique: false } },
        {
          name: 'lastRefreshDate',
          keypath: 'lastRefreshDate',
          options: { unique: false },
        },
      ],
    },
    {
      store: 'Participation',
      storeConfig: {
        keyPath: 'id',
        autoIncrement: true,
        options: { unique: true },
      },
      storeSchema: [
        {
          name: 'roleDescription',
          keypath: 'roleDescription',
          options: { unique: false },
        },
        { name: 'topicId', keypath: 'topicId', options: { unique: false } },
        { name: 'guestId', keypath: 'guestId', options: { unique: false } },
        { name: 'guestName', keypath: 'guestName', options: { unique: false } },
        {
          name: 'guestPhoto',
          keypath: 'guestPhoto',
          options: { unique: false },
        },
        {
          name: 'lastRefreshDate',
          keypath: 'lastRefreshDate',
          options: { unique: false },
        },
      ],
    },
    {
      store: 'Exhibitor',
      storeConfig: {
        keyPath: 'id',
        autoIncrement: false,
        options: { unique: true },
      },
      storeSchema: [
        { name: 'cnpj', keypath: 'cnpj', options: { unique: false } },
        { name: 'name', keypath: 'name', options: { unique: false } },
        {
          name: 'description',
          keypath: 'description',
          options: { unique: false },
        },
        { name: 'about', keypath: 'about', options: { unique: false } },
        { name: 'logoPath', keypath: 'logoPath', options: { unique: false } },
        {
          name: 'logoListPath',
          keypath: 'logoListPath',
          options: { unique: false },
        },
        {
          name: 'emblemPath',
          keypath: 'emblemPath',
          options: { unique: false },
        },
        { name: 'type', keypath: 'type', options: { unique: false } },
        { name: 'category', keypath: 'category', options: { unique: false } },
        {
          name: 'categoryId',
          keypath: 'categoryId',
          options: { unique: false },
        },
        {
          name: 'categoryOrder',
          keypath: 'categoryOrder',
          options: { unique: false },
        },
        { name: 'isMaster', keypath: 'isMaster', options: { unique: false } },
        {
          name: 'prescriber',
          keypath: 'prescriber',
          options: { unique: false },
        },
        { name: 'order', keypath: 'order', options: { unique: false } },
        {
          name: 'eventScheduleId',
          keypath: 'eventScheduleId',
          options: { unique: false },
        },
        {
          name: 'websiteLink',
          keypath: 'websiteLink',
          options: { unique: false },
        },
        {
          name: 'surveyLink',
          keypath: 'surveyLink',
          options: { unique: false },
        },
        {
          name: 'surveyPrescriber',
          keypath: 'surveyPrescriber',
          options: { unique: false },
        },
        {
          name: 'imageSurvey',
          keypath: 'imageSurvey',
          options: { unique: false },
        },
        { name: 'drawLink', keypath: 'drawLink', options: { unique: false } },
        { name: 'imageDraw', keypath: 'imageDraw', options: { unique: false } },
        {
          name: 'lastRefreshDate',
          keypath: 'lastRefreshDate',
          options: { unique: false },
        },
      ],
    },
    {
      store: 'CoverageAreaEPoster',
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        {
          name: 'description',
          keypath: 'description',
          options: { unique: false },
        },
        {
          name: 'worksCount',
          keypath: 'worksCount',
          options: { unique: false },
        },
        {
          name: 'lastRefreshDate',
          keypath: 'lastRefreshDate',
          options: { unique: false },
        },
      ],
    },
    {
      store: 'WorkEPoster',
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        { name: 'title', keypath: 'title', options: { unique: false } },
        {
          name: 'abbreviation',
          keypath: 'abbreviation',
          options: { unique: false },
        },
        {
          name: 'coverageAreaId',
          keypath: 'coverageAreaId',
          options: { unique: false },
        },
        {
          name: 'coverageAreaDescription',
          keypath: 'coverageAreaDescription',
          options: { unique: false },
        },
        {
          name: 'bestWorkAbbreviation',
          keypath: 'bestWorkAbbreviation',
          options: { unique: false },
        },
        { name: 'pathFile', keypath: 'pathFile', options: { unique: false } },
        { name: 'author', keypath: 'author', options: { unique: false } },
        {
          name: 'lastRefreshDate',
          keypath: 'lastRefreshDate',
          options: { unique: false },
        },
      ],
    },
    {
      store: 'Question',
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        {
          name: 'description',
          keypath: 'description',
          options: { unique: false },
        },
        { name: 'answer', keypath: 'answer', options: { unique: false } },
        { name: 'order', keypath: 'order', options: { unique: false } },
        {
          name: 'lastRefreshDate',
          keypath: 'lastRefreshDate',
          options: { unique: false },
        },
      ],
    },
    {
      store: 'Notification',
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        {
          name: 'sendDateTime',
          keypath: 'dateTime',
          options: { unique: false },
        },
        { name: 'title', keypath: 'title', options: { unique: false } },
        { name: 'message', keypath: 'message', options: { unique: false } },
        { name: 'type', keypath: 'type', options: { unique: false } },
        { name: 'isRead', keypath: 'isRead', options: { unique: false } },
        { name: 'eventId', keypath: 'eventId', options: { unique: false } },
        { name: 'onlyGuests', keypath: 'eventId', options: { unique: false } },
      ],
    },
    {
      store: 'Filter',
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        { name: 'lesson', keypath: 'lesson', options: { unique: false } },
        { name: 'schedule', keypath: 'schedule', options: { unique: false } },
        {
          name: 'eventScheduleId',
          keypath: 'eventScheduleId',
          options: { unique: false },
        },
      ],
    },
  ],

  migrationFactory,
};

export function createConfig(): SignalRConfiguration {
  const c = new SignalRConfiguration();
  c.hubName = 'ChatHub';
  //c.qs = { user: 'alon' };
  c.url = environment.cientificoUrl + '/signalr';
  c.logging = true;
  return c;
}

@NgModule({
  declarations: [AppComponent, LoginModalComponent],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    SharedModule,
    AuthModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    NgxIndexedDBModule.forRoot(dbConfig),
    SignalRModule.forRoot(createConfig),
    ToastrModule.forRoot(),
    JoyrideModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    // for HttpClient use:
    LoadingBarHttpClientModule,
    // for Router use:
    LoadingBarRouterModule,
    // for Core use:
    LoadingBarModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerImmediately',
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      //registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  exports: [LoginModalComponent],
  providers: [
    DbService,
    AuthService,
    UserService,
    EventService,
    ExhibitorService,
    UtilService,
    AlertService,
    ToastService,
    NetworkConnectionService,
    NetworkingService,
    EventHubService,
    SearchService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
