<div class="default-according style-1 faq-accordion job-accordion" id="accordionoc">
    <div class="row">
        <div class="col-xl-12">
            <div class="card">
                <div class="card-header">
                    <h5 class="mb-0">
                        <button class="btn btn-link pl-0 d-inline" (click)="isFilter = !isFilter"
                            [attr.aria-expanded]="!isFilter" aria-controls="collapse">{{ IsSchedule ? 'Busca Geral por Dia' : 'Busca Geral' }}
                        </button>
                        <button href="javascript:void(0)" class="btn btn-link clear pl-0 d-inline font-primary"
                            (click)="clear()" *ngIf="ShowClearFilters && IsCongressUser">Limpar Filtros
                        </button>
                    </h5>
                </div>
                <div class="collapse" id="collapseicon" [ngbCollapse]="isFilter">
                    <div class="card-body filter-cards-view animate-chk b-b-r-15">
                        <div class="job-filter m-b-0">
                            <div class="faq-form">
                                <input class="form-control busca" type="text" placeholder="Buscar"
                                    [(ngModel)]="searchword" (input)="searchByTerm()">
                                <app-feather-icons class="search-icon" [icon]="'search'"></app-feather-icons>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>