import { environment } from './../../../environments/environment.tests';
import { Injectable } from '@angular/core';
import { ElapsedTimeModel } from './../models/shared/elapsed-time.model';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor() { }

  isNullOrEmpty(str: string) {
    return str == null || str == '' || str == undefined;
  }

  isUndefined(obj: any) {
    return obj == undefined;
  }

  any(obj: any) {
    if (!this.isUndefined(obj))
      return obj.length > 0;

    return null;
  }

  checkTimeToRefresh(lastRefreshDate: string, refreshMinutes?: number): boolean {
    if (!lastRefreshDate)
      return true;

    let diff = (+(new Date()) - +(new Date(lastRefreshDate)));
    let minutes = Math.floor(diff / 6e4);

    return minutes >= (refreshMinutes ? refreshMinutes : environment.refreshMinutes);
  }

  getElapsedTime(startDate: string): ElapsedTimeModel {

    startDate = startDate.substr(0, 10) + "T" + startDate.substr(11, 8) + ":10"
    let date = new Date(startDate.replace(/-/g, '/').replace('T', ' ')).toISOString();
    let newDate = new Date().toISOString();

    var delta = Math.abs(+(new Date(newDate)) - +(new Date(date))) / 1000;

    // calculate days
    var days = Math.floor(delta / 86400);
    delta -= days * 86400;

    // calculate hours
    var hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;

    // calculate minutes
    var minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;

    //calculate seconds
    var seconds = delta % 60;  // in theory the modulus is not required

    let result: ElapsedTimeModel = { days: days, hours: hours, minutes: minutes, seconds: seconds }

    return result;
  }

  getElapsedTimeText(date: string): string {

    let time = this.getElapsedTime(date);
    if (time.days > 1)
      return time.days + " dia(s)";
    if (time.hours > 1)
      return time.hours + " horas(s)";
    if (time.minutes <= 5)
      return "Agora";
    if (time.minutes > 5)
      return time.minutes + " minuto(s)";

  }


  prepareSearch(string) {
    return string.toLowerCase().trim()
      .replace(/(á|à|ã|â|ä)/g, 'a')
      .replace(/(é|è|ê|ë)/g, 'e')
      .replace(/(é|è|î|ï)/g, 'i')
      .replace(/(ó|ò|ô|õ|ö)/g, 'o')
      .replace(/(ú|ù|û|ü)/g, 'u')
      .replace(/(!|\?|%|'|"|:)/g, '');
  }

  prepareSearchFirstAndLastWord(string) {

    let text = this.prepareSearch(string).split(/(\s).+\s/).join("");
    let textSplit = text.split(' ');

    return textSplit.length >= 2 ? textSplit[0] + ' ' + textSplit[1] : text;
  }

  capitalizeTerm(string) {
    return this.prepareSearch(string).replace(/([^a-z]|^)([a-z])(?=[a-z]{0})/g, x => x.toUpperCase())
  }

  formatHour(hour: string): string {
    if (hour) {
      hour = hour.substring(0, 1) === '0' ? hour.substring(1) : hour;
      return hour.replace(':', 'h');
    }

    return "";
  }

  formatHourAndMinutes(hour: string): string {
    if (hour) {
      hour = hour.substring(0, 1) === '0' ? hour.substring(1) : hour;

      let minutos = hour.split(':')[1];
      let plural = Number(hour.split(':')[0]) > 1 ? "s" : "";

      let hora = hour.substring(0, 1) === '0' ? minutos + 'm' :
        minutos && minutos != "00" ? hour.replace(':', 'h' + plural) + 'm' : hour.split(':')[0] + 'h' + plural;
      return hora;
    }

    return "";
  }

  formatHourInFull(hour: string): string {

    if (hour) {
      hour = hour.substring(0, 1) === '0' ? hour.substring(1) : hour;

      let minutos = hour.split(':')[1];
      let plural = Number(hour.split(':')[0]) > 1 ? "s" : "";

      let hora = hour.substring(0, 1) === '0' ? minutos + ' m' :
        minutos && minutos != "00" ? hour.replace(':', ' hora' + plural + ' e ') + ' m' : hour.split(':')[0] + ' hora' + plural;
      return hora;
    }

    return "";
  }

  toDateISO(dateStr) {
    return dateStr.split('/').reverse().join('-');
  }

  hexToRgbA(hex, alpha) {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split('');
      if (c.length == 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = '0x' + c.join('');
      return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') +
        ',' + alpha + ')';
    }
    throw new Error('Bad Hex');
  }

  getFistAndLastWords(words: string): string {
    return words.split(/(\s).+\s/).join("");
  }

  isMobile(): boolean {
    return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
  }

  getFirstAndLastName(name: string): string {

    name = name.split(/(\s).+\s/).join("");
    let namesplit = name.split(' ');

    name = name.length >= 2 ? namesplit[0] + ' ' + namesplit[1] : name;

    return name;
  }

  getNameAndInitials(fullName: string) {

    fullName = fullName.replace(/\s(de|da|dos|das|De|Da|Dos|Das|DE|DA|DOS|DAS|)\s/g, ' '); // Remove os de,da, dos,das.
    var names = fullName.split(' ');
    var initials = names.map((n) => n[0]); // Iniciais de cada parte do nome.


    var name = names[0].toLowerCase(); // Primeiro nome.
    var lastName = names[names.length - 1].toLowerCase();
    if (names.length > 2) {
      var lastNames = initials.splice(1, initials.length - 2).join('. ').toLowerCase() + '.'; // Iniciais
      return (name + ' ' + lastNames + ' ' + lastName);
    }
    return name + ' ' + lastName
  }

  capitalizeWord(word: string) {
    if (!word) return word;
    return word[0].toUpperCase() + word.substr(1).toLowerCase();
  }

  getPaginationSize() {
    let notsize: any;
    return this.isMobile() ? 'sm' : notsize
  }

}
