import { Injectable } from '@angular/core';
declare var gtag;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsEventsService {
  
  constructor() { }

  /**
   * Emit google analytics event
   * Fire event example:
   * this.emitEvent("testAction", "testLabel", "testCategory");
   * @param {string} eventAction
   * @param {string} eventLabel
   * @param {string} eventCategory      
   */
  public emitEvent(
    eventAction: string,
    eventLabel: string,
    eventCategory: string,) {    
      gtag('event', eventAction, {
        'event_label': eventLabel,
        'event_category': eventCategory
      });
  }
}
