import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-term-filter',
  templateUrl: './term-filter.component.html',
  styleUrls: ['./term-filter.component.scss']
})
export class TermFilterComponent implements OnInit {

  @Input() Term: string;
  @Input() IsSchedule = false;
  @Input() IsCongressUser = false;
  @Input() ShowClearFilters = false;
  @Output() searchByWord = new EventEmitter<string>();
  @Output() clearFilters = new EventEmitter();

  public isFilter: boolean = false;
  public searchword: string = '';

  constructor() { 
  }

  ngOnInit(): void {
    this.searchword = this.Term;
  }

  searchByTerm() {
    this.searchByWord.emit(this.searchword)
  }

  clear() {
    this.searchword = undefined;
    this.clearFilters.emit();
  }
}
