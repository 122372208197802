import { Router } from '@angular/router';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
declare var JitsiMeetExternalAPI: any;

@Component({
  selector: 'app-jitsi-meeting',
  templateUrl: './jitsi-meeting.component.html',
  styleUrls: ['./jitsi-meeting.component.scss']
})
export class JitsiMeetingComponent implements OnInit, AfterViewInit {

  @Input() Domain: string;
  @Input() AppId: string;
  @Input() Room: string;
  @Input() JWT: string;
  @Input() UserName: string;
  @Input() UrlRedirectWhenLeftOrClose: string;
  @Input() Width: string;
  @Input() Height: string;
  @Input() PrejoinPageEnabled: boolean = true;

  @Output() participantLeft = new EventEmitter<string>();
  @Output() participantJoin = new EventEmitter<string>();
  @Output() close = new EventEmitter<number>();


  options: any;
  api: any;


  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.api.executeCommand('hangup');
  }

  ngAfterViewInit(): void {
    this.options = {
      roomName: this.AppId + '/' + this.Room,
      jwt: this.JWT,
      width: this.Width ? this.Width : '100%',
      height: this.Height ? this.Height : '600px',
      configOverwrite: {
        // Sobrescrever as configs se necessário
        prejoinPageEnabled: this.PrejoinPageEnabled,
      },
      interfaceConfigOverwrite: {
        // Sobrescrever as propriedades se necessário
        LANG_DETECTION: true,
        TOOLBAR_BUTTONS: ['hangup', 'microphone', 'camera', 'fullscreen', 'videoquality', 'chat',
          'fodeviceselection', 'profile', 'raisehand', 'filmstrip', 'tileview', 'select-background'
          //,'settings','download', 'help', 'mute-everyone', 'mute-video-everyone', 'security','recording', 'closedcaptions', 'desktop', 'embedmeeting', 'livestreaming', 'etherpad', 'sharedvideo', 'invite', 'feedback', 'stats', 'shortcuts',
        ]
      },
      parentNode: document.querySelector('#meeting-container'),
      userInfo: {
        displayName: this.UserName
      }
    }

    this.api = new JitsiMeetExternalAPI(this.Domain, this.options);

    this.api.addEventListeners({
      readyToClose: this.handleClose,
      // participantJoined: this.handleParticipantJoined, //Obs: Executa ação para todos os participantes, caso um novo participante entre
      videoConferenceJoined: this.handleVideoConferenceJoined,
      // participantLeft: this.handleParticipantLeft, //Obs: Executa ação para todos os participantes, caso um participante saia.
      videoConferenceLeft: this.handleVideoConferenceLeft,
    });

  }

  handleParticipantLeft = async (participant: any) => {
    //const data = await this.getParticipants();
  }

  handleParticipantJoined = async (participant: any) => {
    //const data = await this.getParticipants();
  }

  handleVideoConferenceJoined = async (participant: any) => {

    this.participantJoin.emit(participant.id);
  }

  handleVideoConferenceLeft = () => {
    this.participantLeft.emit(null);
    this.leftRedirect();
  }

  handleClose = () => {
    this.leftRedirect();
  }

  // Get all participants
  getParticipants() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(this.api.getParticipantsInfo());
      }, 500)
    });
  }

  leftRedirect() {
    if (this.UrlRedirectWhenLeftOrClose)
      this.router.navigate([this.UrlRedirectWhenLeftOrClose]);
  }

}
