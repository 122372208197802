<ngx-loading-bar [color]="loaderColor" [includeSpinner]="false" [height]="'4px'"></ngx-loading-bar>
<app-loader></app-loader>
<app-tap-to-top></app-tap-to-top>
<!--Content para apresentar movidesk no Tout Inicial-->
<div class="movidesk-content" joyrideStep="7" stepPosition="left" title="Fale com a SOGESP" (done)="onDone()"
    [stepContent]="movi"></div>
<ng-template #movi>
    <div style="max-width: 400px; width: 310px;">Aqui você entra em contato com a equipe da SOGESP para tirar dúvidas.
    </div>
</ng-template>
<!--end Content-->
<router-outlet></router-outlet>
<login #login></login>