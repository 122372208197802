import { environment } from "src/environments/environment";

export abstract class StorageRefresh {
     lastRefreshDate: string = new Date().toISOString();

     constructor() {
          this.lastRefreshDate = new Date().toISOString();
     };

     public setLastRefreshDate() {
          this.lastRefreshDate = new Date().toISOString();
     }

     public checkTimeToRefresh(refreshMinutes?: number): boolean {
          if (!this.lastRefreshDate)
            return true;

          let diff = (+(new Date()) - +(new Date(this.lastRefreshDate)));
          let minutes = Math.floor(diff / 6e4);

          return minutes >= (refreshMinutes ? refreshMinutes : environment.refreshMinutes);
        }

}
