import { FilterBase } from "./filter-base";

export class Filters {
    id: number;
    lesson: LessonFilter;
    schedule: ScheduleFilter;
    eventScheduleId: number;
    constructor(eventScheduleId: number) {
        this.id = 1;
        this.eventScheduleId = eventScheduleId;
    }
}

export class LessonFilter extends FilterBase {
    dates: string[];
    rooms: string[];
    coverageAreas: number[];
    thematicArea: string;
    types: number[];
    listMode: boolean;
    favorites: boolean;

    constructor() {
        super();
        this.dates = [];
        this.coverageAreas = [];
        this.types = [];
    }
}

export class ScheduleFilter extends FilterBase {
    coverageAreas: number[];
    thematicArea: string;
    types: number[];
    day: string;
    rooms: string[];
    activityId: number;

    constructor() {
        super();
        this.coverageAreas = [];
        this.types = [];
    }
}
