import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgSelectConfig } from '@ng-select/ng-select';
import { SelectItem } from 'src/app/shared/models/shared/selectItem.model';

@Component({
  selector: 'app-country-state-city-filter',
  templateUrl: './country-state-city-filter.component.html',
  styleUrls: ['./country-state-city-filter.component.scss']
})
export class CountryStateCityFilterComponent implements OnInit {

  @Input() Cities: SelectItem[] = [];
  @Input() CountryStates: SelectItem[] = [];
  @Output() searchByCountryState = new EventEmitter<string>();
  @Output() searchByCity = new EventEmitter<string>();

  public citiesCountryState: SelectItem[] = [];
  public isCountryState: boolean = false;
  public countryStateName: string;
  public isCity: boolean = false;
  public cityName: string

  constructor(private config: NgSelectConfig) {
    this.config.notFoundText = 'Nenhum item encontrado';
  }

  ngOnInit(): void {
  }

  onSelectedChangeCountryState(value: string) {
    this.countryStateName = value;
    this.filterByCountryState(this.countryStateName)
  }

  filterByCountryState(name: string) {

    this.cityName = undefined;
    this.citiesCountryState = [];

    if (this.countryStateName && this.countryStateName.length)
      this.citiesCountryState = this.Cities.filter(x =>
        x.category.toLocaleLowerCase().includes(this.countryStateName.toLocaleLowerCase()));

    this.searchByCountryState.emit(name);
  }

  onSelectedChangeCity(value: string) {
    this.cityName = value;
    this.filterByCity(this.cityName)
  }

  filterByCity(name: string) {
    this.searchByCity.emit(name);
  }

}
