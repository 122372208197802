import { Component, Input, OnInit } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';

import { BannerItem } from 'src/app/shared/models/event/banner-item-model';

@Component({
  selector: 'app-ad-banner',
  templateUrl: './ad-banner.component.html',
  styleUrls: ['./ad-banner.component.scss'],
  providers: [NgbCarouselConfig]
})
export class AdBannerComponent implements OnInit {

  @Input() Banners: BannerItem[] = [];
  @Input() BannerId: number;

  public isDragging: boolean;

  owlcarousel2Options = {

    margin: 0,
    items: 1,
    dots: true,
    nav: false,
    autoplay: true,
    loop: false,
    rewind: true

  }

  constructor() { }

  ngOnInit(): void {
  }

}
