import { isPlatformBrowser } from '@angular/common';
import { Component, EventEmitter, Inject, OnInit, Output, PLATFORM_ID, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-notification-modal',
  templateUrl: './notification-modal.component.html',
  styleUrls: ['./notification-modal.component.scss']
})
export class NotificationModalComponent implements OnInit {

  @ViewChild("modalNotification", { static: false }) modalNotification: TemplateRef<any>;

  @Output() markAsRead = new EventEmitter<{ id: number, isRead: number }>();


  public show: boolean = false;
  public modalOpen: boolean = false;
  public title: string;
  public description: string;

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  openModal(id: number, title: string, description: string) {

    this.title = title;
    this.description = description;

    if (isPlatformBrowser(this.platformId)) { // For SSR
      this.markAsRead.emit({ id: id, isRead: 1 });
      this.modalService.open(this.modalNotification, {
        size: 'md',
        ariaLabelledBy: 'modal-bookmark',
        centered: true,
        windowClass: 'modal-bookmark',
      }).result.then((result) => {
        if (result){
          this.modalOpen = true;
        }

      }, (reason) => {
        this.markAsRead.emit({ id: id, isRead: 1 });
      });
    }
  }

  mark(id: number, isRead: number) {

    this.markAsRead.emit({ id: id, isRead: isRead });
  }

  ngOnDestroy() {
    if (this.modalService.hasOpenModals()) {
      this.modalService.dismissAll();
    }
  }
}
