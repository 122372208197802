<div [ngClass]="unreadNotifications.length ? 'notification-box' : 'notification-box-not-animated'"
  (click)="toggleNotificationMobile()">
  <app-feather-icons [icon]="'bell'"></app-feather-icons>
  <span *ngIf="unreadNotifications.length"
    class="badge badge-pill badge-secondary">{{unreadNotifications.length}}</span>
</div>
<ul class="notification-dropdown onhover-show-div" [class.active]="openNotification" *ngIf="notifications.length">
  <li>
    <app-feather-icons [icon]="'bell'"></app-feather-icons>
    <h6 class="f-18 mb-0">Notificações</h6>
  </li>
  <li *ngFor="let notification of notifications">
    <p (click)="openNotificationModal(notification)">
      <i class="fa fa-circle-o mr-3"
        [ngClass]="notification.isRead == 1 ? 'font-light' :  'font-primary'"></i>{{notification.title.length >= 13 ? notification.title.slice(0, 13) + '...' : notification.title }}<span
        class="pull-right">{{ util.getElapsedTimeText(notification.sendDateTime) }}</span>
    </p>
  </li>
  <li>
    <a class="btn btn-primary m-t-10 m-b-5" (click)="openNotifications()">Ver Todas</a>
  </li>
</ul>

<app-notification-modal #notificationModal (markAsRead)="markNotificationAsRead($event)"></app-notification-modal>
