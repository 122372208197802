import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// Services
import { NavService } from 'src/app/shared/services/nav.service';
import { SearchService } from 'src/app/shared/services/search/search.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  public searchResultEmpty: boolean = false;
  public text: string;
  public currentEventId: number = 0;
  public searchPageResult: boolean = false;

  constructor(
    private router: Router,
    private searchService: SearchService,
    public navServices: NavService
  ) {
  }

  ngOnInit(): void {
    this.searchToggle();

    this.searchService.getSearchPageResultEmitter().subscribe(() => {
      this.searchPageResult = true;
    });

    this.searchService.getSearchToggleEmitter().subscribe((open) => {
      if (!open)
        this.clearSearch();
    });
  }

  ngOnDestroy() {
    this.searchToggle();
  }

  searchToggle() {
    this.clearSearch();
    this.searchService.closeSerch();
  }

  clearSearch(): void {
    this.text = '';
    this.searchPageResult = false;
    this.searchService.saveSearch('', 1);
  }

  backToSearchGeneral(): void {
    this.searchTerm();
  }

  searchTerm() {

    let term = this.text;

    this.searchPageResult = false;
    this.searchService.searchTerm(term);

    if (!this.isGeneralSearchPage() && term)
      this.router.navigate(['evento/buscar']);
  }

  isGeneralSearchPage(): boolean {
    return location.pathname.split('evento/buscar').length >= 2
  }

  getPlaceholder(): string {
    return window.innerWidth < 480 ? 'Buscar...' : 'O que deseja buscar...';
  }

  onDone() {
    localStorage.setItem('viewedTour', 'true');
  }

}
