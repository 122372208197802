<!-- page-wrapper Start-->
<div [ngClass]="layout.config.settings.layout_type" 
     [class]="layout.config.settings.layout_version" 
     [class.body-focus]="isCongressUser" >
  <div class="page-wrapper" 
       id="canvas-bookmark"
       [ngClass]="layout.config.settings.sidebar.type">
    <app-header style="overflow: auto;" *ngIf="!isCongressUser"></app-header>
    <!-- Page Body Start-->
    <div class="page-body-wrapper"
         [ngClass]="layout.config.settings.sidebar.body_type">
      <div class="sidebar-wrapper" *ngIf="!isCongressUser"
           [attr.sidebar-layout]="layout.config.settings.sidebar_type"
           [class.close_icon]="utilService.isMobile() ? navServices.collapseSidebar : true">
           <!--Adicionar ao sidebar-wrapper para ativar comportamento original-->
           <!--[class.close_icon]="navServices.collapseSidebar"-->
        <app-sidebar></app-sidebar>
      </div>
      <div class="page-body">
        <main [@fadeInAnimation]="getRouterOutletState(o)">
          <router-outlet #o="outlet"></router-outlet>
        </main>
      </div>
      <!-- footer start-->
      <footer class="footer">
        <app-footer></app-footer>
      </footer>
      <!-- footer End-->
    </div>
    <!-- Page Body End-->
  </div>
</div>
<!-- page-wrapper End-->