<div class="card earning-card" [class.m-t-b-15]="DivideCardMargin">
    <div *ngIf="IsFavorite && ShowFavorite" style="cursor: pointer;" placement="left" ngbTooltip="Remover dos Favoritos"
        tooltipClass="custom-tooltip" class="ribbon ribbon-bookmark ribbon-vertical-right ribbon-secondary m-r-10"
        (click)="addFavorites(Topic.id, false)">
        <i class="icofont icofont-ui-love"></i>
    </div>
    <div *ngIf="!IsFavorite && ShowFavorite" style="cursor: pointer;" placement="left" ngbTooltip="Favoritar"
        tooltipClass="custom-tooltip" class="ribbon ribbon-bookmark ribbon-vertical-right ribbon-light m-r-10"
        (click)="addFavorites(Topic.id, true)">
        <i class="icofont icofont-ui-love"></i>
    </div>
    <div class="card-header card-no-border p-b-5" *ngIf="!ShowThumbOnTop">
        <h6 class="f-14 f-w-300 m-b-0" *ngIf="Topic.activity">
            <i class="icon-calendar"></i><span class="m-l-5">{{Topic.activity.dayDate}}
            </span><span class="m-r-5" *ngIf="Topic.startTime"> às {{Topic.startTime}}</span><span
                class="duration-time m-r-10"><i class="icon-clock"></i><span class="m-l-5 m-r-10">{{
                    Duration }}</span>
                <i class="icon-check font-primary" *ngIf="HasView"></i><span class="m-l-5 m-r-10 font-primary" *ngIf="HasView">aula assistida</span>
            </span>
            <span class="ml-0 rate-content" *ngIf="false"> <!--*ngIf="ShowRate"-->
                <ngb-rating [(rate)]="rateLesson" class="rating-size font-warning" [max]="5" [readonly]="ReadOnlyRate"
                    (rateChange)="rate(Topic.id, Topic.rate)">
                    <ng-template let-fill="fill" let-index="index">
                        <span class="star" [class.full]="fill === 100">
                            <span class="half" [style.width.%]="fill"><span
                                    class="fa fa-star f-16 m-l-5 font-warning"></span></span><span class="fa fa-star-o f-16 m-l-5 font-warning"></span>
                        </span>
                    </ng-template>
                </ngb-rating>
            </span>
        </h6>
        <h6 *ngIf="Topic.activity" style="min-height: 52px;">
            <span class="badge badge-dark m-t-5">{{
                Topic.activity.coverageAreaDescription }}</span>
            <span class="badge badge-primary m-t-5">{{
                Topic.activity.abbreviation }} {{ Topic.activity.order }} </span>
            <span class="badge badge-secondary m-t-5">{{
                Topic.activity.thematicAreaDescription }}</span>
        </h6>
    </div>
    <div class="card-body p-t-0" [class.p-b-20]="ShowThumbOnTop">
        <div class="row p-0">
            <div class="col-sm-12">
                <div class="card features-faq product-box m-0">
                    <!-- <div placement="bottom" [ngbTooltip]="getDescription(Topic)" tooltipClass="custom-tooltip"> -->
                    <p class="f-w-600 f-16 m-t-0 m-b-15 ellipsis-2 title" style="cursor: pointer !important; min-height: 52px;"
                        (click)="openVideo(Topic.id)">{{ getDescription(Topic) }}</p>
                    <!-- </div> -->
                    <div class="faq-image product-img"><img class="img-fluid activity-thumb"
                            [src]="Topic.thumbUrl ? Topic.thumbUrl : 'assets/images/thumb_video_geral.png'"
                            alt="">
                        <div class="product-hover" (click)="openVideo(Topic.id)">
                            <ul>
                                <li><i class="ion ion-play"></i></li>
                            </ul>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card-footer p-t-0 p-b-0" *ngIf="ShowThumbOnTop">
        <h6 class="f-14 f-w-300 m-b-0" *ngIf="Topic.activity">
            <i class="icon-calendar"></i><span class="m-l-5">{{Topic.activity.dayDate}}
            </span><span class="m-r-5" *ngIf="Topic.startTime"> às {{Topic.startTime}}</span><span
                class="duration-time m-r-10"><i class="icon-clock"></i><span class="m-l-5 m-r-10">{{
                    Duration }}</span></span>
            <i class="icon-check font-primary" *ngIf="HasView"></i><span class="m-l-5 font-primary" *ngIf="HasView">aula assistida</span>
            <span class="ml-0 rate-content" *ngIf="false"><!--*ngIf="ShowRate"-->
                <ngb-rating [(rate)]="rateLesson" class="rating-size font-warning" [max]="5" [readonly]="ReadOnlyRate"
                    (rateChange)="rate(Topic.id, Topic.rate)">
                    <ng-template let-fill="fill" let-index="index">
                        <span class="star" [class.full]="fill === 100">
                            <span class="half" [style.width.%]="fill"><span
                                    class="fa fa-star f-16 m-l-5 font-warning"></span></span><span
                                class="fa fa-star-o f-16 m-l-5 font-warning"></span>
                        </span>
                    </ng-template>
                </ngb-rating>
            </span>
        </h6>
        <h6 *ngIf="Topic.activity" style="min-height: 58px;">
            <span class="badge badge-dark m-t-5">{{
                Topic.activity.coverageAreaDescription }}</span>
            <span class="badge badge-primary m-t-5">{{
                Topic.activity.abbreviation }} {{ Topic.activity.order }} </span>
            <span class="badge badge-secondary m-t-5">{{
                Topic.activity.thematicAreaDescription }}</span>
        </h6>
        <!-- <div placement="bottom" [ngbTooltip]="getDescription(Topic)" tooltipClass="custom-tooltip"> -->
        <p class="f-w-600 f-16 m-t-15 m-b-15 ellipsis-2 title" (click)="openVideo(Topic.id)" style="cursor: pointer !important; min-height: 52px;">
            {{ getDescription(Topic) }}</p>
        <!-- </div> -->
    </div>
</div>
