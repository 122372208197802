

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import ImageMap from "image-map";
import * as moment from 'moment';

import { UtilService } from 'src/app/shared/services/util.service';
import { EventService } from './../../../shared/services/event/event.service';

import { Activity } from 'src/app/shared/models/event/eventSchedule.model';
import { xor } from 'lodash';


@Component({
  selector: 'app-image-mapper',
  templateUrl: './image-mapper.component.html',
  styleUrls: ['./image-mapper.component.scss']
})
export class ImageMapperComponent implements OnInit {

  @Input()
  isHome: boolean = false;

  @Input()
  canEdit: boolean

  @Input()
  src: string

  @Input()
  coordinates: ImageMapCoordinate[]

  @Input()
  cordinateX: number = 0;

  @Input()
  cordinateY: number = 0;

  @Output('onClick')
  onClick: EventEmitter<ImageMapCoordinate> = new EventEmitter();

  currentEventId = 0;
  room1Activity: Activity;
  room2Activity: Activity;
  room3Activity: Activity;

  constructor(private dbService: NgxIndexedDBService, private eventService: EventService, private utilService: UtilService) {
    this.setMap();
  }

  ngOnInit() {

    this.eventService.getCurrentEvent().then((ev: any) => {
      this.currentEventId = ev ? ev.id : 0;
      this.loadNextActivities();
    });

    if (!this.coordinates) {
      this.setCoodinates();
    }


    this.setMap();
  }

  loadNextActivities() {

    this.dbService.getAllByIndex('Activity', 'eventScheduleId', IDBKeyRange.only(this.currentEventId)).subscribe((acts) => {
      if (acts) {

        let activities = acts as Activity[];
        let today = moment(new Date()).format('DD/MM/YYYY');
        let currentTime = moment().format("HH:mm");
        this.room1Activity = activities.filter(x => new Date(this.utilService.toDateISO(x.dayDate)) == new Date(this.utilService.toDateISO(today)) && x.roomAbbreviation == "SL01" && x.startTime >= currentTime && !x.isSponsored).sort((a, b) => (a.startTime < b.startTime ? -1 : 1))[0];
        this.room1Activity = this.room1Activity ? this.room1Activity : activities.filter(x => new Date(this.utilService.toDateISO(x.dayDate)) == new Date(this.utilService.toDateISO(today)) && x.roomAbbreviation == "SL01" && !x.isSponsored).sort((a, b) => (a.startTime < b.startTime ? -1 : 1))[0];

        this.room2Activity = activities.filter(x => new Date(this.utilService.toDateISO(x.dayDate)) == new Date(this.utilService.toDateISO(today)) && x.roomAbbreviation == "SL02" && x.startTime >= currentTime && !x.isSponsored).sort((a, b) => (a.startTime < b.startTime ? -1 : 1))[0];
        this.room2Activity = this.room2Activity ? this.room2Activity : activities.filter(x => new Date(this.utilService.toDateISO(x.dayDate)) == new Date(this.utilService.toDateISO(today)) && x.roomAbbreviation == "SL02" && !x.isSponsored).sort((a, b) => (a.startTime < b.startTime ? -1 : 1))[0];

        this.room3Activity = activities.filter(x => new Date(this.utilService.toDateISO(x.dayDate)) == new Date(this.utilService.toDateISO(today)) && x.roomAbbreviation == "SL03" && x.startTime >= currentTime && !x.isSponsored).sort((a, b) => (a.startTime < b.startTime ? -1 : 1))[0];
        this.room3Activity = this.room3Activity ? this.room3Activity : activities.filter(x => new Date(this.utilService.toDateISO(x.dayDate)) == new Date(this.utilService.toDateISO(today)) && x.roomAbbreviation == "SL03" && !x.isSponsored).sort((a, b) => (a.startTime < b.startTime ? -1 : 1))[0];

        if (!this.room1Activity)
          this.room1Activity = activities.filter(x => new Date(this.utilService.toDateISO(x.dayDate)) > new Date(this.utilService.toDateISO(today)) && x.roomAbbreviation == "SL01" && !x.isSponsored).sort((a, b) => (a.dayDate < b.dayDate ? -1 : 1))[0];

        if (!this.room2Activity)
          this.room2Activity = activities.filter(x => new Date(this.utilService.toDateISO(x.dayDate))> new Date(this.utilService.toDateISO(today)) && x.roomAbbreviation == "SL02" && !x.isSponsored).sort((a, b) => (a.dayDate < b.dayDate ? -1 : 1))[0];

        if (!this.room3Activity)
          this.room3Activity = activities.filter(x => new Date(this.utilService.toDateISO(x.dayDate)) > new Date(this.utilService.toDateISO(today)) && x.roomAbbreviation == "SL03" && !x.isSponsored).sort((a, b) => (a.dayDate < b.dayDate ? -1 : 1))[0];

        if (this.isHome)
          this.setCoodinates();
      }
    });

  }

  setMap() {
    setTimeout(() => {
      ImageMap('img[usemap]');
    }, 1000);
  }

  getCoordinateStyle(coordinate: ImageMapCoordinate): object {
    return {
      top: `${coordinate.y1}px`,
      left: `${coordinate.x2}px`,
      height: `${coordinate.height}px`,
      width: `${coordinate.width}px`
    };
  }

  onAreaClick(coordinate) {
    this.onClick.emit(coordinate);
  }

  onAreaContext(e: MouseEvent, coordinate: ImageMapCoordinate) {
    if (this.canEdit) {
      if (coordinate) {
        console.log('editing')

      }
      else {
        console.log('creating')
      }

      e.stopPropagation();
      return false;
    }
  }

  onAreaCreate(x: number, y: number): ImageMapCoordinate {
    const coordinate = new ImageMapCoordinate({ x1: x, y1: y, width: 100, height: 100 });
    return coordinate
  }

  onAreaEdit(coordinate: ImageMapCoordinate): ImageMapCoordinate {
    return coordinate;
  }



  setCoodinates() {
    this.coordinates = [
      {
        title: 'Acessar ORGANON',
        coordinates: [100, 288, 118, 491, 826, 487, 824, 292],
        link: '/evento/expositores/visualizar/37',
        shape: 'poly',
        target: '',
        width: 0,
        height: 0
      },
      {
        title: 'Inscrições',
        coordinates: [2043, 219, 2029, 467, 2140, 467, 2156, 219],
        externalLink: 'https://www.sogesp.com.br/congressos-e-eventos/inscreva-se-nos-eventos-disponiveis/',
        shape: 'poly',
        target: '_blank',
        width: 0,
        height: 0
      },
      {
        title: 'Acessar Meu Certificado',
        coordinates: [2242, 275, 2226, 535, 2341, 540, 2364, 277],
        link: '/meu-evento/certificado',
        shape: 'poly',
        target: '',
        width: 0,
        height: 0
      },
      {
        title: 'Networking',
        coordinates: [1952, 582, 1950, 650, 2491, 663, 2502, 568],
        link: '/evento/networking',
        shape: 'poly',
        target: '',
        width: 0,
        height: 0
      },
      {
        title: 'Acessar CURSOS PRÁTICOS',
        coordinates: [532, 609, 541, 767, 745, 796, 745, 607],
        link: '/evento/programacao/59,61', //59 = CPOO; 61 = CPOG
        shape: 'poly',
        target: '',
        width: 0,
        height: 0
      },
      {
        title: 'Acessar SALA 1',
        coordinates: [817, 613, 826, 873, 1021, 876, 1021, 613],
        link: '/evento/aulas/visualizar/' + (this.room1Activity ? this.room1Activity?.topics[0]?.id : 24341),
        shape: 'poly',
        target: '',
        width: 0,
        height: 0
      },
      {
        title: 'Acessar SALA 2',
        coordinates: [1095, 607, 1098, 831, 1292, 847, 1294, 610],
        link: '/evento/aulas/visualizar/' + (this.room2Activity ? this.room2Activity?.topics[0]?.id : 22401),
        shape: 'poly',
        target: '',
        width: 0,
        height: 0
      },
      {
        title: 'Acessar SALA 3',
        coordinates: [1367, 859, 1568, 780, 1572, 610, 1367, 613],
        link: '/evento/aulas/visualizar/' + (this.room3Activity ? this.room3Activity?.topics[0]?.id : 22129),
        shape: 'poly',
        target: '',
        width: 0,
        height: 0
      },
      {
        title: 'Acessar e-Pôsters',
        coordinates: [1645, 607, 1643, 829, 1839, 870, 1851, 616],
        link: '/evento/e-poster',
        shape: 'poly',
        target: '',
        width: 0,
        height: 0
      },
      {
        title: 'Acessar SOGESP',
        coordinates: [1097, 852, 1099, 963, 1301, 963, 1301, 847],
        link: '/evento/expositores/visualizar/35',
        shape: 'poly',
        target: '',
        width: 0,
        height: 0
      },
      {
        title: 'Acessar ORGANON',
        coordinates: [1034, 982, 1306, 982, 1301, 1384, 1043, 1384],
        link: '/evento/expositores/visualizar/37',
        shape: 'poly',
        target: '',
        width: 0,
        height: 0
      },
      {
        title: 'Acessar NOVO NORDISK',
        coordinates: [835, 883, 840, 980, 894, 983, 887, 1111, 935, 1107, 923, 983, 975, 978, 969, 883],
        link: '/evento/expositores/visualizar/34',
        shape: 'poly',
        target: '',
        width: 0,
        height: 0
      },
      {
        title: 'Acessar LIBBS',
        coordinates: [604, 957, 607, 1079, 683, 1077, 665, 1248, 749, 1233, 717, 1077, 785, 1072, 781, 955, 688, 952],
        link: '/evento/expositores/visualizar/32',
        shape: 'poly',
        target: '',
        width: 0,
        height: 0
      },
      {
        title: 'Acessar EMS',
        coordinates: [191, 955, 290, 946, 390, 939, 401, 1056, 326, 1065, 358, 1215, 277, 1233, 286, 1070, 202, 1077],
        link: '/evento/expositores/visualizar/30',
        shape: 'poly',
        target: '',
        width: 0,
        height: 0
      },
      {
        title: 'Acessar ASTRAZENECA',
        coordinates: [383, 853, 397, 943, 444, 939, 442, 1061, 503, 1054, 482, 939, 537, 934, 523, 848],
        link: '/evento/expositores/visualizar/28',
        shape: 'poly',
        target: '',
        width: 0,
        height: 0
      },

      {
        title: 'Acessar BAYER',
        coordinates: [691, 852, 697, 940, 815, 936, 808, 848],
        link: '/evento/expositores/visualizar/29',
        shape: 'poly',
        target: '',
        width: 0,
        height: 0
      },
      {
        title: 'Acessar BESINS HEALTHCARE',
        coordinates: [546, 811, 552, 888, 593, 888, 607, 937, 620, 888, 654, 883, 654, 806],
        link: '/evento/expositores/visualizar/25',
        shape: 'poly',
        target: '',
        width: 0,
        height: 0
      },


      {
        title: 'Acessar GRUNENTHAL',
        coordinates: [1479, 965, 1474, 1087, 1541, 1087, 1518, 1273, 1595, 1275, 1579, 1090, 1645, 1087, 1647, 961],
        link: '/evento/expositores/visualizar/31',
        shape: 'poly',
        target: '',
        width: 0,
        height: 0
      },
      {
        title: 'Acessar EXELTIS',
        coordinates: [1817, 954, 1814, 1078, 1885, 1087, 1866, 1264, 1930, 1284, 1928, 1090, 1998, 1099, 2011, 972],
        link: '/evento/expositores/visualizar/36',
        shape: 'poly',
        target: '',
        width: 0,
        height: 0
      },
      {
        title: 'Acessar ACHÉ',
        coordinates: [1799, 856, 1794, 949, 1930, 960, 1934, 872],
        link: '/evento/expositores/visualizar/27',
        shape: 'poly',
        target: '',
        width: 0,
        height: 0
      },
      {
        title: 'Acessar SALOMÃO E ZOPPI ',
        coordinates: [1360, 877, 1360, 965, 1408, 963, 1396, 1108, 1446, 1105, 1437, 965, 1482, 956, 1484, 873],
        link: '/evento/expositores/visualizar/38',
        shape: 'poly',
        target: '',
        width: 0,
        height: 0
      }
      ,
      {
        title: 'Acessar MINDRAY',
        coordinates: [1636, 849, 1631, 943, 1677, 947, 1665, 1069, 1713, 1072, 1708, 947, 1753, 954, 1762, 864],
        link: '/evento/expositores/visualizar/33',
        shape: 'poly',
        target: '',
        width: 0,
        height: 0
      },
      {
        title: 'Acessar DKT',
        coordinates: [1496, 811, 1498, 879, 1530, 881, 1539, 953, 1557, 879, 1588, 883, 1591, 815],
        link: '/evento/expositores/visualizar/26',
        shape: 'poly',
        target: '',
        width: 0,
        height: 0
      }
    ]
  }

}

export class ImageMapCoordinate {
  x1?: number = 0
  y1?: number = 0
  x2?: number = 0
  coordinates?: number[] = []
  radius?: number = 0
  shape: string
  target: string
  width?: number = 0
  height?: number = 0
  title?: string
  alt?: string
  externalLink?: string
  link?: string


  constructor(init?: Partial<ImageMapCoordinate>) {
    Object.assign(this, init);
  }
}
