import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
//Services
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { EventService } from 'src/app/shared/services/event/event.service';
import { UserService } from 'src/app/shared/services/user/user.service';
//Models
import { User } from 'src/app/shared/models/user/user.model';
import { Event } from 'src/app/shared/models/event/event.model';

@Component({
  selector: 'app-login-card',
  templateUrl: './login-card.component.html',
  styleUrls: ['./login-card.component.scss']
})
export class LoginCardComponent implements OnInit {

  @ViewChild("login", { static: false }) login: TemplateRef<any>;
  @ViewChild("modalPermission", { static: false }) permission: TemplateRef<any>;

  public modalOpen: boolean = false;
  public loginForm: FormGroup;
  public show: boolean = false;
  public errorMessage: any;
  public allowEmailMarketing: boolean = false;
  public allowSharePersonalInfo: boolean = false;
  public showLoader: boolean = false;

  public _user: User;
  public cpf: string;
  public password: string;
  public logo: string = 'assets/images/logo/logo_sogesp.png';
  public termText = '';

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal,
    public authService: AuthService,
    public userService: UserService,
    private eventService: EventService,
    private alert: AlertService,
    private fb: FormBuilder) {

    this.loginForm = this.fb.group({
      cpf: ['', [Validators.required, Validators.maxLength]],
      password: ['', Validators.required]
    });
  }

  ngOnInit(): void {

    this.eventService.getCurrentEvent().then((ev: Event) => {
      this.termText = ev.authorizationTerm;
      this.logo = ev?.logo;
    });

    this.authService.getUserEmitter().subscribe((user: User) => {
      this._user = user;
      if (this._user) {
        this.ngOnDestroy();
      }
    });
  }

  auth(l: any) {

    this.authService.signIn(this.loginForm.value['cpf'], this.loginForm.value['password']).then((data: User) => {
      if (data) {
        if (data.allowEmailMarketing == null || data.allowSharePersonalInfo == null) {
          this._user = data;
          this.openModalPermission();
        }
      }
    });
  }

  savePermissions() {

    let textError = "Ocorreu um erro ao atualizar permissão.";

    let perm = this.allowEmailMarketing ? 1 : 0;
    let perm2 = this.allowSharePersonalInfo ? 1 : 0;
    this.showLoader = true
    this.userService.savePermissions(this._user.id, perm, perm2).then((data: any) => {
      this.showLoader = false;
      if (data) {
        this.alert.success("Sucesso", "Permissão atualizada com sucesso. Você pode alterar esta permissão a qualquer momento acessando seu perfil.");
        this.ngOnDestroy();
      }
      else
        this.alert.error("Erro", textError);
    });
  }

  openModalPermission() {
    if (isPlatformBrowser(this.platformId)) { // For SSR
      this.modalService.open(this.permission, {
        size: 'md',
        ariaLabelledBy: 'modal-bookmark',
        centered: true,
        windowClass: 'modal-bookmark',
        backdrop: 'static',
        keyboard: false
      }).result.then((result) => {
        if (result)
          this.modalOpen = true;
      });
    }
  }

  updateEmailPermission() {
    this.allowEmailMarketing = !this.allowEmailMarketing;
  }

  updateInfoPermission() {
    this.allowSharePersonalInfo = !this.allowSharePersonalInfo;
  }


  showPassword() {
    this.show = !this.show;
  }

  ngOnDestroy() {
    if (this.modalService.hasOpenModals()) {
      this.modalService.dismissAll();
    }
  }
}
