<div class="default-according style-1 m-b-20" [attr.id]="'accordion_' + Index">
  <div class="card view-file">
    <div class="card-header pdf-header p-l-0" [class.b-b-r-15]="!expanded">
      <div class="row m-l-0 m-b-0" style="margin-right: -20px;">
        <div class="media-body col-md-12 p-0">
          <h6 class="m-b-0">
            <span class="badge badge-dark">{{ Work.coverageAreaDescription }}</span>
            <span class="badge badge-primary">{{ Work.abbreviation }}</span>
            <span class="badge badge-secondary" *ngIf="Work.bestWorkAbbreviation">
              {{ Work.bestWorkAbbreviation }}</span>
          </h6>
          <p class="f-w-600 f-16 m-t-15">{{ Work.title }}</p>
          <div class="row m-0 content-autors">
            <div class="col-sm-6 col-xl-4 p-l-0 m-t-20" *ngFor="let author of Work.authors">
              <div class="media guest-content">
                <img class="img-50 rounded-circle m-r-15"
                  [src]="author.pathPicture ? author.pathPicture : 'assets/images/user/user.png'" alt="">
                <div class="social-status status-50 bg-success"></div>
                <div class="media-body"><span class="f-w-500 d-block" [innerHTML]="getName(author.name)"></span>
                  <span class="d-block">{{author.principal ? 'Autor' : 'Coautor'}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="customers b-t-light m-t-20" #target>
        <a class="btn btn-primary f-w-600 f-15 m-t-20 m-b-5 w-100 btn-file" (click)="viewPoster(expanded, target)"
          [ngbTooltip]="!expanded ? 'Visualizar' : 'Recolher'" tooltipClass="custom-tooltip">
          <i class="expand-icon f-16 f-w-500" [ngClass]="!expanded ? 'icon-plus': 'icon-minus'"></i>
          <span class="m-l-5 f-16">{{!expanded ? 'Visualizar Trabalho': 'Recolher Trabalho'}}</span>
        </a>
      </div>
    </div>
    <div class="collapse b-b-r-15" id="collapseicon" [ngbCollapse]="!expanded">
      <div class="card-body animate-chk p-0 b-b-r-15">
        <div class="row p-0 m-0">
          <div class=" col-md-12 col-xl-12 b-b-r-15 background-pdf">
            <div class="row" *ngIf="Work?.pathFile">
              <div class="col-sm-12 content-pdf">
                <pdf-viewer id="pdf-viewer" [src]="Work.pathFile" [original-size]="false" [render-text]="true"
                  height="90vh" type="application/pdf" style="pointer-events: none">
                </pdf-viewer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
