import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertService } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-contact-card',
  templateUrl: './contact-card.component.html',
  styleUrls: ['./contact-card.component.scss']
})
export class ContactCardComponent implements OnInit {

  public expanded: boolean;

  @Output() addToFavorites = new EventEmitter<{ id: number, confirm: boolean }>();

  @Input() Id: number;
  @Input() Index: number;  
  @Input() Name: string;
  @Input() Picture: string;
  @Input() City: string;
  @Input() CountryState: string;
  @Input() IsFavorite: boolean;
  @Input() Email: string;
  @Input() WhatsApp: string;
  @Input() Facebook: string;
  @Input() Instagram: string;
  @Input() LinkedIn: string;
  @Input() Lattes: string;
  @Input() Institutions: string[];
  @Input() ShowSpeakerDetails: boolean;
  @Input() ShowFavorite: boolean;
  @Input() ShowFistAndLastName: boolean;

  constructor(private alert: AlertService) { }

  ngOnInit(): void {
    if (this.ShowFistAndLastName)
      this.Name = this.Name.split(/(\s).+\s/).join("");
      let namesplit = this.Name.split(' ');

      this.Name = namesplit.length >= 2 ? namesplit[0] + '<br>' + namesplit[1] : this.Name;
  }

  addFavorites(contactId: number, confirm: boolean) {
    this.addToFavorites.emit({ id: contactId, confirm: confirm });
  }

  onDone() {
    localStorage.setItem('contactsTourViewed', 'true');
    this.alert.error('Informação!', 'Mantenha sua foto e cadastros sempre atualizados para que seus amigos(as) possam encontrá-los(as).');
  }
}
