import { UtilService } from 'src/app/shared/services/util.service';
import { EventEmitter, Injectable, Output } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {


  public config = {
    settings: {
      layout_type: 'ltr',
      layout_version: localStorage.getItem('layout_version') ? localStorage.getItem('layout_version') : 'light-only',
      sidebar_type: 'default-sidebar',
      sidebar: {
        type: 'compact-wrapper',
        body_type: 'sidebar-icon'
      }
    },
    color: {
      primary_color: '#DE3051',//'#7366ff',
      secondary_color: '#f73164'
    }
  }

  constructor(private utilService: UtilService) {
    if (this.config.settings.layout_type == 'rtl')
      document.getElementsByTagName('html')[0].setAttribute('dir', this.config.settings.layout_type);

    document.documentElement.style.setProperty('--theme-deafult', this.config.color.primary_color);
    document.documentElement.style.setProperty('--theme-secondary', this.config.color.secondary_color);

  }

  setThemeColors(primary: string, secondary: string) {

    document.documentElement.style.setProperty('--theme-deafult', primary);
    document.documentElement.style.setProperty('--theme-default-op-006', this.utilService.hexToRgbA(primary, 0.06));
    document.documentElement.style.setProperty('--theme-default-op-05', this.utilService.hexToRgbA(primary, 0.5));
    document.documentElement.style.setProperty('--theme-default-op-12', this.utilService.hexToRgbA(primary, '12%'));
    document.documentElement.style.setProperty('--theme-default-op-035', this.utilService.hexToRgbA(primary, 0.35))
    document.documentElement.style.setProperty('--theme-default-op-010', this.utilService.hexToRgbA(primary, '0.10%'));
    document.documentElement.style.setProperty('--theme-default-op-2', this.utilService.hexToRgbA(primary, '2%'));

    document.documentElement.style.setProperty('--theme-default-op-01', this.utilService.hexToRgbA(primary, 0.1));
    document.documentElement.style.setProperty('--theme-secondary', secondary);
  }
}
