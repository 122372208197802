import { Component, OnInit } from '@angular/core';
import { NgxIndexedDBService } from 'ngx-indexed-db';
declare var require;
const Swal = require('sweetalert2');
//Compónents
import { AppComponent } from 'src/app/app.component';
//Services
import { AlertService } from 'src/app/shared/services/alert.service';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
//Models
import { User } from 'src/app/shared/models/user/user.model';




@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit {

  public _user: User = undefined;
  public openProfile: boolean = false;

  constructor(private dbService: NgxIndexedDBService, private appComponent: AppComponent, private authService: AuthService, private alert: AlertService) { }

  ngOnInit() {

    this.authService.getUserEmitter().subscribe((user) => {

      this._user = user;
    });

    this.getUser();
  }

  getUser() {

    this.dbService.getAll('User').subscribe((users) => {
      if (users && users.length)
        this._user = users[0] as User;
    });
  }

  openLogin() {
    this.appComponent.openLogin();
  }

  logout() {
    let self = this;
    this.alert.confirmation('Sair', 'Deseja sair?', 'warning', null, null, function () { self.authService.signOut() })
  }

  toggleProfileMobile(){
    this.openProfile = !this.openProfile;
  }
}
