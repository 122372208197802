import { AppComponent } from 'src/app/app.component';
import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { JoyrideService } from 'ngx-joyride';

import { UtilService } from 'src/app/shared/services/util.service';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { NavService } from '../../services/nav.service';
import { LayoutService } from '../../services/layout.service';
import { SearchService } from '../../services/search/search.service';

import { User } from '../../models/user/user.model';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public logo: string;
  public logoDark: string;
  public logoIcon: string;
  public elem: any;
  public dark: boolean = this.layout.config.settings.layout_version == 'dark-only' || localStorage.getItem('layout_version') == 'dark-only' ? true : false;
  public mobileLayout: boolean = false;
  public showLayout: boolean = false;
  public $this: any;
  public textSearch: string = '';
  public userLogged: User;


  constructor(
    public auth: AuthService,
    public layout: LayoutService,
    public navServices: NavService,
    public util: UtilService,
    private dbService: NgxIndexedDBService,
    private searchService: SearchService,
    private router: Router,
    private joyrideService: JoyrideService,
    public appComponent: AppComponent,
    @Inject(DOCUMENT) private document: any
  ) {

    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        if ((e.url === "/evento/inicio" || e.url === "/evento/expositores") && !util.isMobile()) {
          this.showLayout = false;//true para ativar troca
        } else {
          this.showLayout = false;
        }
      }
    });

  }

  ngOnInit() {
    this.elem = document.documentElement;

    setTimeout(() => {
      this.startTour();
    }, 1000)

    this.setLayout();
    this.setLogo();
    this.loadSearchWidth()
    this.checkUserLogged();
  }

  startTour() {
    let tourViewed = localStorage.getItem('viewedTour');
    if (!tourViewed && !this.util.isMobile()) {
      this.joyrideService.startTour(
        {
          steps: ['1', '2', '3', '4', '5', '6', '7'],
          startWith: '1',
          customTexts: { next: '>>', prev: '<<', done: 'Ok' },
        },
      );

      // setTimeout(() => {
      //   window.scroll(0, 0)
      // }, 300);

    }
  }

  onDone() {
    localStorage.setItem('viewedTour', 'true');
  }

  checkUserLogged(): void {
    this.auth.getUserEmitter().subscribe((user: any) => {
      this.userLogged = user;
    });

    this.dbService.getAll('User').subscribe((users: any) => {
      let userIsLogged = !!(users && users.length);
      if (userIsLogged) {
        this.userLogged = users[0];
      }
    });
  }

  setLayout() {
    this.auth.getLayoutEmitter().subscribe((layoutMobile: any) => {
      this.mobileLayout = layoutMobile;
    });
  }

  setLogo() {
    setTimeout(() => {
      this.dbService.getByKey('Event', 13).subscribe((e) => {

        if (e) {
          this.logo = e.Logo;
          this.logoDark = e.LogoBlack;
          this.logoIcon = e.LogoIcon;
        }
        else {
          this.logo = 'assets/images/logo/logo.png';
          this.logoDark = 'assets/images/logo/logo.png'
        }
      });
    }, 1000)
  }

  sidebarToggle() {
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
    this.navServices.isFixedSidebar = !this.navServices.isFixedSidebar;
    this.navServices.megaMenu = false;
    this.navServices.levelMenu = false;
  }

  layoutToggle() {
    this.dark = !this.dark;
    this.layout.config.settings.layout_version = this.dark ? 'dark-only' : 'light';
    localStorage.setItem('layout_version', this.layout.config.settings.layout_version);

  }

  searchToggle() {
    this.navServices.search = true;
  }

  languageToggle() {
    this.navServices.language = !this.navServices.language;
  }

  changeLayout() {
    this.auth.changeLayout();
  }


  toggleFullScreen() {
    this.navServices.fullScreen = !this.navServices.fullScreen;
    if (this.navServices.fullScreen) {
      if (this.elem.requestFullscreen) {
        this.elem.requestFullscreen();
      } else if (this.elem.mozRequestFullScreen) {
        /* Firefox */
        this.elem.mozRequestFullScreen();
      } else if (this.elem.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.elem.webkitRequestFullscreen();
      } else if (this.elem.msRequestFullscreen) {
        /* IE/Edge */
        this.elem.msRequestFullscreen();
      }
    } else {
      if (!this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }

  @HostListener('document:fullscreenchange', ['$event'])
  @HostListener('document:webkitfullscreenchange', ['$event'])
  @HostListener('document:mozfullscreenchange', ['$event'])
  @HostListener('document:MSFullscreenChange', ['$event'])
  exitFullScreen() {
    if (!this.document.fullscreenElement && !this.document.webkitIsFullScreen && !this.document.mozFullScreen && !this.document.msFullscreenElement) {
      this.navServices.fullScreen = false;
    }

  }

  getSearchWidth() {

    if (window.innerWidth < 1220)
      return undefined;

    return this.textSearch ? ((this.textSearch.length + 1) * 9 + 70) : 0;
  }

  loadSearchWidth() {
    this.searchService.getSearchEmitter().subscribe((term) => {
      this.textSearch = term;
    });
  }

}
