import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import * as Plyr from 'plyr';

import { AlertService } from './../../../shared/services/alert.service';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss']
})
export class VideoPlayerComponent implements OnInit {

  @Input() Title: string;
  @Input() VideoId: string;
  @Input() VideoUrl: string;
  @Input() VideoType: any;
  @Input() ThumbUrl: string;
  @Input() Color: string = 'none';
  @Input() AutoPlay: boolean;

  @Output() startedVideo = new EventEmitter<void>();
  @Output() finishVideo = new EventEmitter<void>();
  @Output() checkProgress = new EventEmitter<void>();
  @Output() pauseVideo = new EventEmitter<void>();

  private started: boolean = false;
  public chatUrl: any = undefined;

  player: Plyr;
  public options: Plyr.Options = {
    hideControls: true,
    vimeo: { byline: false, portrait: false, title: false, speed: true, quality: true, transparent: true },
    youtube: { byline: false, portrait: false, title: false, speed: true, quality: true, transparent: true }
  };
  public videoSources: Plyr.Source[];

  constructor(public dom: DomSanitizer, public alertService: AlertService) { }

  ngOnInit(): void {

    this.options.autoplay = this.AutoPlay;

    if (!this.VideoId) {
      this.setVideoInfo(this.VideoUrl)
    }
    if (this.ThumbUrl)
      this.options.previewThumbnails = { enabled: true, src: this.ThumbUrl }

    if (this.VideoType == 'vimeo') {
      this.options.controls = [];
    }

    this.videoSources = [
      {
        src: this.VideoId,
        provider: this.VideoType,
      },
    ];
  }

  played(event: Plyr.PlyrEvent) {

    if (!this.started) {
      this.started = true;
      this.startedVideo.emit();
    }
  }

  paused(event: Plyr.PlyrEvent) {
    this.pauseVideo.emit();
  }

  finished(event: Plyr.PlyrEvent) {
    this.finishVideo.emit();
  }

  checkingProgress(event: Plyr.PlyrEvent) {
    if (this.started && this.getPercentageWatched() >= 70) {
      this.checkProgress.emit();
    }
  }

  public getPercentageWatched(): number {

    var percentage = this.player.currentTime * 100 / this.player.duration;
    var result = parseInt(percentage.toString());
    return result;

  }

  public setVideoInfo(url: string) {

    if (!url)
      this.alertService.error("Vídeo indisponível", "Aguarde a liberação do vídeo pela SOGESP");
    else {

      let videoId = "";
      let videoUrl = "";

      var regexYoutube = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]{11,11}).*/;
      if (url.match(regexYoutube)) {
        videoId = url.split(/v\/|v=|youtu\.be\//)[1].split(/[?&]/)[0];
        videoUrl = url;
      }
      else {
        let vimeoVideo = url.split('/').map(Number);

        for (var i = 0; i < vimeoVideo.length; i++)
          if (vimeoVideo[i] > 0)
            videoId = vimeoVideo[i].toString();

        videoUrl = 'https://player.vimeo.com/video/' + videoId;
      }

      this.VideoId = videoId;
      this.VideoType = videoUrl.indexOf('youtube') >= 0 ? "youtube" : "vimeo";
    }
  }


}
