import { GetEventForViewInput } from './../../models/event/eventSchedule.model';
import { GetActivityLivesInput } from './../../models/event/get-activity-lives.model';
import { NotificationModel } from 'src/app/shared/models/event/notification.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { EventEmitter, Inject, Injectable, Output } from '@angular/core';
//Services
import { DbService } from 'src/app/shared/services/db.service';
import { UtilService } from 'src/app/shared/services/util.service';
import { environment } from './../../../../environments/environment';
import { NetworkConnectionService } from 'src/app/shared/services/network-connection.service';
//Models
import { Event } from 'src/app/shared/models/event/event.model';
import {
  Activity,
  EventSchedule,
  GetCompleteEventScheduleInput,
  Participation,
  ScheduleDay,
  Topic,
} from '../../models/event/eventSchedule.model';
import { CoverageArea } from '../../models/event/coverage-area.model';
import { ThematicArea } from '../../models/event/thematic-area.model';
import { AddActivityViewInput } from './../../models/event/add-view-activity.model';
import { RateActivityInput } from './../../models/event/rate-activity.model';
import { SendGuestContatcMessageInput } from './../../models/event/send-guest-contatc-message.model';
import { AddViewGuestInput } from './../../models/event/add-view-guest-input.model';
import { Certificate } from './../../models/event/certificate.model';
import { AddGuestToFavoritesInput } from './../../models/event/add-guest-to-favorites.model';
import { AddActivityToFavoritesInput } from './../../models/event/add-activity-to-favorites.model';
import { AddContactToFavoritesInput } from './../../models/event/add-contact-to-favorites.model';
import { SendSupportContatcMessageInput } from '../../models/event/send-support-contact-message.model';
import { CoverageAreaEPoster, Work } from '../../models/event/e-poster-model';
import { Question } from '../../models/event/question.model';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from '../alert.service';

@Injectable({
  providedIn: 'root',
})
export class EventService {
  @Output() updateNotifications = new EventEmitter<number>();

  private http: HttpClient;
  private baseUrl: string;
  public trabalhosUrl: string;
  public baseSgunServiceUrl: string;
  public baseSgunwAuth: string;
  public baseSgunReferer: string;
  public currentEvent: Event;

  constructor(
    @Inject(HttpClient) http: HttpClient,
    private dbService: NgxIndexedDBService,
    private connectionService: NetworkConnectionService,
    private appDbService: DbService,
    public utilService: UtilService,
    private alert: AlertService,
    private route: ActivatedRoute
  ) {
    this.http = http;
    this.baseUrl = environment.serverUrl;
    this.trabalhosUrl = environment.trabalhosUrl;
    this.baseSgunServiceUrl = environment.sgunServiceUrl;
    this.baseSgunwAuth = environment.sgunAuthorization;
    this.baseSgunReferer = environment.sgunReferer;
    this.getCurrentEvent();
  }

  getEvent(input: GetEventForViewInput) {
    let eventId = input.eventId > 0 ? input.eventId : 0;
    let url =
      this.baseUrl +
      'EventSchedules/GetEventForView?EventId=' +
      eventId +
      '&Code=' +
      input.code;

    let options: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.request('get', url, options);
  }

  getEventData(id: number): Promise<Event> {

    return this.appDbService
      .get(id, 'Event')
      .toPromise()
      .then((event: Event) => {
        if (
          event &&
          !this.utilService.checkTimeToRefresh(event.lastRefreshDate, 10) &&
          this.connectionService.checkConnection(true)
        ) {
          return event;
        } else if(this.connectionService.checkConnection(true)){
          let input = new GetEventForViewInput();
          input.eventId = id;
          input.code = localStorage.getItem('eventCode');
          return this.getEvent(input)
            .toPromise()
            .then((data: any) => {
              if (event) {
                event = data.result;
                event.lastRefreshDate = new Date().toISOString();
                this.appDbService.update(event, 'Event');
                return event;
              } else {
                this.appDbService.clear('Event');
                data.lastRefreshDate = new Date().toISOString();
                event = data.result;
                this.appDbService.create(event, 'Event');

                return event;
              }
            });

          return event;
        }
        else{
         return this.appDbService.getAll('Event').toPromise().then((events: Event[]) => {
            if(events && events.length){
              return events[0];
            }
            else{
              this.alert.success('Falha!', 'Não foi possível carregar as informações do evento. Verifique sua conexão e tente novamente.');
              return undefined;
            }
          });

        }
      });
  }

  getCurrentEvent(): Promise<Event> {

    return this.dbService
      .getAll('Event')
      .toPromise()
      .then((events) => {
        if (
          events &&
          events.length &&
          !this.utilService.checkTimeToRefresh(events[0].lastRefreshDate, 10)
        ) {
          this.currentEvent = events[0] as Event;
          return this.currentEvent;
        } else {
          return this.getEventData(0);
        }
      });
  }

  getEventScheduleForView(id: number) {
    let url = this.baseUrl + 'EventSchedules/GetEventScheduleForViews?id=' + id;

    let options: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.request('get', url, options);
  }

  getCompleteEventSchedule(input: GetCompleteEventScheduleInput) {
    let id = input.eventId ? input.eventId : 0;
    input.code = localStorage.getItem('eventCode');
    let url =
      this.baseUrl +
      'EventSchedules/GetCompleteEventSchedule?EventId=' +
      id +
      '&Code=' +
      input.code;

    let options: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.request('get', url, options);
  }

  getCompleteEventScheduleData(eventId: number): Promise<EventSchedule> {
    return this.dbService
      .getByKey('EventSchedule', eventId)
      .toPromise()
      .then((dataSchedule: EventSchedule) => {
        if (
          dataSchedule &&
          !this.utilService.checkTimeToRefresh(dataSchedule.lastRefreshDate) &&
          this.connectionService.checkConnection(true)
        ) {
          dataSchedule.updated = false;
          return dataSchedule as EventSchedule;
        } else {
          let input = new GetCompleteEventScheduleInput();
          return this.getCompleteEventSchedule(input)
            .toPromise()
            .then((data: any) => {
              if (data) {
                let schedule = new EventSchedule();
                schedule = data.result as EventSchedule;
                schedule.lastRefreshDate = new Date().toISOString();

                if (dataSchedule) {
                  this.appDbService.update(schedule, 'EventSchedule');

                  schedule.days.forEach((d) => {
                    let day = d as ScheduleDay;

                    this.appDbService.update(day, 'Day');

                    d.activities.forEach((a) => {
                      let activity = a as Activity;

                      this.appDbService.update(activity, 'Activity');
                      if (activity.topics) {
                        activity.topics.forEach((t) => {
                          let topic = t as Topic;

                          this.appDbService.update(topic, 'Topic');

                          topic.participations.forEach((p) => {
                            let participation = p as Participation;

                            this.appDbService.update(
                              participation,
                              'Participation'
                            );
                          });
                        });
                      }
                    });
                  });
                } else {
                  this.appDbService.create(schedule, 'EventSchedule');

                  schedule.days.forEach((d) => {
                    let day = d as ScheduleDay;

                    this.appDbService.create(day, 'Day');

                    d.activities.forEach((a) => {
                      let activity = a as Activity;

                      this.appDbService.create(activity, 'Activity');

                      if (activity.topics) {
                        activity.topics.forEach((t) => {
                          let topic = t as Topic;

                          this.appDbService.create(topic, 'Topic');

                          topic.participations.forEach((p) => {
                            let participation = p as Participation;

                            this.appDbService.create(
                              participation,
                              'Participation'
                            );
                          });
                        });
                      }
                    });
                  });
                }

                schedule.updated = true;
                return schedule;
              }
            });
        }
      });
  }

  getCoverageAreasForSelect(id: number) {
    let url = this.baseUrl + 'CoverageAreas/GetForSelect?eventId=' + id;

    let options: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.request('get', url, options);
  }

  getCoverageAreasForSelectData(id: number): Promise<CoverageArea[]> {
    return this.dbService
      .getAll('CoverageArea')
      .toPromise()
      .then((areas: CoverageArea[]) => {
        if (
          areas &&
          areas.length &&
          !this.utilService.checkTimeToRefresh(areas[0].lastRefreshDate) &&
          this.connectionService.checkConnection(true)
        )
          return areas;
        else
          return this.getCoverageAreasForSelect(id)
            .toPromise()
            .then((areas: any) => {
              let allAreas = areas.result as CoverageArea[];
              this.appDbService.clear('CoverageArea');

              allAreas.forEach((a) => {
                a.lastRefreshDate = new Date().toISOString();
                this.appDbService.create(a, 'CoverageArea');
              });
              return allAreas;
            });
      });
  }

  getThematicAreasForSelect(id: number) {
    let url = this.baseUrl + 'ThematicAreas/GetForSelect?eventId=' + id;

    let options: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.request('get', url, options);
  }

  getThematicAreasForSelectData(id: number): Promise<ThematicArea[]> {
    return this.dbService
      .getAll('ThematicArea')
      .toPromise()
      .then((areas: ThematicArea[]) => {
        if (
          areas &&
          areas.length &&
          !this.utilService.checkTimeToRefresh(areas[0].lastRefreshDate) &&
          this.connectionService.checkConnection(true)
        )
          return areas as ThematicArea[];
        else
          return this.getThematicAreasForSelect(id)
            .toPromise()
            .then((areas: any) => {
              let allAreas = areas.result as ThematicArea[];
              allAreas.forEach((a) => {
                a.lastRefreshDate = new Date().toISOString();
                this.appDbService.create(a, 'ThematicArea');
              });
              return allAreas;
            });
      });
  }

  getActivityTypesForSelect(id: number) {
    let url = this.baseUrl + 'ActivityTypes/GetForSelect?eventId=' + id;

    let options: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.request('get', url, options);
  }

  getGuestsByEventId(id: number) {
    let url = this.baseUrl + 'Guests/GetGuestsByEventId?id=' + id;

    let options: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.request('get', url, options);
  }

  getGuestById(id: number) {
    let url = this.baseUrl + 'Guests/GetGuestById?id=' + id;

    let options: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.request('get', url, options);
  }

  getGuestActivitiesById(id: number) {
    let url = this.baseUrl + 'Guests/GetGuestActivitiesById?id=' + id;

    let options: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.request('get', url, options);
  }

  addActivityToFavorites(id: number, confirm: boolean, userId: number) {
    let url = this.baseUrl + 'Activities/AddToFavorites';
    let fav = new AddActivityToFavoritesInput();
    fav.activityId = id;
    fav.confirmAdd = confirm;
    fav.userId = userId;
    let body = JSON.stringify(fav);

    let options: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post(url, body, options);
  }

  rateActivity(id: number, rate: number, userId: number) {
    let url = this.baseUrl + 'Activities/RateActivity';
    let r = new RateActivityInput();
    r.activityId = id;
    r.rate = rate;
    r.userId = userId;
    let body = JSON.stringify(r);

    let options: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post(url, body, options);
  }

  addActivityView(
    id: number,
    percentageWatched: number,
    userId: number,
    userCategoryId: number,
    userCategoryTypeId: number
  ) {
    let url = this.baseUrl + 'Activities/AddActivityView';
    let v = new AddActivityViewInput();
    v.topicId = id;
    v.percentageWatched = percentageWatched;
    v.userId = userId;
    (v.userCategoryId = userCategoryId),
      (v.userAssociationTypeId = userCategoryTypeId);
    let body = JSON.stringify(v);

    let options: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post(url, body, options);
  }

  addGuestToFavorites(id: number, confirm: boolean, userId: number) {
    let url = this.baseUrl + 'Guests/AddToFavorites';
    let fav = new AddGuestToFavoritesInput();
    fav.guestId = id;
    fav.confirmAdd = confirm;
    fav.userId = userId;
    let body = JSON.stringify(fav);

    let options: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post(url, body, options);
  }

  getWorks() {
    let url = this.baseUrl + 'EPosters/GetAll';

    let options: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.request('get', url, options);
  }

  getWorksData(): Promise<Work[]> {
    return this.dbService
      .getAll('WorkEPoster')
      .toPromise()
      .then((works: Work[]) => {
        if (
          works &&
          works.length &&
          !this.connectionService.checkConnection(true)
        ) {
          return works as Work[];
        } else if (
          works &&
          works.length &&
          !this.utilService.checkTimeToRefresh(works[0].lastRefreshDate) &&
          this.connectionService.checkConnection(true)
        ) {
          return works as Work[];
        } else {
          return this.getWorks()
            .toPromise()
            .then((data: any) => {
              let allWorks = data.result.works as Work[];

              if (works && works.length) {
                allWorks.forEach((w) => {
                  let work = w as Work;
                  work.lastRefreshDate = new Date().toISOString();
                  this.appDbService.update(work, 'WorkEPoster');
                });

                let coverageAreas = data.result.areas as CoverageAreaEPoster[];
                coverageAreas.forEach((c) => {
                  let coverageArea = c as CoverageAreaEPoster;
                  coverageArea.lastRefreshDate = new Date().toISOString();
                  this.appDbService.update(coverageArea, 'CoverageAreaEPoster');
                });
              } else {
                allWorks.forEach((w) => {
                  let work = w as Work;
                  work.lastRefreshDate = new Date().toISOString();
                  this.appDbService.create(work, 'WorkEPoster');
                });

                let coverageAreas = data.result.areas as CoverageAreaEPoster[];
                coverageAreas.forEach((c) => {
                  let coverageArea = c as CoverageAreaEPoster;
                  coverageArea.lastRefreshDate = new Date().toISOString();
                  this.appDbService.create(coverageArea, 'CoverageAreaEPoster');
                });
              }

              return allWorks;
            });
        }
      });
  }

  getBanners(id: number) {
    let url = this.baseUrl + 'Banners/GetBannersByEventId?id=' + id;

    let options: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.request('get', url, options);
  }

  getCertificate(model: Certificate) {
    let url =
      this.baseUrl +
      'Certificates/GetCertificate?' +
      'EventId=' +
      model.eventId +
      '&UserId=' +
      model.userId;
    let options: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.request('get', url, options);
  }

  getContacts(id: number) {
    let url = this.baseUrl + 'Contacts/GetAll?eventId=' + id;

    let options: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.request('get', url, options);
  }

  getQuestions(id: number) {
    let url = this.baseUrl + 'Questions/GetAllByEventId?eventId=' + id;

    let options: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.request('get', url, options);
  }

  getQuestionsData(id: number): Promise<Question[]> {
    return this.dbService
      .getAll('Question')
      .toPromise()
      .then((questions: Question[]) => {
        if (
          questions &&
          questions.length &&
          !this.utilService.checkTimeToRefresh(questions[0].lastRefreshDate) &&
          this.connectionService.checkConnection(true)
        ) {
          return questions;
        } else {
          return this.getQuestions(id)
            .toPromise()
            .then((questions: any) => {
              let allQuestions = questions.result as Question[];

              allQuestions.forEach((q) => {
                let question = q as Question;
                question.lastRefreshDate = new Date().toISOString();
                this.appDbService.create(question, 'Question');
              });

              return allQuestions;
            });
        }
      });
  }

  saveViewGuest(userSgunId: number, guestId: number) {
    let url = this.baseUrl + 'Guests/SaveView';
    let view = new AddViewGuestInput();
    view.userSgunId = userSgunId;
    view.guestId = guestId;
    let body = JSON.stringify(view);

    let options: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post(url, body, options);
  }

  sendGuestContatcMessage(message: SendGuestContatcMessageInput) {
    let url = this.baseUrl + 'Guests/SendGuestContatcMessage';

    let body = JSON.stringify(message);

    let options: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post(url, body, options);
  }

  addContactToFavorites(
    userSgunId: number,
    contactSgunId: number,
    confirm: boolean
  ) {
    let url = this.baseUrl + 'Contacts/AddToFavorites';
    let fav = new AddContactToFavoritesInput();
    fav.userId = userSgunId;
    fav.contatId = contactSgunId;
    fav.confirmAdd = confirm;
    let body = JSON.stringify(fav);

    let options: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post(url, body, options);
  }

  sendSupportContatcMessage(message: SendSupportContatcMessageInput) {
    let url = this.baseUrl + 'Support/SendSupportContatcMessage';

    let body = JSON.stringify(message);

    let options: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post(url, body, options);
  }

  getNotifications(id: number) {
    let url = this.baseUrl + 'EventNotifications/GetAllByEventId?eventId=' + id;

    let options: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.request('get', url, options);
  }

  getNotificationsData(id: number): Promise<NotificationModel[]> {
    return this.dbService
      .getAll('Notification')
      .toPromise()
      .then((notifications: NotificationModel[]) => {
        if (
          notifications &&
          notifications.length &&
          !this.connectionService.checkConnection(true)
        ) {
          return notifications;
        } else {
          return this.getNotifications(id)
            .toPromise()
            .then((notes: any) => {
              if (!notifications) notifications = [];

              let allNotifications = notes.result as NotificationModel[];
              allNotifications.forEach((n) => {
                this.appDbService.get(n.id, 'Notification').subscribe((d) => {
                  if (!d) {
                    let notification = n as NotificationModel;
                    notification.message = decodeURI(notification.message);
                    this.appDbService.create(notification, 'Notification');
                    notifications.push(notification);
                  }
                });
              });

              return notifications.length > 0
                ? notifications
                : allNotifications;
            });
        }
      });
  }

  getActivityLives(input: GetActivityLivesInput) {
    let url =
      this.baseUrl +
      'Activities/GetLives?EventId=' +
      input.eventId +
      '&AtivityId=' +
      input.activityId +
      '&Room=' +
      input.room;

    let options: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.request('get', url, options);
  }

  getTopicById(id: number) {
    let url = this.baseUrl + 'Activities/GetTopicById?id=' + id;

    let options: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.request('get', url, options);
  }

  getUpdateNotificationsEmitter() {
    return this.updateNotifications;
  }
}
