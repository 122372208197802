import { UserModule } from './../../user/user.module';
import { Routes } from '@angular/router';


export const content: Routes = [
  {
    path: 'evento',
    loadChildren: () => import('../../event/event.module').then(m => m.EventModule)
  },
  {
    path: 'meu-evento',
    loadChildren: () => import('../../my-event/my-event.module').then(m => m.MyEventModule)
  },
  {
    path: 'organizacao',
    loadChildren: () => import('../../organization/organization.module').then(m => m.OrganizationModule)
  },
  {
    path: 'usuario',
    loadChildren: () => import('../../user/user.module').then(m => m.UserModule)
  },
];
