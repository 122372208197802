<ng-template #modalNotification let-modal>
    <div class="modal-header bg-light">
        <h4 id="modal-basic-title" class="modal-title">{{title}}</h4>
        <button class="close" type="button" data-dismiss="modal" aria-label="Close" data-original-title="" title=""
            (click)="modal.dismiss('Cross click')"><span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body p-0">
        <div class="container-fluid p-0">
            <div class="row">
                <div class="col-12">
                    <div class="login-card p-25 bg-light">
                        <div>
                            <div class="login-main">
                                <div class="theme-form">
                                    <h5 class="form-title f-15" [innerHTML]="description"></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>