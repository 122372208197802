import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(private toastrService: ToastrService) { }

  /** 
  Show Basic Toast
  positions: 'toast-bottom-right', 'toast-bottom-left', 'toast-top-right', 'toast-top-left, 'toast-bottom-center', 'toast-top-center
  types: 'info', 'warning', 'success', 'error'
  */
  basic(message: string, title?: string, position?: string, type?: string) {
    this.toastrService.show(message, title, { positionClass: position }, type);
  }

  /** 
  Show Info Toast
  positions: 'toast-bottom-right', 'toast-bottom-left', 'toast-top-right', 'toast-top-left, 'toast-bottom-center', 'toast-top-center
  */
  info(message: string, title?: string, position?: string,) {
    this.toastrService.info(message, title, { positionClass: position });
  }

  /** 
  Show Warning Toast
  positions: 'toast-bottom-right', 'toast-bottom-left', 'toast-top-right', 'toast-top-left, 'toast-bottom-center', 'toast-top-center
  */
  warning(message: string, title?: string, position?: string,) {
    this.toastrService.warning(message, title, { positionClass: position });
  }

  /** 
  Show Success Toast
  positions: 'toast-bottom-right', 'toast-bottom-left', 'toast-top-right', 'toast-top-left, 'toast-bottom-center', 'toast-top-center
  */
  success(message: string, title?: string, position?: string,) {
    this.toastrService.success(message, title, { positionClass: position });
  }

  /** 
  Show Error Toast
  positions: 'toast-bottom-right', 'toast-bottom-left', 'toast-top-right', 'toast-top-left, 'toast-bottom-center', 'toast-top-center
  */
  error(message: string, title?: string, position?: string,) {
    this.toastrService.error(message, title, { positionClass: position });
  }

  /** 
  Show Custom Toast
  positions: 'toast-bottom-right', 'toast-bottom-left', 'toast-top-right', 'toast-top-left, 'toast-bottom-center', 'toast-top-center
  types: 'info', 'warning', 'success', 'error'
  */
  custom(message: string, title: string, showCloseButton: boolean, enableHtml: boolean, position: string, type?: string) {
    this.toastrService.show(message, title, { closeButton: showCloseButton, enableHtml: enableHtml, positionClass: position }, type);

    this.toastrService.show()
  }

}
