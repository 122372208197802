import { Component, Input, OnInit } from '@angular/core';
// Services
import { SearchService } from './../../services/search/search.service';

@Component({
  selector: 'highlight-words',
  templateUrl: './highlight.component.html'
})
export class HighlightComponent implements OnInit {

  constructor(
    private searchService: SearchService
  ) { }

  @Input() Text: string;

  public highlightTerm: string = "";

  ngOnInit(): void {
    this.loadHighlight()
  }

  loadHighlight(): void {

    this.highlightTerm = this.searchService.getSearchModel().term;

    this.searchService.getSearchToggleEmitter().subscribe((open) => {
      if (!open)
        this.highlightTerm = '';
    });
  }

  removeDiacritics(text: string) : string {
    return text ? text.normalize("NFD").replace(/[\u0300-\u036f]/g, "") : '';

  }
}

