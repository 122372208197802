import { EventService } from './../event/event.service';
import { NetworkConnectionService } from 'src/app/shared/services/network-connection.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  EventEmitter,
  Inject,
  Injectable,
  NgZone,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

import { DbService } from 'src/app/shared/services/db.service';
import { ToastrService } from 'ngx-toastr';
import { AlertService } from 'src/app/shared/services/alert.service';

import { User } from 'src/app/shared/models/user/user.model';
import { UserCookie } from 'src/app/shared/models/user/user.model';
import { Auth } from 'src/app/shared/models/user/auth.model';
import { AuthenticateResultModel } from 'src/app/shared/models/user/auth.result.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  @Output() userIsLoggedIn: EventEmitter<any> = new EventEmitter<any>();
  public desktopLayout: boolean = false; //TODO: Ativar troca de layout localStorage.getItem('layout') == '1';
  @Output() activeMobileLayout: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  private http: HttpClient;
  private baseUrl: string;
  public baseSgunServiceUrl: string;
  public baseSgunReferer: string;
  public baseSgunwAuth: string;

  public showLoader: boolean = false;
  public _userLogged: User;

  constructor(
    @Inject(HttpClient) http: HttpClient,
    public router: Router,
    public ngZone: NgZone,
    public dbService: DbService,
    private alert: AlertService,
    private connectionService: NetworkConnectionService,
    private eventService: EventService,
    private toastrService: ToastrService
  ) {
    this.http = http;
    this.baseUrl = environment.serverUrl;
    this.baseSgunwAuth = environment.sgunAuthorization;
    this.baseSgunServiceUrl = environment.sgunServiceUrl;
    this.baseSgunReferer = environment.sgunReferer;

    this.getLoggedUser();
  }

  signIn(userName: string, password: string): Promise<any> {
    let url =
      environment.serverRootUrl + 'api/' + 'TokenAuth/AuthenticateForEvent';
    let auth = new Auth(userName, password);
    let body = JSON.stringify(auth);

    let options: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    this.showLoader = true;

    if (this.connectionService.checkConnection(true)) {
      return this.http
        .post(url, body, options)
        .toPromise()
        .then(
          (data: any) => {
            if (data) {
              let user = data.result as AuthenticateResultModel;

              return this.getCurrentUserInfo(user);
            } else {
              this.showLoader = false;
              this.toastrService.error('Usuário inválido');
              return null;
            }
          },
          (error) => {
            this.showLoader = false;

            if (error.error)
              this.toastrService.error(error.error.error.message);
            else this.toastrService.error('Ocorreu um erro interno');

            return null;
          }
        );
    } else {
      return new Promise((resolve) => {
        resolve(null);
      });
    }
  }

  getCurrentUserInfo(user: AuthenticateResultModel): Promise<any> {
    var $this = this;
    if (this.connectionService.checkConnection(true)) {
      return $this
        .getCurrentLoginInformations(user.userId, user.accessToken)
        .toPromise()
        .then((data: any) => {
          if (data) {
            let currentUser = data.result as User;
            currentUser.guid = user.sgunAuthResult.guid;
            currentUser.usCookie = user.sgunAuthResult.us_cookie;
            currentUser.accessToken = user.accessToken;

            this.setUserData(currentUser);

            this._userLogged = currentUser;
            this.userIsLoggedIn.emit(currentUser);
            this.showLoader = false;

            return this._userLogged;
          } else {
            this.showLoader = false;
            this.toastrService.error('Usuário não encontrado');
            return null;
          }
        });
    } else {
      return new Promise((resolve) => {
        resolve(null);
      });
    }
  }

  getCurrentLoginInformations(id: number, token: string) {
    let url =
      this.baseUrl +
      'Session/GetCurrentLoginInformationsForEvents?id=' +
      id +
      '&eventId=' +
      (this.eventService.currentEvent ? this.eventService.currentEvent.id : 0);

    let options: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      }),
    };

    return this.http.request('get', url, options);
  }

  setUserData(user: User) {
    this.dbService.clear('User');
    this.dbService.create(user, 'User');
  }

  // Sign out
  signOut() {
    this.dbService.clear('User');
    this.userIsLoggedIn.emit(null);
    this._userLogged = null;
  }

  getUserEmitter() {
    return this.userIsLoggedIn;
  }

  getLoggedUser() {
    return this.dbService.getAll('User').subscribe((users) => {
      if (users && users.length) return (this._userLogged = users[0] as User);
    });
  }

  isEposterUser(): boolean {
    return this._userLogged && this._userLogged.userName == '00000000000';
  }

  isScheduleUser(): boolean {
    return this._userLogged && this._userLogged.userName == '11111111111';
  }

  isCongressUser(): boolean {
    return this.isEposterUser() || this.isScheduleUser();
  }

  validateLogin() {
    let url = this.baseSgunServiceUrl + '/get-user-logged/json';
    let self = this;
    return this.dbService
      .getAll('User')
      .toPromise()
      .then((users: any) => {
        if (users && users.length) {
          this._userLogged = users[0] as User;
          let userCookie: UserCookie = new UserCookie();
          userCookie.validateApcAccess = true;
          userCookie.us_cookie = this._userLogged.usCookie;
          userCookie.guid = this._userLogged.guid;
          userCookie.eventId = this.eventService.currentEvent
            ? this.eventService.currentEvent.eventSgunId
            : 0;
          let body = JSON.stringify(userCookie);

          let options: any = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: this.baseSgunwAuth,
              MobileReferer: this.baseSgunReferer,
            }),
          };
          if (this.connectionService.checkConnection(true)) {
            return this.http
              .post(url, body, options)
              .toPromise()
              .then((data: any) => {
                if (data != '') {
                  data = JSON.parse(data).resposta;

                  if (data.codigo == 0) {
                    this._userLogged.ticketValid = data.retorno.IngressoValido;
                    this._userLogged.posEventAccessValid =
                      data.retorno.AcessoPosEventoValido;
                    this._userLogged.eventFinished =
                      data.retorno.EventoFinalizado;
                    return this._userLogged;
                  } else {
                    this.alert.warning(
                      'Usuário não autenticado',
                      'Faça login para assistir as aulas'
                    );
                    self.signOut();
                  }
                }

                return null;
              });
          } else {
            return new Promise((resolve, reject) => {
              resolve(null);
            });
          }
        } else {
          self.signOut();
          return new Promise((resolve, reject) => {
            resolve(null);
          });
        }
      });
  }

  //TODO: Mudar para LayoutService
  getLayoutEmitter() {
    return this.activeMobileLayout;
  }

  //TODO: Mudar para LayoutService
  changeLayout() {
    this.desktopLayout = !this.desktopLayout;
    localStorage.setItem('layout', this.desktopLayout ? '1' : '2');
    this.activeMobileLayout.emit(this.desktopLayout);
  }
}
